<!--headers-->
<app-headers></app-headers>
<!--Headers End-->

<div class="main-container">
    <div class="w3-container w3-margin-top">
        <div class="w3-section">
            <h1 class="text-capitalise search-header">{{header}}</h1>
        </div>
        <div class="w3-section search-box ">

            <form id="CarsFilter" [formGroup]="CarsFilter">
                <div class="filter-box-inner">
                    <div class="rect w3-round w3-hover-shadow" id="show-filter">
                        <h3 class="w3-center w3-margin filter-title"> Filter By <i
                                class="fa fa-plus w3-xlarge w3-margin-left"></i></h3>
                    </div>
                    <div class="w3-row-padding rect w3-round w3-hover-shadow filter-box" style="display: none;">
                        <div class="w3-col l4 m4 s6">
                            <label>Make</label><br>
                            <select id="filter_brand" (change)="getSpecificModels($event)" formControlName="brand"
                                name="brand" class=" select-field w3-round">
                                <option value="" disabled selected>Make</option>
                                <option class="text-black" value="" id="allmakes">All Makes </option>
                                <option class="text-black" *ngFor="let brand of brands" value="{{brand['id']}}"
                                    id="brandoption_{{brand['brand']}}">{{brand['brand']}}</option>
                            </select>
                        </div>

                        <div class="w3-col l4 m4 s6">
                            <label>Model</label><br>
                            <select (change)="getSpecificSeries($event)" id="filter_model" formControlName="model"
                                name="model" class="select-field w3-round">
                                <option *ngIf="!modelsloading" value="" [selected]="!modelsloading" disabled>
                                    <p class="w3-small w3-blue">Please Select</p>
                                </option>
                                <option class="text-black" value="">All Models</option>
                                <option *ngIf="modelsloading" value="" [selected]="modelsloading" disabled>
                                    <p>Loading...
                                    </p>
                                </option>
                                <option *ngFor="let model of models" value="{{model['id']}}">{{model['model']}}</option>
                            </select>
                        </div>

                        <div class="w3-col l4 m4 s6">
                            <label>Series</label><br>
                            <select class=" select-field w3-round" id="filter_series" name="series"
                                formControlName="series">
                                <option value="" selected disabled>
                                    <p *ngIf="!seriesloading" class="select-text">Please Select</p>
                                    <div *ngIf="seriesloading">Loading...
                                    </div>
                                </option>
                                <option class="text-black" value="">All Series</option>
                                <option *ngFor="let single_series of series" value="{{single_series['series_id']}}">
                                    {{single_series['series']}}</option>
                            </select>
                        </div>

                        <div class="w3-col l4 m4 s6">
                            <label>Body Type</label>
                            <select formControlName="body_type" id="filter_body_type" name="body_type"
                                class=" select-field w3-round">
                                <option value="" disabled selected="selected" class="select-text">Please Select</option>
                                <option class="text-black" value="">All Body Types</option>
                                <option value="Saloons">Saloons</option>
                                <option value="Hatchbacks">Hatchbacks</option>
                                <option value="SUV">4 Wheel Drives &amp; SUVs</option>
                                <option value="Station Wagon">Station Wagons</option>
                                <option value="Pickups">Pickups</option>
                                <option value="Motorbikes">Motorbikes</option>
                                <option value="Convertibles">Convertibles</option>
                                <option value="Buses and Vans">Buses, Taxis and Vans</option>
                                <option value="Trucks">Trucks</option>
                                <option value="Machinery and Tractors">Machinery and Tractors</option>
                                <option value="Trailers">Trailers</option>
                                <option value="Minis">Minis</option>
                                <option value="Coupes">Coupes</option>
                                <option value="Quad Bikes">Quad Bikes</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>


                        <!-- 
                    <div class="w3-col l4 m4 s4">
                        <label>Engine</label>
                        <select class=" select-field w3-round">
                            <option value="make">Engine</option>
                            <option></option>
                            <option></option>
                        </select>
                    </div> -->

                        <div class="w3-col l4 m4 s6">
                            <label>Year From</label>
                            <select formControlName="year_from" id="filter_year_from" name="year_from"
                                class=" select-field w3-round">
                                <option value="" disabled selected="selected" class="select-text">Please Select</option>
                                <option value="2021">2021</option>
                                <option value="2020">2020</option>
                                <option value="2019">2019</option>
                                <option value="2018">2018</option>
                                <option value="2017">2017</option>
                                <option value="2016">2016</option>
                                <option value="2015">2015</option>
                                <option value="2014">2014</option>
                                <option value="2013">2013</option>
                                <option value="2012">2012</option>
                                <option value="2011">2011</option>
                                <option value="2010">2010</option>
                                <option value="2009">2009</option>
                                <option value="2008">2008</option>
                                <option value="2007">2007</option>
                                <option value="2006">2006</option>
                                <option value="2005">2005</option>
                                <option value="2004">2004</option>
                                <option value="2003">2003</option>
                                <option value="2002">2002</option>
                                <option value="2001">2001</option>
                                <option value="2000">2000</option>
                                <option value="1999">1999</option>
                                <option value="1998">1998</option>
                                <option value="1997">1997</option>
                                <option value="1996">1996</option>
                                <option value="1995">1995</option>
                                <option value="1994">1994</option>
                                <option value="1993">1993</option>
                                <option value="1992">1992</option>
                                <option value="1991">1991</option>
                                <option value="1990">1990</option>
                                <option value="1989">1989</option>
                                <option value="1988">1988</option>
                                <option value="1987">1987</option>
                                <option value="1986">1986</option>
                                <option value="1985">1985</option>
                                <option value="1984">1984</option>
                                <option value="1983">1983</option>
                                <option value="1982">1982</option>
                                <option value="1981">1981</option>
                                <option value="1980">1980</option>
                                <option value="1979">1979</option>
                                <option value="1978">1978</option>
                                <option value="1977">1977</option>
                                <option value="1976">1976</option>
                                <option value="1975">1975</option>
                                <option value="1974">1974</option>
                                <option value="1973">1973</option>
                                <option value="1972">1972</option>
                                <option value="1971">1971</option>
                                <option value="1970">1970</option>
                                <option value="1969">1969</option>
                                <option value="1968">1968</option>
                                <option value="1967">1967</option>
                                <option value="1966">1966</option>
                                <option value="1965">1965</option>
                                <option value="1964">1964</option>
                                <option value="1963">1963</option>
                                <option value="1962">1962</option>
                                <option value="1961">1961</option>
                                <option value="1960">1960</option>
                                <option value="1959">1959</option>
                                <option value="1958">1958</option>
                                <option value="1957">1957</option>
                                <option value="1956">1956</option>
                                <option value="1955">1955</option>
                                <option value="1954">1954</option>
                                <option value="1953">1953</option>
                                <option value="1952">1952</option>
                                <option value="1951">1951</option>
                                <option value="1950">1950</option>
                            </select>
                        </div>
                        <div class="w3-col l4 m4 s6">
                            <label>Year To</label>
                            <select formControlName="year_to" id="filter_year_to" name="year_to"
                                class=" select-field w3-round">
                                <option value="" disabled selected="selected" class="select-text">Please Select</option>
                                <option value="2021">2021</option>
                                <option value="2020">2020</option>
                                <option value="2019">2019</option>
                                <option value="2018">2018</option>
                                <option value="2017">2017</option>
                                <option value="2016">2016</option>
                                <option value="2015">2015</option>
                                <option value="2014">2014</option>
                                <option value="2013">2013</option>
                                <option value="2012">2012</option>
                                <option value="2011">2011</option>
                                <option value="2010">2010</option>
                                <option value="2009">2009</option>
                                <option value="2008">2008</option>
                                <option value="2007">2007</option>
                                <option value="2006">2006</option>
                                <option value="2005">2005</option>
                                <option value="2004">2004</option>
                                <option value="2003">2003</option>
                                <option value="2002">2002</option>
                                <option value="2001">2001</option>
                                <option value="2000">2000</option>
                                <option value="1999">1999</option>
                                <option value="1998">1998</option>
                                <option value="1997">1997</option>
                                <option value="1996">1996</option>
                                <option value="1995">1995</option>
                                <option value="1994">1994</option>
                                <option value="1993">1993</option>
                                <option value="1992">1992</option>
                                <option value="1991">1991</option>
                                <option value="1990">1990</option>
                                <option value="1989">1989</option>
                                <option value="1988">1988</option>
                                <option value="1987">1987</option>
                                <option value="1986">1986</option>
                                <option value="1985">1985</option>
                                <option value="1984">1984</option>
                                <option value="1983">1983</option>
                                <option value="1982">1982</option>
                                <option value="1981">1981</option>
                                <option value="1980">1980</option>
                                <option value="1979">1979</option>
                                <option value="1978">1978</option>
                                <option value="1977">1977</option>
                                <option value="1976">1976</option>
                                <option value="1975">1975</option>
                                <option value="1974">1974</option>
                                <option value="1973">1973</option>
                                <option value="1972">1972</option>
                                <option value="1971">1971</option>
                                <option value="1970">1970</option>
                                <option value="1969">1969</option>
                                <option value="1968">1968</option>
                                <option value="1967">1967</option>
                                <option value="1966">1966</option>
                                <option value="1965">1965</option>
                                <option value="1964">1964</option>
                                <option value="1963">1963</option>
                                <option value="1962">1962</option>
                                <option value="1961">1961</option>
                                <option value="1960">1960</option>
                                <option value="1959">1959</option>
                                <option value="1958">1958</option>
                                <option value="1957">1957</option>
                                <option value="1956">1956</option>
                                <option value="1955">1955</option>
                                <option value="1954">1954</option>
                                <option value="1953">1953</option>
                                <option value="1952">1952</option>
                                <option value="1951">1951</option>
                                <option value="1950">1950</option>
                            </select>
                        </div>

                        <div class="w3-col l4 m4 s12">
                            <label>Mileage</label>
                            <input type="number" id="filter_mileage" formControlName="mileage" name="mileage"
                                class="w3-input" placeholder="Mileage (kms)">
                        </div>

                        <div class="w3-col l4 m4 s6">
                            <label>Min Price</label>
                            <select class="select-field w3-round" formControlName="min_price" name="min_price"
                                id="filter_min_price">
                                <option value="" disabled selected>Min. Price</option>
                                <option value="100000">100,000</option>
                                <option value="500000">500,000</option>
                                <option value="700000">700,000</option>
                                <option value="1000000">1,000,000</option>
                                <option value="2000000">2,000,000</option>
                                <option value="3000000">3,000,000</option>
                                <option value="4000000">4,000,000</option>
                                <option value="5000000">5,000,000</option>
                                <option value="6000000">6,000,000</option>
                                <option value="7000000">7,000,000</option>
                                <option value="8000000">8,000,000</option>
                                <option value="9000000">9,000,000</option>
                                <option value="10000000">10,000,000</option>
                                <option value="12000000">12,000,000</option>
                                <option value="14000000">14,000,000</option>
                                <option value="16000000">16,000,000</option>
                                <option value="18000000">18,000,000</option>
                                <option value="20000000">20,000,000</option>
                                <option value="22000000">22,000,000</option>
                                <option value="24000000">24,000,000</option>
                                <option value="26000000">26,000,000</option>
                                <option value="28000000">28,000,000</option>
                                <option value="30000000">30,000,000</option>
                            </select>
                        </div>

                        <div class="w3-col l4 m4 s6">
                            <label>Max Price</label>
                            <select class=" select-field w3-round" id="filter_max_price" formControlName="max_price"
                                name="max_price">
                                <option value="" disabled selected>Min. Price</option>
                                <option value="100000">100,000</option>
                                <option value="500000">500,000</option>
                                <option value="700000">700,000</option>
                                <option value="1000000">1,000,000</option>
                                <option value="2000000">2,000,000</option>
                                <option value="3000000">3,000,000</option>
                                <option value="4000000">4,000,000</option>
                                <option value="5000000">5,000,000</option>
                                <option value="6000000">6,000,000</option>
                                <option value="7000000">7,000,000</option>
                                <option value="8000000">8,000,000</option>
                                <option value="9000000">9,000,000</option>
                                <option value="10000000">10,000,000</option>
                                <option value="12000000">12,000,000</option>
                                <option value="14000000">14,000,000</option>
                                <option value="16000000">16,000,000</option>
                                <option value="18000000">18,000,000</option>
                                <option value="20000000">20,000,000</option>
                                <option value="22000000">22,000,000</option>
                                <option value="24000000">24,000,000</option>
                                <option value="26000000">26,000,000</option>
                                <option value="28000000">28,000,000</option>
                                <option value="30000000">30,000,000</option>
                            </select>
                        </div>
                        <div class="w3-row">
                            <button type="submit" id="filter_submit" class="w3-margin-bottom  w3-round  w3-blue">
                                <p *ngIf="!loading">Search</p>
                                <p *ngIf="loading">Loading....</p>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="w3-row-padding">
            <div *ngFor="let car of cars" class="car__card single-car w3-col l3 m6 s12"
                routerLink="/car/{{car['year'] }} {{car['brand']}} {{car['model']}}/{{car['id']}}">
                <div class="from-dealers w3-margin-bottom w3-hover-shadow">
                    <div class="img-hover-zoom arrival-image border-bottom">
                        <img src="{{url}}{{car['front_image']}}" class=""
                            alt="{{car['year'] }} {{car['brand']}} {{car['model']}}/{{car['id']}}">
                    </div>
                    <div class="car-name-wrapper">
                        <h4 class="car-title w3-margin-left">{{car['year']}} {{car['brand']}} {{car['model']}}</h4>
                    </div>
                    <div class="car-details-bottom w3-row-padding border-top pt-2">
                        <div class="w3-col l5 m5 s5">
                            <p class="model  w3-text-gray">{{car['use_condition']}}</p>
                            <div>
                                <p class="w3-text-gray pl-0.5"><i class="fa fa-map-marker"></i>
                                    {{car['location']}}</p>
                            </div>
                        </div>
                        <div class="w3-col border-left l7 m7 s7">
                            <p class=" w3-text-gray" *ngIf="car['mileage']"> {{car['mileage'] | number:'1.0':'en-US'}}
                                Km</p>
                            <p class=" w3-text-gray" *ngIf="!car['mileage']"></p>
                            <p class="price  w3-text-gray">Kshs {{car['price'] | number:'1.0':'en-US'}} </p>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="cars.length == 0" class="container">
                <div class="pl-5 pr-5" *ngIf="!loading && cars.length == 0">
                    <h4>Nothing matches your criteria. Adjust the filter and try again</h4>
                </div>
            </div>
            <!-- <div *ngIf="cars">
            <h3>Sorry nothing matches that criteria</h3>
        </div> -->
            <div class="w3-row-padding w3-center pb-3">
                <div class="lds-roller" *ngIf="loading">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <button *ngIf="!loading && (cars.length > 0)" class="w3-button " (click)="loadMore()">
                    View More
                </button>

            </div>
        </div>
    </div>
</div>
<app-footers></app-footers>