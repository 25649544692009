import { Component, OnInit } from '@angular/core';
import { config } from '../../_helpers/global';
import * as $ from 'jquery';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NgxNotificationService } from 'ngx-notification';

@Component({
  selector: 'app-edit-dealers',
  templateUrl: './edit-dealers.component.html',
  styleUrls: ['./edit-dealers.component.scss']
})
export class EditDealersComponent implements OnInit {
  Dealers;
  loading = false;
  editmode = false;
  EditDealersForm: FormGroup;

  constructor(private ngxNotificationService: NgxNotificationService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    var self = this;
    this.EditDealersForm = this.formBuilder.group({
      dealer_name: ['', Validators.required]
    });
    
    $('#EditDealersForm').submit(function(e){
      self.editmode = false;
      if (self.EditDealersForm.invalid) {
          return;
      }      
      var formData = new FormData(this);
      self.loading = true;
      $.ajax({
        type:'POST',
        url: `${config.apiUrl}/get_dealer`,
        data: formData,
        cache:false,
        contentType: false,
        processData: false,
        success: (data) => {
          self.loading = false;
          self.getDealer(data); //callback
        },
        error: function(data){
            console.log(data);
            self.getDealer(data);
        }
        });
    // console.log('getting');
    });
  }

      
  getDealer(data) {
    if (data == 404) {
      this.ngxNotificationService.sendMessage('No user by that name!', 'warning', 'bottom-left');
    } else {
      this.editmode = true;
      this.Dealers = data;
    }
  }

  suspend(index) {
    var self=this;
    self.loading = true;
    var id = this.Dealers[index]['id'];
    $.ajax({
      type:'GET',
      url: `${config.apiUrl}/suspend/`+id,
      cache:false,
      contentType: false,
      processData: false,
      success: (data) => {
        if (data == 1) {
          self.disableSuspend(index);
        } else {
          this.ngxNotificationService.sendMessage('Error! Please try again', 'warning', 'bottom-left');
          console.log(data);
        }
        self.loading = false;
      },
      error: function(data){
          console.log(data);
      }
      });
  } 

  appoint(index) {
    var self=this;
      self.loading = true;
      var id = this.Dealers[index]['id'];
      $.ajax({
        type:'GET',
        url: `${config.apiUrl}/appoint/`+id,
        cache:false,
        contentType: false,
        processData: false,
        success: (data) => {
          if (data == 1) {
            self.disableAppoint(index);
          } else {
            this.ngxNotificationService.sendMessage('Error! Please try again', 'warning', 'bottom-left');
            console.log(data);
          }
          self.loading = false;
        },
        error: function(data){
            console.log(data);
        }
        });
  }

  disableAppoint(index){
    this.Dealers[index]['status'] = 1; //change button in view
  }

  disableSuspend(index){
    this.Dealers[index]['status'] = 0; //change button in view
  }
} 
