<!--headers-->
<app-headers></app-headers>
<!--headers end-->

<div id="profile-upper">
    <div id="profile-banner-image">
        <img src="assets/images/landscape-placeholder.png" *ngIf="!Dealer[0]['cover_photo']" alt="Banner image">
        <img src="{{url}}{{Dealer[0]['cover_photo']}}" *ngIf="Dealer[0]['cover_photo']" alt="Banner image">
    </div>
    <div id="profile-d">
        <div id="profile-pic">
            <img *ngIf="!Dealer[0]['profile_photo']" src="assets/images/profile.jpg">
            <img src="{{url}}{{Dealer[0]['profile_photo']}}" *ngIf="Dealer[0]['profile_photo']">
        </div>
        <div id="u-name">{{Dealer[0]['name']}}</div>
    </div>
    <div id="black-grd"></div>
</div>
<div id="main-content">
    <div class="w3-row-padding">
        <div class="w3-col l3 m3 s12">
            <div class="l-cnt w3-margin-bottom">
                <div class="cnt-label">
                    <i class="l-i" id="l-i-i"></i>
                    <span>Info</span>
                    <!-- <div class="lb-action"><i class="material-icons">edit</i></div> -->
                </div>
                <div id="i-box">
                    <div id="intro-line">Location: {{Dealer[0]['location']}}</div>
                    <div id="intro-line">Total Posts: {{Dealer[0]['posts']}}</div>
                    <!-- <div id="u-occ">Developing awesome UIs at <a href="#">Google LLC</a> Bengaluru and inspiring other companies to do so :)</div> -->
                    <!-- <div id="u-loc"><i class="material-icons">location_on</i><a href="#">Bengaluru</a>, <a href="#">India</a></div> -->
                    <button *ngIf="LoggedIn" class="w3-btn w3-light-blue mt-3 w3-padding w3-round"
                        onclick="document.getElementById('id0').style.display='block'">Edit Profile</button>
                </div>
                <!-- <div id="t-box"> -->
                <!--                         <a href="#">Privacy</a> <a href="#">Terms</a> <a href="#">Advertising</a> <a href="#">Ad Choices</a> <a href="#">Cookies</a> <span id="t-more">More<i class="material-icons">arrow_drop_down</i></span> -->
                <!-- <div id="cpy-nt">German Rides Hub &copy; <span id="curr-year"></span></div> -->
                <!-- </div> -->
            </div>

        </div>
        <div class="w3-col l9 m9 s12">
            <!-- <h2 class="deal-title">My Deals</h2> -->
            <div class="l-cnt w3-margin-bottom">
                <div class="cnt-label">
                    <i class="l-i" id="l-i-i"></i>
                    <span>About {{Dealer[0]['name']}}</span>
                    <!-- <div class="lb-action"><i class="material-icons">edit</i></div> -->
                </div>
                <div id="i-box">
                    <!-- <div id="intro-line">Web developer - UI</div> -->
                    <div id="u-occ" *ngIf="Dealer[0]['description']">{{Dealer[0]['description']}}</div>
                    <div id="u-occ" *ngIf="!Dealer[0]['description']">No Description added.</div>
                    <!-- <div id="u-loc"><i class="material-icons">location_on</i><a href="#">Bengaluru</a>, <a href="#">India</a></div> -->
                </div>
            </div>
        </div>

    </div>

    <!-- <h2 class="deal-title">My Deals</h2> -->
    <div class="w3-row-padding">
        <h2 class="pl-2">Our Deals</h2>
        <div *ngFor="let car of Cars; let i = index" class="car__card single-car w3-col l3 m6 s12 w3-margin-bottom">
            <div routerLink="/car/{{car['year'] }} {{car['brand']}} {{car['model']}}/{{car['id']}}"
                class="cursor-pointer from-dealers">
                <div class="img-hover-zoom">
                    <img src="{{url}}{{car['front_image']}}" class=" article-image" alt="image loading">
                </div>
                <h4 class="car-title w3-margin-left">{{car['year']}} {{car['brand']}} {{car['model']}}</h4>
                <div class="w3-row-padding car-details-bottom car-details-bottom">
                    <div class="w3-col l5 m5 s5">
                        <p class="model">{{car['use_condition']}}</p>
                        <div>
                            <p class="w3-text-gray pl-0.5"><i class="fa fa-map-marker"></i>
                                {{car['location']}}</p>
                        </div>
                    </div>
                    <div class="w3-col border-left l7 m7 s7">
                        <p class="w3-text-gray" *ngIf="car['mileage']"> {{car['mileage'] | number:'1.0':'en-US'}} Km</p>
                        <p class="w3-text-gray" *ngIf="!car['mileage']"> </p>
                        <p class="price  w3-text-gray ">Kshs {{car['price'] | number:'1.0':'en-US'}} </p>
                    </div>
                </div>
            </div>
            <div class="w3-row">
                <div class="w3-half">
                    <button (click)="editCar(i)" *ngIf="LoggedIn" class="w3-input w3-button w3-green">Edit</button>
                </div>
                <div class="w3-half">
                    <button (click)="confirmDelete(car['id'])" *ngIf="LoggedIn"
                        class="w3-input w3-button w3-red">Delete</button>
                </div>
            </div>
        </div>

        <div class="lds-roller" *ngIf="loading">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>

    </div>
</div>

<!--delete form-->

<div id="delete_car" class="w3-modal">
    <div class="w3-modal-content w3-center w3-card-4 w3-animate-zoom p-4" style="max-width:600px">
        <h2>Are you sure you want to delete this Car?</h2>
        <p>Note that it will be completely erased from our database</p>
        <div class="w3-row">
            <button class="w3-button w3-green w3-input w3-margin-bottom" (click)="deleteCar()">Yes</button>
        </div>
        <div class="w3-row">
            <button (click)="cancelDelete()" class="w3-button w3-input w3-red">Cancel</button>
        </div>
    </div>
</div>

<!--Delete form end-->

<!--edit form-->

<div id="id0" class="w3-modal">
    <div class="w3-modal-content w3-card-4 w3-animate-zoom" style="max-width:600px">

        <div class="w3-center"><br>
            <span onclick="document.getElementById('id0').style.display='none'"
                class="w3-button w3-xlarge w3-hover-red w3-display-topright" title="Close Modal">&times;</span>
        </div>

        <form class="w3-container" id="EditDealerForm" [formGroup]="EditDealerForm">
            <div class="w3-section">
                <label><b>Name</b></label>
                <input class="w3-input w3-border w3-margin-bottom" type="text" formControlName="name"
                    placeholder="Enter Username" name="name">

                <label><b>Location</b></label>
                <!-- <input class="w3-input w3-border w3-margin-bottom" type="text" placeholder="where you located" name="location" formControlName="location"> -->
                <select class="w3-input w3-border w3-margin-bottom" name="location" formControlName="location">
                    <option value="baringo">Baringo</option>
                    <option value="bomet">Bomet</option>
                    <option value="bungoma">Bungoma</option>
                    <option value="busia">Busia</option>
                    <option value="elgeyo marakwet">Elgeyo Marakwet</option>
                    <option value="embu">Embu</option>
                    <option value="garissa">Garissa</option>
                    <option value="homa bay">Homa Bay</option>
                    <option value="isiolo">Isiolo</option>
                    <option value="kajiado">Kajiado</option>
                    <option value="kakamega">Kakamega</option>
                    <option value="kericho">Kericho</option>
                    <option value="kiambu">Kiambu</option>
                    <option value="kilifi">Kilifi</option>
                    <option value="kirinyaga">Kirinyaga</option>
                    <option value="kisii">Kisii</option>
                    <option value="kisumu">Kisumu</option>
                    <option value="kitui">Kitui</option>
                    <option value="kwale">Kwale</option>
                    <option value="laikipia">Laikipia</option>
                    <option value="lamu">Lamu</option>
                    <option value="machakos">Machakos</option>
                    <option value="makueni">Makueni</option>
                    <option value="mandera">Mandera</option>
                    <option value="meru">Meru</option>
                    <option value="migori">Migori</option>
                    <option value="marsabit">Marsabit</option>
                    <option value="mombasa">Mombasa</option>
                    <option value="muranga">Muranga</option>
                    <option value="nairobi">Nairobi</option>
                    <option value="nakuru">Nakuru</option>
                    <option value="nandi">Nandi</option>
                    <option value="narok">Narok</option>
                    <option value="nyamira">Nyamira</option>
                    <option value="nyandarua">Nyandarua</option>
                    <option value="nyeri">Nyeri</option>
                    <option value="samburu">Samburu</option>
                    <option value="siaya">Siaya</option>
                    <option value="taita taveta">Taita Taveta</option>
                    <option value="tana river">Tana River</option>
                    <option value="tharaka nithi">Tharaka Nithi</option>
                    <option value="trans nzoia">Trans Nzoia</option>
                    <option value="turkana">Turkana</option>
                    <option value="uasin gishu">Uasin Gishu</option>
                    <option value="vihiga">Vihiga</option>
                    <option value="wajir">Wajir</option>
                    <option value="pokot">West Pokot</option>
                </select>

                <label><b>Phone Number.</b> <span> This will be shown on all your ads</span></label>
                <input class="w3-input w3-border w3-margin-bottom" type="text" placeholder="0701234567"
                    name="phone_number" formControlName="phone_number">
                <div class="alert alert-danger"
                    *ngIf="EditDealerForm.controls['phone_number'].invalid && (EditDealerForm.controls['phone_number'].dirty || EditDealerForm.controls['phone_number'].touched || submitted)">
                    Please provide a valid Phone number e.g 0701234567</div>

                <label><b>Email.</b><span> This will be shown on all your ads</span></label>
                <input class="w3-input w3-border w3-margin-bottom" type="email" placeholder="johndoe@gmail.com"
                    name="email" formControlName="email">
                <div class="alert alert-danger"
                    *ngIf="EditDealerForm.controls['email'].invalid && (EditDealerForm.controls['email'].dirty || EditDealerForm.controls['email'].touched || submitted)">
                    Your Email is invalid</div>

                <label><b>Description</b></label>
                <textarea class="w3-margin-bottom w3-border w3-input" rows="8" style="width: 100%;"
                    formControlName="description" name="description"></textarea>

                <label><b>Cover Image</b><span> Leave Blank if you don't want to change it</span></label>
                <input class="w3-input w3-border w3-margin-bottom" type="file" name="cover_photo"
                    formControlName="cover_photo" (change)="fileProgress($event)">

                <label><b>Profile Picture</b> <span> Leave Blank if you don't want to change it</span></label>
                <input class="w3-input w3-border w3-margin-bottom" type="file" name="profile_photo"
                    fomrControlName="profile_photo" (change)="fileProgress($event)">

                <button class="w3-button w3-block w3-green w3-section w3-padding" type="submit"><span
                        *ngIf="loading">Loading...</span><span *ngIf="!loading">Update</span></button>

            </div>
        </form>
    </div>
</div>

<div id="editcarmodal" class="w3-modal">

    <div class="w3-modal-content w3-card-4 w3-animate-zoom" style="width:100%">
        <div class="w3-center mb-5"><br>
            <span onclick="document.getElementById('editcarmodal').style.display='none'"
                class="w3-button w3-xlarge w3-red w3-hover-orange w3-display-topright"
                title="Close Modal">&times;</span>
        </div>

        <div class="p-3">
            <app-edit-car></app-edit-car>
        </div>
    </div>

</div>

<lib-ngx-notification></lib-ngx-notification>
<!--Login form END-->

<!--footers-->
<app-footers></app-footers>
<!--footers end-->