<!--headers-->
<app-headers></app-headers>
<!--headers end-->

    <div id="profile-upper">
        <div id="profile-banner-image">
            <img src="https://imagizer.imageshack.com/img921/9628/VIaL8H.jpg" alt="Banner image">
        </div>
        <div id="profile-d">
            <div id="profile-pic">
                <img src="https://imagizer.imageshack.com/img921/3072/rqkhIb.jpg">    
            </div>
            <div id="u-name">Himalaya Singh</div>
        </div>
        <div id="black-grd"></div>
    </div>
    <div id="main-content">
        <div class="tb">
            <div class="td" id="m-col">

              
                <div class="w3-row-padding">
                    <div class="w3-col l4 m6 s12 w3-margin-bottom">
                        <div class="from-dealers">
                            <img src="assets/images/matt-henry.png" class="article-image" alt="image loading">
                            <h4 class="car-title w3-margin-left">2016 Mercedes-Benz E-Class</h4>
                            <div class="w3-row-padding">
                                <div class="w3-col l6 m6 s6">
                                    <p class="model">E 350 4MATIC</p>
                                </div>
                              
                                <div class="w3-col l6 m6 s6">
                                    <p class="distance">  | 61,119 miles</p>
                                </div>
                            </div>
                            <p class="price w3-margin-left">Kshs. 300,000</p>
                        </div>
                    </div>
        
                    <div class="w3-col l4 m6 s12 w3-margin-bottom">
                        <div class="from-dealers">
                            <img src="assets/images/merc1.jpg" class="article-image" alt="image loading">
                            <h4 class="car-title w3-margin-left">2016 Mercedes-Benz E-Class</h4>
                            <div class="w3-row-padding">
                                <div class="w3-col l6 m6 s6">
                                    <p class="model">E 350 4MATIC</p>
                                </div>
                              
                                <div class="w3-col l6 m6 s6">
                                    <p class="distance">  | 61,119 miles</p>
                                </div>
                            </div>
                            <p class="price w3-margin-left">Kshs. 300,000</p>
                        </div>
                    </div>
                    <div class="w3-col l4 m6 s12 w3-margin-bottom">
                        <div class="from-dealers">
                            <img src="assets/images/matt-henry.png" class="article-image" alt="image loading">
                            <h4 class="car-title w3-margin-left">2016 Mercedes-Benz E-Class</h4>
                            <div class="w3-row-padding">
                                <div class="w3-col l6 m6 s6">
                                    <p class="model">E 350 4MATIC</p>
                                </div>
                              
                                <div class="w3-col l6 m6 s6">
                                    <p class="distance">  | 61,119 miles</p>
                                </div>
                            </div>
                            <p class="price w3-margin-left">Kshs. 300,000</p>
                        </div>
                    </div>
                </div>
                <div id="loading"><i class="material-icons">autorenew</i></div>
            </div>
            <div class="td" id="l-col">                   
                <div class="l-cnt">
                    <div class="cnt-label">
                        <i class="l-i" id="l-i-i"></i>
                        <span>Intro</span>
                        <div class="lb-action"><i class="material-icons">edit</i></div>
                    </div>
                    <div id="i-box">
                        <div id="intro-line">Web developer - UI</div>
                        <div id="u-occ">Developing awesome UIs at <a href="#">Google LLC</a> Bengaluru and inspiring other companies to do so :)</div>
                        <div id="u-loc"><i class="material-icons">location_on</i><a href="#">Bengaluru</a>, <a href="#">India</a></div>
                    </div>
                </div>
                <div id="t-box">
<!--                         <a href="#">Privacy</a> <a href="#">Terms</a> <a href="#">Advertising</a> <a href="#">Ad Choices</a> <a href="#">Cookies</a> <span id="t-more">More<i class="material-icons">arrow_drop_down</i></span> -->
                    <div id="cpy-nt">German Rides Hub &copy; <span id="curr-year"></span></div>
                </div>
            </div>
        </div>
    </div>

<!--footers-->
<app-footers></app-footers>
<!--footers end-->
