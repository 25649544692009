import { Component, OnInit } from '@angular/core';
import { config } from '../_helpers/global';
import { BrandsModelsSeriesService } from '../_services/brands-models-series.service';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-makes',
  templateUrl: './makes.component.html',
  styleUrls: ['./makes.component.css']
})
export class MakesComponent implements OnInit {
  Brands: any;
  url;
  BrandsForm: any;
  makesloading = true;

  constructor(private router: Router, private bms: BrandsModelsSeriesService, private formBuilder: FormBuilder, private title: Title,
    private meta: Meta) { }

  ngOnInit(): void {
    this.url = config.Url;
    this.getBrands();
    this.BrandsForm = this.formBuilder.group({
      brand: []
    });
    this.title.setTitle('German Rides Hub | GRH - Buy or sell German cars in Kenya');
    this.meta.updateTag({ name: 'description', content: 'A market place where you can buy or sell German cars (Audi, BMW, Porsche, VW, Land Rover and Mercedes Benz) in Kenya.' });
  }

  getBrands() {
    this.bms.getBrands().subscribe(data => {
      this.makesloading = false;
      this.Brands = data;
      // console.log(data);
      this.bms.brands = data;
    }, error => {
      this.makesloading = false;
      console.log(error);
    });
  }

  toBrand(brandid) {
    this.BrandsForm.patchValue({ brand: brandid });
    this.router.navigateByUrl('/search', { state: { data: this.BrandsForm.value } });
  }
}