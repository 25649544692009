<!--headers-->
<app-headers></app-headers>
<!--Headers End-->
 
<section class="p-5">
    <div class="p-5">
        <h1 class="text-center">About GRH</h1>
        <div class="pt-3">
            <p>
                German Rides Hub is all about serving German car lovers in Kenya and our website offers a desirable marketing and buying platform. We are purely involved in German car dealership and our priority is to offer the best services to our customers. Often, car buyers dread the process of searching for cars for sale online. Our website simplifies the process for clients. Not only do we connect German car dealers to their likely buyers, but also have a blog section which quenches the thirst for information about German cars. 
            </p>
            <p>
                Potential buyers check out the available German cars on offer by dealers, whether used or new and decide on which one to purchase. For a dealer to be given an opportunity to post his/her German cars for sale on the website, he/she MUST sign up and provide authentic information like real names, email address and official contacts. After full scrutiny of the details, cars from dealers are posted. This is to ensure customers avoid being conned, since our goal is to offer quality and best services to customers.
            </p>
            <p>
                We also offer advice to customers who have low budgets and would still love to purchase a nice German car. In such cases, we recommend low price cars, or connect them with dealers who offer hire purchase services on their cars.
            </p>
            <p>
                After successful purchases, we still love to get in touch with our clients. We connect our clients or other German car owners with car mechanics whenever they need servicing or general car repairs. For those who love car upgrades, we connect them with spare part dealers. To us, customers are the top priority.
            </p>
            <p>
                Our <b>MISSION</b> is to provide easy and convenient German car sale and purchase services.
            </p>
            <p>
                Our <b>VISION</b> is to be the most preferred German car dealership platform in Kenya.
            </p>
        </div>
    </div>
</section>

<!--footers-->
<app-footers></app-footers>
<!--footers END-->