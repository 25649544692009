<form [formGroup]="RegisterForm">
  <div class="container">
    <div class="cancel-div">
      <button
        type="button"
        onclick="document.getElementById('id02').style.display='none'"
        class="cancelbtn"
      >
        X
      </button>
    </div>
    <h1>Sign Up</h1>
    <p>Please fill in this form to create an account.</p>
    <hr />

    <label for="name"><b>Full Name</b></label>
    <input
      type="text"
      formControlName="name"
      id="name"
      placeholder="John Doe"
      name="name"
      required
    />
    <div
      class="alert alert-danger"
      *ngIf="
        RegisterForm.controls['name'].invalid &&
        (RegisterForm.controls['name'].dirty ||
          RegisterForm.controls['name'].touched ||
          submitted)
      "
    >
      Please provide your Name
    </div>

    <label for="email"><b>Email</b></label>
    <input
      type="text"
      formControlName="email"
      id="email"
      placeholder="Enter Email"
      name="email"
      required
    />
    <div
      class="alert alert-danger"
      *ngIf="
        RegisterForm.controls['email'].invalid &&
        (RegisterForm.controls['email'].dirty ||
          RegisterForm.controls['email'].touched ||
          submitted)
      "
    >
      Your Email is missing
    </div>

    <label for="dealer"><b>Register as:</b></label>
    <select formControlName="type" (change)="registerAs($event)" id="type">
      <option selected value="customer">Customer</option>
      <option value="dealer">Dealer</option>
    </select>
    <div
      class="alert alert-danger"
      *ngIf="
        submitted &&
        RegisterForm.controls['type'].invalid &&
        (RegisterForm.controls['type'].dirty ||
          RegisterForm.controls['type'].touched ||
          submitted)
      "
    >
      Please choose one
    </div>
    <br />

    <label for="password"><b>Password</b></label>
    <input
      type="password"
      formControlName="password"
      id="password"
      placeholder="Enter Password"
      name="password"
      required
    />
    <div
      class="alert alert-danger"
      *ngIf="
        submitted &&
        RegisterForm.controls['password'].invalid &&
        (RegisterForm.controls['password'].dirty ||
          RegisterForm.controls['password'].touched ||
          submitted)
      "
    >
      Please provide a password for your account
    </div>

    <label for="c_password"><b>Repeat Password</b></label>
    <input
      type="password"
      formControlName="c_password"
      placeholder="Repeat Password"
      id="c_password"
      name="c_password"
      required
    />
    <div
      class="alert alert-danger"
      *ngIf="
        submitted &&
        RegisterForm.controls['c_password'].invalid &&
        (RegisterForm.controls['c_password'].dirty ||
          RegisterForm.controls['c_password'].touched ||
          submitted)
      "
    >
      Your passwords do not match
    </div>

    <!-- <label>
          <input type="checkbox" checked="checked" name="remember" style="margin-bottom:15px"> Remember me
        </label> -->

    <p>
      By creating an account you agree to our
      <a href="#" style="color: dodgerblue">Terms & Privacy</a>.
    </p>

    <div *ngIf="existing" class="alert alert-danger">
      <strong>Error!</strong>
      That email address has already been registered.
    </div>
    <div *ngIf="submiterror" class="alert alert-danger">
      <strong>Error!</strong>
      Sorry something went wrong. Please try again.
    </div>
    <div *ngIf="created" class="alert alert-success">
      <strong>Success!</strong>
      Your account was successfully created. Proceed to login.
    </div>
    <div *ngIf="dealercreatedsuccess" class="alert alert-success">
      <strong>Success!</strong>
      Your account was successfully created. Check your email for the next steps
      of your registration. The admin will contact you shortly.
    </div>

    <div class="clearfix">
      <button type="submit" (click)="registerNew()" class="signupbtn">
        <span *ngIf="loading">Loading...</span
        ><span *ngIf="!loading">Sign Up</span>
      </button>
      <button type="button" (click)="toggleLogin()" class="loginbtn">
        <span>Log In</span>
      </button>
    </div>
  </div>
</form>
