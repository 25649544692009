<form class="modal-content animate" [formGroup]="ResetPasswordForm" method="POST">
    <div class="container">
        <div class="cancel-div">
            <button type="button" onclick="document.getElementById('id03').style.display='none'"
                class="cancelbtn">X</button>
        </div>
        <div class="form-group">
            <label for="forgotemail"><b>Email</b></label>
            <input formControlName="forgotemail" class="form-control" type="email" name="forgotemail" id="forgotemail" required>
        </div>
        <div>
            <button type="submit" (click)="reset()" id="forgot-submit">
                <span *ngIf="!loading">Reset</span>
                <span *ngIf="loading">Loading...</span>
            </button>
            <div class="alert alert-danger" *ngIf="ResetPasswordForm.controls['forgotemail'].invalid && (ResetPasswordForm.controls['forgotemail'].dirty || ResetPasswordForm.controls['forgotemail'].touched || submitted)">The email is invalid</div>
            
            <div *ngIf="nonexisterror" class="alert alert-danger">
                That email is not registered with us. 
            </div>
            
            <div *ngIf="reseterror" class="alert alert-danger">
                Something went wrong please try again.
            </div>

            <div *ngIf="isreset" class="alert alert-success">
                <strong>Success!</strong>
                Check your email for a password reset link. Further instructions are in your email.
            </div>

          <button type="button" (click)="toggleLogin()" class="loginbtn"><span>Log In</span></button>
        </div>
    </div>
</form>