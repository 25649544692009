<!--headers-->
<app-headers></app-headers>
<!--Headers End-->

<!--confirm make user blogger section-->
<div id="make_blogger" class="w3-modal">
    <div class="w3-modal-content w3-center w3-card-4 w3-animate-zoom p-4" style="max-width:600px">
        <h2 *ngIf="makebloggername">Make {{makebloggername['name']}} a blogger?</h2>
        <p>User will be able to create new blogs, edit blogs and delete blogs</p>
        <div class="w3-row">
            <button *ngIf="makebloggername" class="w3-button w3-green w3-input w3-margin-bottom"
                (click)="appointBlogger(makebloggername['id'])">Yes</button>
        </div>
        <div class="w3-row">
            <button (click)="cancelAppointBlogger()" class="w3-button w3-input w3-red">Cancel</button>
        </div>
    </div>
</div>
<!--confirm make user blogger section end-->

<!--confirm make blogger normal user section-->
<div id="demote_blogger" class="w3-modal">
    <div class="w3-modal-content w3-center w3-card-4 w3-animate-zoom p-4" style="max-width:600px">
        <h2 *ngIf="makebloggername">Make {{makebloggername['name']}} a normal user?</h2>
        <p>User will no longer create new blogs, edit blogs and delete blogs.</p>
        <div class="w3-row">
            <button *ngIf="makebloggername" class="w3-button w3-green w3-input w3-margin-bottom"
                (click)="demoteBlogger(makebloggername['id'])">Yes</button>
        </div>
        <div class="w3-row">
            <button (click)="cancelDemoteBlogger()" class="w3-button w3-input w3-red">Cancel</button>
        </div>
    </div>
</div>
<!--confirm make user blogger section end-->

<div class="add-container ">
    <div class="panel-group" id="accordion">
        <div class="panel panel-default">

            <div *ngIf="accesslevel==1" class="add-container-inner">
                <div class="panel-heading">
                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse-users">
                        <h3>
                            Registered Users
                        </h3>
                    </a>
                </div>

                <div id="collapse-users" class="panel-collapse collapse">
                    <div class="table-responsive">
                        <table class="table" id="blogs-table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Access Level</th>
                                    <th>Registered On</th>
                                    <th>Change Role</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let user of users; let i=index">
                                    <td>{{i+1}}</td>
                                    <td>{{user['name']}}</td>
                                    <td>{{user['email']}}</td>
                                    <td>
                                        <span *ngIf="user['access_level'] == 0">User</span>
                                        <span *ngIf="user['access_level'] == 1">Admin</span>
                                        <span *ngIf="user['access_level'] == 2">Dealer</span>
                                        <span *ngIf="user['access_level'] == 3">Blogger</span>
                                    </td>
                                    <td>{{user['created_at']}}</td>
                                    <td>
                                        <button *ngIf="user['access_level'] == 3 "
                                            (click)="confirmDemoteBlogger(i)">Make User Normal</button>
                                        <button *ngIf="user['access_level'] == 0 || user['access_level'] == 2"
                                            (click)="confirmAppointBlogger(i)">Make Blogger</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <lib-ngx-notification></lib-ngx-notification>
                    </div>
                </div>
            </div>

            <!--add blogs form-->
            <div *ngIf="accesslevel==1 || accesslevel==3" class="add-container-inner">
                <app-add-blogs></app-add-blogs>
            </div>
            <!--add blogs form-->

            <!--edit blogs form-->

            <div *ngIf="accesslevel==1 || accesslevel==3" class="add-container-inner">
                <app-edit-blogs></app-edit-blogs>
            </div>
            <!--edit blogs form-->

            <div *ngIf="accesslevel==1 || accesslevel==2" class="add-container-inner">

                <div class="panel-heading">
                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse3">
                        <h3>
                            Add Make
                        </h3>
                    </a>
                </div>
                <div id="collapse3" class="panel-collapse collapse in">
                    <form id="AddBrandForm" enctype='multipart/form-data' [formGroup]="AddBrandForm">
                        <div class="form-group">
                            <label for="brand">Make: </label>
                            <input type="text" class="form-control" name="brand" id="brand2" placeholder="Audi" required
                                formControlName="brand">
                        </div>
                        <div class="form-group">
                            <label>Logo: </label>
                            <input type="file" accept="image/*" placeholder="Logo" class="form-control" required
                                name="logo" id="brand_logo" formControlName="logo" (change)="fileProgress($event)">
                        </div>
                        <input [disabled]="loading" class="site-btn" type="submit" value="Post Make" name="submit"
                            id="brand_submit">
                        <div *ngIf="loading" class="lds-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        <lib-ngx-notification></lib-ngx-notification>
                    </form>
                </div>
            </div>
            <div *ngIf="accesslevel==1 || accesslevel==2" class="add-container-inner">

                <div class="panel-heading">
                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse4">
                        <h3>
                            Add New Model
                        </h3>
                    </a>
                </div>
                <div id="collapse4" class="panel-collapse collapse in">
                    <form id="AddModelForm" enctype='multipart/form-data' [formGroup]="AddModelForm">
                        <div class="form-group">
                            <label for="model_name">Model: </label>
                            <input type="text" placeholder="E-Class" class="form-control" name="model" id="model_name"
                                required formControlName="model_name">
                        </div>
                        <div class="form-group">
                            <label for="brand">Make: </label>
                            <select formControlName="brand" id="modelformbrand" name="brand" class="form-control">
                                <option disabled value="" selected>Please Select</option>
                                <option *ngFor="let brand of brands" value="{{brand['id']}}">{{brand['brand']}}</option>
                            </select>
                        </div>
                        <input [disabled]="loading" class="site-btn" type="submit" value="Add model" name="submitmodel"
                            id="model_submit">
                        <div *ngIf="loading" class="lds-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        <lib-ngx-notification></lib-ngx-notification>
                    </form>
                </div>
            </div>
            <div *ngIf="accesslevel==1 || accesslevel==2" class="add-container-inner">

                <div class="panel-heading">
                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse6">
                        <h3>
                            Add New Series
                        </h3>
                    </a>
                </div>
                <div id="collapse6" class="panel-collapse collapse in">
                    <form id="AddSeriesForm" enctype='multipart/form-data' [formGroup]="AddSeriesForm">
                        <div class="form-group">
                            <label for="series_name">Series: </label>
                            <input type="text" class="form-control" name="series_name" placeholder="2.0 3 door"
                                id="series_name" required formControlName="series_name">
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6">
                                <label for="brand">Make: *</label>
                                <select (change)="getSpecificModels($event)" formControlName="brand_id" id="brand1"
                                    name="brand_id" class="form-control">
                                    <option value="" disabled selected='selected'>Please Select</option>
                                    <option *ngFor="let brand of brands" value="{{brand['id']}}">{{brand['brand']}}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group col-md-6">
                                <label for="series_model">Model: *</label>
                                <select formControlName="series_model" id="series_model" name="series_model"
                                    class="form-control">
                                    <option disabled selected value="">
                                        <p *ngIf="!loading">Please Select </p>
                                        <div *ngIf="loading">Loading...
                                        </div>
                                    </option>
                                    <option *ngFor="let model of models" value="{{model['id']}}">{{model['model']}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <input [disabled]="loading" class="site-btn" type="submit" value="Add series" name="submit"
                            id="submitseries">
                        <div *ngIf="loading" class="lds-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        <lib-ngx-notification></lib-ngx-notification>
                    </form>
                </div>
            </div>
            <div *ngIf="accesslevel==1 || accesslevel==2" class="add-container-inner">

                <div class="panel-heading">
                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse5">
                        <h3>
                            Edit Dealers
                        </h3>
                    </a>
                </div>
                <div id="collapse5" class="panel-collapse collapse in">
                    <app-edit-dealers></app-edit-dealers>
                </div>
            </div>
            <div *ngIf="accesslevel==1 || accesslevel==2" class="add-container-inner">

                <div class="panel-heading">
                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse7">
                        <h3>
                            Add Cars
                        </h3>
                    </a>
                </div>
                <div id="collapse7" class="panel-collapse collapse in">
                    <app-add-car></app-add-car>
                </div>
            </div>
        </div>
    </div>
</div>

<!--footers-->
<app-footers></app-footers>
<!--footers END-->