import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NgxNotificationService } from 'ngx-notification';
import * as $ from 'jquery';
import * as Quill from 'quill';
import { config } from '../../_helpers/global';

@Component({
  selector: 'app-edit-blogs',
  templateUrl: './edit-blogs.component.html',
  styleUrls: ['./edit-blogs.component.css']
})
export class EditBlogsComponent implements OnInit {
  EditBlogForm: FormGroup;
  EditBlogEditor: any;
  CurrentEditID: any;
  fileData: File = null;
  loading = false;
  Blogs: any;

  constructor(private formBuilder: FormBuilder, private ngxNotificationService: NgxNotificationService) { }

  ngOnInit() {
    var self = this;
    this.getBlogs(self);
    this.EditBlogForm = this.formBuilder.group({
      title: ['', Validators.required],
      body: ['', Validators.required],
      author: ['', Validators.required],
      image: [''],
      excerpt: ['', Validators.required]
    });
    this.EditBlogEditor = new Quill("#blog-editor-container", {
      modules: {
        toolbar: [
          // [{ header: [1, 2, false] }],
          // ["bold", "italic", "underline"],
          // [{ 'list' : 'ordered' }, { 'list' : 'bullet' }],
          // 
          ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
          ['blockquote', 'code-block'],

          [{ 'header': 1 }, { 'header': 2 }],               // custom button values
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
          [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
          [{ 'direction': 'rtl' }],                         // text direction

          [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'color': ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"] }, { 'background': ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"] }],          // dropdown with defaults from theme
          [{ 'font': [] }],
          [{ 'align': [] }],

          ["image", "code-block"],

          ['clean']
        ]
      },
      placeholder: "Your epic story goes here...",
      theme: "snow" // or 'bubble'
    });
    this.EditBlogEditor.on('text-change', function (delta, old, source) {
      if (source === 'user') {
        var inner = self.EditBlogEditor.root.innerHTML;
        self.EditBlogForm.patchValue({ body: inner });
      }
    });

    $('#EditBlogForm').submit(function (e) {
      e.preventDefault();
      // if (self.EditBlogForm.invalid) {
      //   console.log('invalid');
      //   return;
      // }
      var formData = new FormData(this);
      // formData.forEach((value,key) => {
      //   console.log(key+" "+value)
      // });
      formData.append('body', self.EditBlogEditor.root.innerHTML);
      var id = self.CurrentEditID;
      self.loading = true;
      // console.log(formData);
      $.ajax({
        type: 'POST',
        url: `${config.apiUrl}/edit_blog/` + id,
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        success: (data) => {
          if (data == 1) {
            self.ngxNotificationService.sendMessage('Blog successfully updated!', 'success', 'bottom-left');
            // console.log(data);
            self.EditBlogForm.reset();
            self.EditBlogEditor.root.innerHTML = '';
            self.loading = false;
            $('.edit-blog-modal').addClass('hidden');
            self.getBlogs(self);
          } else {
            console.log(data);
            self.ngxNotificationService.sendMessage('Something went wrong!', 'warning', 'bottom-left');
            self.loading = false;
          }
        },
        error: function (err) {
          console.log(err);
          self.ngxNotificationService.sendMessage('Something went wrong!', 'warning', 'bottom-left');
          self.loading = false;
          // self.addBlog(data);
        }
      });
    });
  }

  getBlogs(self) {
    $.ajax(`${config.apiUrl}/get_blogs/time/infinite/none`,
      {
        type: 'GET',
        cache: false,
        contentType: false,
        processData: false,
        success: function (data) {   // success callback function
          // console.log(data);
          self.Blogs = data;
        },
        error: function (errorMessage) { // error callback 
          console.log(errorMessage);
        }
      });
  }
  editBlog(id) {
    var blog = this.Blogs[id];
    $('input#edit-blog-title').val(blog['title']);
    // $('#blog-editor-container').text(blog['body']);
    var delta = this.EditBlogEditor.clipboard.convert(blog['body']);
    this.EditBlogEditor.setContents(delta, 'silent');
    $('input#edit-blog-excerpt').val(blog['excerpt']);
    $('input#edit-blog-author').val(blog['author']);
    this.CurrentEditID = blog['id'];
    $('.edit-blog-modal').removeClass('hidden');
  }

  deleteBlog(id) {
    var self = this;
    $.ajax(`${config.apiUrl}/delete_blog/` + id,
      {
        type: 'GET',
        cache: false,
        contentType: false,
        processData: false,
        success: function (data) {   // success callback function
          self.getBlogs(self);
          self.ngxNotificationService.sendMessage('Your blog was deleted.', 'success', 'bottom-left');
        },
        error: function (errorMessage) { // error callback 
          console.log(errorMessage);
          self.ngxNotificationService.sendMessage('Could not delete your blog. Try Again', 'warning', 'bottom-left');
        }
      });
  }

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
  }

  hideModal(id) {
    if (id == 'blog') {
      $('.edit-blog-modal').addClass('hidden');
    } else {
      $('.edit-casestudy-modal').addClass('hidden');
    }
  }

  showPrompt(id) {
    $('#' + id).removeClass('hidden');
  }

  hidePrompt(id) {
    $('#' + id).addClass('hidden');
  }
}
