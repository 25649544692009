import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { LoggedInService } from 'src/app/_services/logged-in.service';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { config } from '../../_helpers/global';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  ResetPasswordForm : FormGroup;
  loading = false;
  submitted = false;
  nonexisterror = false;
  reseterror = false;
  emailsent = false;
  isreset = false;
  emailto : any;

  constructor(private loggedInService : LoggedInService, private fb: FormBuilder ) { }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.ResetPasswordForm = this.fb.group({
      forgotemail:['', [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"), Validators.required]]
    });
  }

  reset() {
    this.submitted = true;
    if (this.ResetPasswordForm.invalid) {
      return;
    }
    this.loading=true;
    var self = this;
    this.emailto = this.ResetPasswordForm.value;
    this.emailto = this.emailto['forgotemail'];
    $.when(
      $.ajax(`${config.apiUrl}/reset_user`,
    { 
      type:'POST',
      data: this.ResetPasswordForm.value, 
      success: function (success) {   // success callback function
        console.log(success);
     },
      error: function (errorMessage) { // error callback 
        console.log(errorMessage);
        self.reseterror = true;
        self.loading = false;
      }
    })
    ).done(function(success){
      self.loading = false;
      self.submitted = false;
      if (success == 404) { //user does not exist
          self.nonexisterror = true; 
          self.reseterror = false;
          // self.loginerror = false;
      } else if (success == 503) { //something went wrong
          self.reseterror = true;
          self.nonexisterror = false;
          // self.unregisterederror = false;
      } else if (success == 1){ //success
        self.nonexisterror = false;
        self.reseterror = false;
        self.isreset = true;
      } else {

      }
    });
  }

  toggleRegister () {
    $('#id01').fadeOut();
    $('#id03').fadeOut();
    $('#id02').fadeIn();
  }

  toggleLogin () {
    $('#id02').css({'display': 'none'});
    $('#id03').css({'display': 'none'});
    $('#id01').css({'display': 'block'});
  }
}
