import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoggedInService {
  loggedin = false;
  admin = false;
  dealer = false;
  accesslevel = 0;
  username: any;
  userId: any;
  dealerid: any;

  constructor() { }

  checkStatus() {
    var user = JSON.parse(localStorage.getItem('german_rides_hub_user'));
    if (!user) {
      return 0; //no user
    } else {
      const now = new Date();
      // if (now.getTime() > user.expiry) {
      //   return null;
      // } else {
      // console.log(user.user);
      this.loggedin = true;
      this.username = user.user.name;
      this.userId = user.user.user_id;
      // console.log(this.userId);
      if (user.user.access_level == "1") { //if user is admin
        this.accesslevel = 1;
        this.admin = true;
      }
      else if (user.user.access_level == "3") { //if user is a blogger
        this.accesslevel = 3;
        this.admin = false;
      }
      else if (user.user.access_level == "2") { //if user is a dealer
        this.accesslevel = 2;
        this.dealer = true;
        this.dealerid = user.dealerid;
      } else {
        this.accesslevel = 0;
      }
      //  console.log(this.admin);
      // }
    }
  }

  logOut() {
    localStorage.removeItem('german_rides_hub_user');
    this.loggedin = false;
    this.admin = false;
    this.accesslevel = 0;
    window.location.reload();
  }

}
