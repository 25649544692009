import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NgxNotificationService } from 'ngx-notification';
import * as $ from 'jquery';
import { config } from '../../_helpers/global';
import { BrandsModelsSeriesService } from '../../_services/brands-models-series.service';
import { LoggedInService } from '../../_services/logged-in.service';


@Component({
  selector: 'app-add-car',
  templateUrl: './add-car.component.html',
  styleUrls: ['./add-car.component.css']
})

export class AddCarComponent implements OnInit {
  loading = false;
  seriesloading = false;
  AddCarForm: FormGroup;
  fileData: File = null;
  front_image: File = null;
  back_image: File = null;
  left_image: File = null;
  right_image: File = null;
  dashboard_image: File = null;
  interior_image: File = null;
  other_image1: File = null;
  other_image2: File = null;
  other_image3: File = null;
  other_image4: File = null;
  other_image5: File = null;
  other_image6: File = null;
  // brands:any;
  brands: any;
  models: any;
  series: any;
  user_id: any;

  constructor(private loggedInService: LoggedInService, private ngxNotificationService: NgxNotificationService, private formBuilder: FormBuilder, private bms: BrandsModelsSeriesService) {
    // this.brands = this.bms.brands;
    // this.models = this.bms.models;
  }

  ngOnInit(): void {
    var self = this;
    setInterval(function () {
      self.brands = self.bms.brands;
      // self.models = self.bms.models;
      // self.series = self.bms.series;
    }, 3000);

    this.AddCarForm = this.formBuilder.group({
      brand_id: ['', Validators.required],
      model_id: ['', Validators.required],
      series_id: [''],
      year: ['', Validators.required],
      // mileage: ['', [Validators.pattern("^[0-9]*$"), Validators.required]],
      mileage: [''],
      body_type: ['', Validators.required],
      condition: ['', Validators.required],
      transmission: ['', Validators.required],
      price: ['', [Validators.pattern("^[0-9]*$"), Validators.required]],
      negotiable: [''],
      duty: ['', Validators.required],
      front_image: ['', Validators.required],
      back_image: ['', Validators.required],
      left_image: ['', Validators.required],
      right_image: ['', Validators.required],
      dashboard_image: ['', Validators.required],
      interior_image: ['', Validators.required],
      other_image1: [''],
      other_image2: [''],
      other_image3: [''],
      other_image4: [''],
      other_image5: [''],
      other_image6: [''],
      fuel: ['', Validators.required],
      interior: ['', Validators.required],
      color: ['', Validators.required],
      engine_size: ['', [Validators.pattern("^[0-9]*$"), Validators.required]],
      description: [''],
      ac_front: [''],
      ac_rear: [''],
      cruise_control: [''],
      navigation: [''],
      power_locks: [''],
      power_steering: [''],
      keyless_entry: [''],
      intergrated_phone: [''],
      bucket_seats: [''],
      leather_interior: [''],
      memory_seats: [''],
      power_seats: [''],
      airbag_driver: [''],
      airbag_passenger: [''],
      airbag_side: [''],
      alarm: [''],
      antilock_brakes: [''],
      fog_lights: [''],
      power_windows: [''],
      rear_window_defroster: [''],
      rear_window_wiper: [''],
      tinted_glass: [''],
      am_fm_stereo: [''],
      cd_player: [''],
      cassette_player: [''],
      alloy_wheels: [''],
      sunroof: [''],
      third_row_seats: ['']
    });

    this.user_id = this.loggedInService.userId;
    $('#AddCarForm').submit(function (e) {
      console.log('aaa');

      e.preventDefault();
      if (self.AddCarForm.invalid) {
        return;
      }
      var formData = new FormData(this);
      self.loading = true;
      // formData.forEach((value,key)[ => {
      //   console.log(key+" "+value)
      // });]
      $.ajax({
        type: 'POST',
        url: `${config.apiUrl}/add_car/` + self.user_id,
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        success: (data) => {
          // console.log(data);
          self.addCar(data); //callback
        },
        error: function (data) {
          self.addCar(data);
        }
      });
    });
    this.user_id = this.loggedInService.userId;
    // console.log(this.user_id);
  }

  addCar(data) {
    this.loading = false;
    var self = this;
    if (data == 1) {
      self.ngxNotificationService.sendMessage('The car was successfully posted!', 'success', 'bottom-left');
      // console.log(data);
      // self.AddCarForm.reset();
      self.loading = false;
    } else {
      console.log(data);
      self.ngxNotificationService.sendMessage('Something went wrong! Please try again', 'warning', 'bottom-left');
      self.loading = false;
    }
  }

  getSpecificSeries(event: any) {
    this.seriesloading = true;
    var id = event.target.value;
    this.bms.getSpecificSeries(id).subscribe(data => {
      this.series = data;
      this.seriesloading = false;
    });
  }

  getSpecificModels(event: any) {
    this.loading = true;
    var id = event.target.value;
    this.bms.getSpecificModels(id).subscribe(data => {
      this.models = data;
      this.loading = false;
    });
  }

  fileProgress(fileInput: any, code) {
    if (code == 1) {
      this.front_image = <File>fileInput.target.files[0];
    } else if (code == 2) {
      this.back_image = <File>fileInput.target.files[0];
    } else if (code == 3) {
      this.left_image = <File>fileInput.target.files[0];
    } else if (code == 4) {
      this.right_image = <File>fileInput.target.files[0];
    } else if (code == 5) {
      this.dashboard_image = <File>fileInput.target.files[0];
    } else if (code == 6) {
      this.interior_image = <File>fileInput.target.files[0];
    } else if (code == 7) {
      this.other_image1 = <File>fileInput.target.files[0];
    } else if (code == 8) {
      this.other_image2 = <File>fileInput.target.files[0];
    } else if (code == 9) {
      this.other_image3 = <File>fileInput.target.files[0];
    } else if (code == 10) {
      this.other_image4 = <File>fileInput.target.files[0];
    } else if (code == 11) {
      this.other_image5 = <File>fileInput.target.files[0];
    } else if (code == 12) {
      this.other_image6 = <File>fileInput.target.files[0];
    }
    // this.AddBlogForm.patchValue({
    //   fileSource : this.fileData
    // });
  }
}
