<!--headers-->
<app-headers></app-headers>
<!--Headers End-->

<div class="main-container">
    <div class="w3-row-padding">
        <div class="w3-col l9 m8 s12">
            <div class="w3-container mt-5">
                <h1 class="title">{{Blog[0]['title']}}</h1>
                <div class="row pr-5 ">
                    <div class="col-md-6 col-xl-6 col-sm-6 col-xs-4">
                        <p><i>By </i> <b class="text__brown">{{Blog[0]['author']}}</b> </p>
                    </div>
                    <div class="col-md-6 col-xl-6 col-sm-6 col-xs-6">
                        <p class="float-right">{{ Blog[0]['updated_at'] | date:'MMM d, y'}}</p>
                        <!-- <span>Share: </span> 
                            <span class="pl-2"><i class="fa fa-facebook fa-2x"></i></span>        
                            <span class="pl-2"><i class="fa fa-twitter fa-2x"></i></span>
                            <span class="pl-2"><i class="fa fa-instagram fa-2x"></i></span> -->
                    </div>
                </div>
                <div class="top-section ">
                    <img src="{{url}}{{blogimg}}" alt="{{Blog[0]['title']}}" class="img-fluid w3-round"
                        alt="Image Loading">
                </div>
                <div class="section">
                    <!-- <div class="w3-row">
                        <div class="w3-half">
                            <img src="assets/images/Image1@2x.png" class="top-image" alt="Image Loading">
                        </div>

                        <div class="w3-half">
                            <img src="assets/images/Image2.png" class="top-image" alt="Image Loading">
                        </div>
                    </div> -->
                    <p class="w3-padding w3-darkgrey" [innerHTML]="Blog[0]['body']">
                    </p>
                    <div class="sharethis-inline-share-buttons mt-3"></div>
                </div>
            </div>
        </div>
        <div class="w3-col l3 m4 s12">
            <div class="right-section w3-panel">

                <h3 class="w3-margin-top w3-margin bottom">Editor's Picks</h3>

                <div class="editor-picks w3-panel">
                    <div *ngFor="let blog of EditorPicks; let i = index" class="w3-border-bottom w3-border-gray">
                        <a href="blog/{{blog['title']}}/{{blog['id']}}">
                            <p class="pt-2 w3-row-padding">
                                <span class="w3-col s2 m2 l2">
                                    <b><u>{{i+1}}</u></b>
                                </span>
                                <span class="w3-col s10 m10 l10">
                                    {{blog['title'].substr(0,60)+'...'}}
                                </span>
                            </p>
                        </a>
                    </div>
                </div>

                <div class="shopping w3-center">
                    <h3>Start Shopping</h3>
                    <div class="w3-panel">


                        <div class="w3-border-bottom w3-border-gray">
                            <p class="w3-row-padding">
                                <span class="w3-col s2 l2 m2"> <i class="fa fa-map-marker fa-2x w3-text-gray"></i>
                                </span>
                                <a routerLink="/search">
                                    <span class="w3-text-blue w3-col s10 l10 m10"> Find cars for sale<br> near you
                                    </span>
                                </a>
                            </p>
                        </div>

                        <div class="w3-border-bottom w3-margin-top w3-border-gray">
                            <p class="w3-row-padding">
                                <span class="w3-col s2 l2 m2"> <i class="fa fa-book fa-2x w3-text-gray"></i> </span>
                                <a routerLink="/makes">
                                    <span class="w3-text-blue w3-col s10 l10 m10"> Research Car<br> Models</span>
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="comments-section mt-5 pb-3">
        <h3 class="w3-center pt-3 w3-margin-bottom comments-heading">Comments</h3>

        <div class="row ">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="comments">
                            <div class="comment-box add-comment top-reply-box">
                                <span class="commenter-pic">
                                    <img src="assets/images/profile.jpg" class="img-fluid">
                                </span>
                                <span class="commenter-name">
                                    <!-- <input type="text" placeholder="Add a public comment" name="Add Comment"> -->
                                    <form [formGroup]="AddCommentForm">
                                        <textarea formControlName="comments" rows="3" name="Add Comment" id=""
                                            placeholder="Add a public comment" class="form-control"></textarea>
                                        <button type="submit" class="btn btn-default" (click)="addComment()">Comment
                                            <div *ngIf="postcommentloading" class="lds-ring">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                        </button>
                                    </form>
                                </span>
                            </div>
                            <div>
                                <div *ngFor="let comment of Comments; let i = index" class="single-comment comment-box">
                                    <span class="commenter-pic">
                                        <img src="assets/images/profile.jpg" class="img-fluid">
                                    </span>
                                    <span class="commenter-name">
                                        <a>{{comment.commentor}}</a> <span class="comment-time">{{comment.created_at |
                                            date:'mediumDate'}}</span>
                                    </span>
                                    <p class=" comment-txt more">{{comment.comments}}</p>
                                    <!-- <div class="comment-meta">
                                        <button (click)="likeComment(comment.comment_id)"
                                            class="rate-btn comment-like"><i class="fa rate-btn fa-thumbs-o-up"
                                                aria-hidden="true"></i>
                                            {{comment.likes}}
                                        </button>
                                        <button (click)="dislikeComment(comment.comment_id)"
                                            class="rate-btn comment-dislike"><i class="fa rate-btn fa-thumbs-o-down"
                                                aria-hidden="true"></i>
                                            {{comment.dislikes}}</button>
                                    </div> -->
                                    <!-- <div class="comment-box add-comment reply-box">
                                        <span class="commenter-pic">
                                            <img src="/images/user-icon.jpg" class="img-fluid">
                                        </span>
                                        <span class="commenter-name">
                                            <input type="text" placeholder="Add a public reply" name="Add Comment">
                                            <button type="submit" class="btn btn-default">Reply</button>
                                            <button type="cancel" class="btn btn-default reply-popup">Cancel</button>
                                        </span>
                                    </div> -->
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="w3-container w3-margin-top w3-margin-bottom">
        <h3 class="w3-center w3-margin-bottom">
            From Our Dealers
        </h3>

        <div class="w3-row-padding new-arrivals-inner">
            <div *ngFor="let car of RecommendedCars; let i = index"
                class="w3-col l3 m6 s12 car__card single-new-arrival">
                <a href="/car/{{car['year'] }} {{car['brand']}} {{car['model']}}/{{car['id']}}">
                    <div class="w3-card w3-margin-bottom">
                        <div class="img-hover-zoom arrival-image">
                            <img src="{{url}}{{car['front_image']}}" class="">
                        </div>
                        <div class="car-name-wrapper">
                            <h5 class="car-title w3-margin-left"> {{car['year']}} {{car['brand']}} {{car['model']}}</h5>
                        </div>
                        <div class="car-details-bottom w3-row-padding border-top pt-2">
                            <div class="w3-col l5 m5 s5">
                                <p class="model w3-text-gray"> {{car['use_condition']}}</p>
                                <div>
                                    <p class="w3-text-gray pl-0.5"><i class="fa fa-map-marker"></i>
                                        {{car['location']}}</p>
                                </div>
                            </div>
                            <div class="w3-col border-left l7 m7 s7">
                                <p class="w3-text-gray"> {{car['mileage'] | number:'1.0':'en-US' }} Km</p>
                                <p class="price  w3-text-gray ">Kshs {{car['price'] | number:'1.0':'en-US'}} </p>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>

        <div class="w3-row-padding w3-center mt-3 pb-3">
            <a [routerLink]="['/search']">
                <button *ngIf="!relatedcarsloading" class="w3-button">
                    View More
                </button>
            </a>
        </div>
    </div>

    <div class="blog-section w3-container w3-margin-top">
        <h3 class="w3-center w3-margin-bottom"> Related Articles
        </h3>
        <div class="row p-3">
            <div *ngFor="let blog of RecommendedBlogs; let i = index"
                class="col-md-4 col-sm-6 col-lg-4 col-xs-12  mb-4">
                <div class=" w3-hover-shadow border">
                    <a href="/blog/{{blog['title']}}/{{blog['id']}}">
                        <div class="blog-suggestion-img blog-img-hover-zoom border-bottom">
                            <img src="{{url}}{{blog['image']}}" alt="{{blog['title']}}" class="blog-image"
                                alt="image loading">
                        </div>
                    </a>
                    <a href="/blog/{{blog['title']}}/{{blog['id']}}">
                        <h4 class="tips-title pb-1 pt-2 w3-center" *ngIf="blog['title'].length < 80">{{blog['title']}}
                        </h4>
                        <h4 class="tips-title pb-3 pt-2 w3-center" *ngIf="blog['title'].length > 80">
                            {{blog['title'].substr(0,80)+'...'}}</h4>
                    </a>
                    <!-- <p *ngIf="blog['excerpt']" class="w3-margin-top w3-center" [innerHTML]="blog['excerpt'].substr(0,250)+'...'"></p> -->
                </div>
            </div>

        </div>
        <lib-ngx-notification></lib-ngx-notification>
    </div>

</div>



<app-footers></app-footers>