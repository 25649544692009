import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { map, filter } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { BrandsModelsSeriesService } from '../_services/brands-models-series.service';
import * as $ from 'jquery';
import { config } from '../_helpers/global';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { Meta, Title } from '@angular/platform-browser';

// import { Observable} from 'rxjs/observable';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})

export class SearchComponent implements OnInit {
  url: any;
  CarsFilter: FormGroup;
  loading = false;
  seriesloading = false;
  modelsloading = false;
  submitted = false;
  models: any;
  series: any;
  header: any;
  brands: any;
  urlmodel: any;
  cars = [];
  pagenumber = 1;
  formDataTemp: any;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private formBuilder: FormBuilder, private bms: BrandsModelsSeriesService, private title: Title,
    private meta: Meta) {
    this.router.getCurrentNavigation().extras.state;
  }

  ngOnInit() {
    this.url = config.Url;
    var self = this;
    var staticBrands = [
      {
        "id": 1,
        "brand": "Audi",
        "logo": "file_uploads\\images\\f8e035c0fc828ff6403a8b12aee667aa0d251584a169e43012f13415d6f3a5e8_Audi-Logo.png",
        "created_at": "2020-04-27 09:57:27",
        "updated_at": "2020-04-27 09:57:27"
      },
      {
        "id": 4,
        "brand": "BMW",
        "logo": "file_uploads\\images\\9fa68638bbdc372e51ecae866071b1d4adbf7632afa08b59d118d662207c376d_bmw.png",
        "created_at": "2020-04-27 10:15:18",
        "updated_at": "2020-04-27 10:15:18"
      },
      {
        "id": 8,
        "brand": "Land Rover",
        "logo": "file_uploads\\images\\ed14fe4efd1c98be169da1db7454d66710d3e1a579cc81ce64b095c5abf1e393_land_rover_PNG58-min.png",
        "created_at": "2021-03-26 08:38:28",
        "updated_at": "2021-03-26 08:38:28"
      },
      {
        "id": 5,
        "brand": "Mercedes Benz",
        "logo": "file_uploads\\images\\5f8eec58066ebf443f24fcd9981d54514fd759903f63c628cb133a73b65ae23b_mercedes.png",
        "created_at": "2020-04-27 10:21:30",
        "updated_at": "2020-04-27 10:21:30"
      },
      {
        "id": 3,
        "brand": "Peugeot",
        "logo": "file_uploads\\images\\66931f5a1fd53fbe31537256af4f35d5ede8e377d95910019714cfb7383d0ce6_peugeot.png",
        "created_at": "2020-04-27 10:09:47",
        "updated_at": "2020-04-27 10:09:47"
      },
      {
        "id": 6,
        "brand": "Porsche",
        "logo": "file_uploads\\images\\692d0c3c838a5e27f8e9dcacaf89c424708b4c82e1e70110f920d11960d298d7_5a355a236f74a8.8758566515134459234565-min.png",
        "created_at": "2020-04-27 10:23:51",
        "updated_at": "2020-04-27 10:23:51"
      },
      {
        "id": 7,
        "brand": "Volkswagen",
        "logo": "file_uploads\\images\\f09b2b64db7ce9c00fa2a14dc9e95987402bdfddb1cdff1fb2de5a9d025c57b0_5a214cdb079472.1392710915121318030311.png",
        "created_at": "2020-04-27 10:28:42",
        "updated_at": "2020-04-27 10:28:42"
      },
      {
        "id": 2,
        "brand": "Volvo",
        "logo": "file_uploads\\images\\f940862284657b0cd715319524269429c6324bd4d68191816829666d6e8dc36f_volvo-min.png",
        "created_at": "2020-04-27 10:04:11",
        "updated_at": "2020-04-27 10:04:11"
      }
    ]
    this.getBrands();

    this.CarsFilter = this.formBuilder.group({
      brand: [''],
      model: [''],
      series: [''],
      body_type: [''],
      mileage: [''],
      // engine: [''],
      year_from: [''],
      year_to: [''],
      min_price: [''],
      max_price: [''],
      condition: ['']
    });

    this.CarsFilter.valueChanges.subscribe(val => {//initialise page number
      this.pagenumber = 1;
    });

    this.activatedRoute.queryParams.subscribe(params => { //retrieve url variables
      if (params) {
        // console.log(params);
        var formData = new FormData();
        let brandid;
        if (params.brand) {
          for (let i = 0; i < staticBrands.length; i++) {
            //change value in filters form
            if (Object.values(staticBrands[i]).includes(params.brand)) {
              // console.log("params.brand")
              // console.log(params.brand)
              brandid = staticBrands[i].id;
              // console.log(brandid);
              this.CarsFilter.patchValue({ brand: staticBrands[i].id });
              formData.set("brand", "" + staticBrands[i].id);
              break; //stop loop
            } else {
              formData.set("brand", "");
            }
          }
          self.getSpecificModelsManual(brandid);
        }
        if (params.model) {
          this.urlmodel = params.model;
          formData.set("model", params.model);
        } else {
          this.urlmodel = '';
          formData.set("model", "");
        }
        if (params.series) {
          formData.set("series", params.series);
          this.CarsFilter.patchValue({ series: params.series });
        }
        if (params.body_type) {
          formData.set("body_type", params.body_type);
          this.CarsFilter.patchValue({ body_type: params.body_type });
        }
        if (params.mileage) {
          formData.set("mileage", params.mileage);
          this.CarsFilter.patchValue({ mileage: params.mileage });
        }
        if (params.year_from) {
          formData.set("year_from", params.year_from);
          this.CarsFilter.patchValue({ year_from: params.year_from });
        }
        if (params.year_to) {
          formData.set("year_to", params.year_to);
          this.CarsFilter.patchValue({ year_to: params.year_to });
        }
        if (params.max_price) {
          formData.set("max_price", params.max_price);
          this.CarsFilter.patchValue({ max_price: params.max_price });
        }
        if (params.min_price) {
          formData.set("min_price", params.min_price);
          this.CarsFilter.patchValue({ min_price: params.min_price });
        }
        if (params.condition) {
          formData.set("condition", params.condition);
          this.CarsFilter.patchValue({ condition: params.condition });
        }

        // values.forEach((key, value) => {
        // });

        // console.log('on load');
        // for (var key of formData.entries()) {
        //   console.log(key[0] + ', ' + key[1]);
        // }

        self.getCars(formData, self);

      } else {
        // console.log('else');
        var formData = new FormData();
        self.getCars(formData, self);
      }

    });

    //filter search button pressed
    $('#CarsFilter').submit(function (e) {
      // console.log('filter btn');
      e.preventDefault();
      self.submitted = true;
      var formData = new FormData();
      var values = self.CarsFilter.value;
      values = Object.entries(values);
      values.forEach((key, value) => {
        formData.set(key[0], key[1]);
      });


      //update url with new params
      let payload = self.CarsFilter.value;
      //update url with the brand and model 
      for (let i = 0; i < staticBrands.length; i++) {
        //change value in filters form
        if (staticBrands[i].id == payload.brand) {
          let brandname = staticBrands[i].brand;
          payload.brand = staticBrands[i].brand;
          break; //stop loop
        } else {
          formData.set("brand", "");
        }
      }
      // console.log("payload afta")
      // console.log(payload)

      self.router.navigate(
        [],
        {
          relativeTo: self.activatedRoute,
          queryParams: self.CarsFilter.value,
        });
    });

    $('#show-filter').click(function () {
      $('.filter-box').toggle();
    })

  }

  getCars(formData, self) {
    // formData.forEach((value, key) => {
    //   console.log(key + " " + value);
    // });
    this.formDataTemp = formData;
    this.loading = true;
    $.ajax({
      type: 'POST',
      url: `${config.apiUrl}/get_cars/16`,
      data: formData,
      cache: false,
      contentType: false,
      processData: false,
      success: (data) => {
        // console.log(data);
        if (self.submitted) {
          self.cars = data;
          self.submitted = false;
        } else {
          data.forEach((obj) => {
            // console.log("success"+obj);
            self.cars.push(obj);
          });
        }
        self.loading = false;
      },
      error: function (err) {
        console.log(err);
        self.loading = false;
      }
    });
  }

  loadMore() {
    var self = this;
    var formData = new FormData();
    var values = self.CarsFilter.value;
    values = Object.entries(values);
    values.forEach((key, value) => {
      formData.set(key[0], key[1]);
      // console.log(key[1]);
    });
    // console.log(values);
    this.pagenumber = this.pagenumber + 1; //increment current page number
    var plusone = this.pagenumber;
    var curr_page = plusone.toString();
    formData.set('page', curr_page); //append page number to request
    this.getCars(formData, self);
  }

  //update meta tags
  setMetatags() {
    let formData = this.formDataTemp;
    let obj = {};
    formData.forEach((value, key) => {
      // console.log(key + " " + value);
      obj[key] = value;
    });
    let condition = "";
    let brand = "";
    let model = "";
    let self = this;
    if (obj['condition'] != "" && obj['condition'] != undefined) {
      condition = obj['condition'];
    }

    if (obj['brand']) {
      if (obj['brand'] != "") {
        brand = obj['brand'];
        for (let i = 0; i < self.brands.length; i++) {
          if (self.brands[i]['id'] == brand) {
            brand = self.brands[i]['brand'];
          }
        }
      }
    }

    // console.log(self.models)
    if (obj['model']) {
      if (obj['model'] != "") {
        model = obj['model'];
        for (let i = 0; i < self.models.length; i++) {
          if (self.models[i]['id'] == model) {
            model = self.models[i]['model'];
          }
        }
      }
    }
    let str = "New and Used " + " " + brand + " " + model + " cars for sale in Kenya | GRH";

    str = this.capitalizeFirstLetter(str);

    this.title.setTitle(str);
    this.meta.updateTag({ name: 'description', content: str });

    //Update H1 in body
    let str2 = condition + " " + brand + " " + model + " cars for sale in Kenya";
    this.header = str2;
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  getBrands() {
    this.bms.getBrands().subscribe(data => {
      this.brands = data;
      // console.log(data);
      this.bms.brands = data;
      this.setMetatags();
    }, error => {
      console.log(error);
    });
  }

  // getModels() {
  //   this.bms.getModels().subscribe(data => {
  //     this.models = data;
  //     this.bms.models = data;
  //     // console.log(this.models);
  //   });
  // }

  getSeries() {
    this.bms.getSeries().subscribe(data => {
      this.series = data;
      this.bms.series = data;
    });
  }

  getSpecificSeries(event: any) {
    var id = event.target.value;
    this.series = null;
    this.CarsFilter.patchValue({ "series": '' });
    if (id == '') {
      return;
    }
    this.seriesloading = true;
    this.bms.getSpecificSeries(id).subscribe(data => {
      this.series = data;
      this.seriesloading = false;
    });
  }

  //triggered on form change
  getSpecificModels(event: any) {
    // console.log("formchange");
    var id = event.target.value;
    this.models = null;
    this.CarsFilter.patchValue({ "model": '' });
    if (id == '') {
      return;
    }
    this.modelsloading = true;
    this.bms.getSpecificModels(id).subscribe(data => {
      this.models = data;
      this.modelsloading = false;
      // console.log(this.models);
      // console.log(data);
    });
  }

  //triggered on page load
  getSpecificModelsManual(brand) {
    this.modelsloading = true;
    var id = brand;
    this.CarsFilter.patchValue({ "model": '' });
    this.bms.getSpecificModels(id).subscribe(data => {
      // console.log("models");
      this.models = data;
      this.modelsloading = false;
      this.CarsFilter.patchValue({ model: this.urlmodel });
      this.setMetatags();
      // console.log(data);
    });
  }
}