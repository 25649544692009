import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoggedInService } from 'src/app/_services/logged-in.service';
import { config } from '../../_helpers/global';
import { DealersService } from 'src/app/_services/dealers.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css']
})

export class LoginFormComponent implements OnInit {
  LoginForm: FormGroup;
  loading = false;
  unregisterederror = false;
  loginerror = false;
  loggedin = false;
  admin = false;
  submitted = false;
  accesslevel: any;
  username: any;
  checkStatus: any;

  constructor(private loggedInService: LoggedInService, private fb: FormBuilder, private dealerService: DealersService) {
    this.checkStatus = this.loggedInService.checkStatus();
    // this.loggedin = this.loggedInService.loggedin;
    this.admin = this.loggedInService.admin;
    this.accesslevel = this.loggedInService.accesslevel;
    this.username = this.loggedInService.username;
  }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.LoginForm = this.fb.group({
      email: ['', [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"), Validators.required]],
      password: ['', Validators.required]
    });
  }

  login() {
    this.submitted = true;
    if (this.LoginForm.invalid) {
      return;
    }
    this.loading = true;
    var self = this;
    $.when(
      $.ajax(`${config.apiUrl}/login`,
        {
          type: 'POST',
          data: this.LoginForm.value,
          success: function (success) {   // success callback function

          },
          error: function (errorMessage) { // error callback 
            console.log(errorMessage);
            self.loading = false;
          }
        })
    ).done(function (success) {
      self.loading = false;
      self.submitted = false;
      if (success == 912) { //user does not exist
        self.unregisterederror = true;
        self.loginerror = false;
      } else if (success == 401) { //something went wrong
        self.loginerror = true;
        self.unregisterederror = false;
      } else { //success
        self.unregisterederror = false;
        self.loginerror = false;
        self.loggedin = true;
        setTimeout(function () {
          $('#id01').fadeOut();
        }, 1200);
        var user = success['success'];
        self.username = user['name'];
        var admin = user['access_level'];
        if (admin == 1) {
          self.admin = true;
        }
        var dealerid = 0;
        self.dealerService.getDealer(user['user_id']).subscribe(data => {
          // console.log(data);
          if (data != 0) {
            dealerid = data[0]['id'];
          }
          const now = new Date();
          const freedom_user = {
            user: user,
            dealerid: dealerid,
            expiry: now.getTime() + 86400000 //add one day to current time
          }
          localStorage.setItem('german_rides_hub_user', JSON.stringify(freedom_user));
          window.location.reload();
        });
        //console.log(success);
      }
    });
  }

  toggleRegister() {
    $('#id01').fadeOut();
    $('#id03').fadeOut();
    $('#id02').fadeIn();
  }

  toggleLogin() {
    $('#id02').css({ 'display': 'none' });
    $('#id03').css({ 'display': 'none' });
    $('#id01').css({ 'display': 'block' });
  }
  toggleForgot() {
    $('#id02').css({ 'display': 'none' });
    $('#id01').css({ 'display': 'none' });
    $('#id03').css({ 'display': 'block' });
  }
}
