<form class="LoginForm modal-content animate" [formGroup]="LoginForm" method="post">

	<div class="container">
		<div class="cancel-div">
			<button type="button" onclick="document.getElementById('id01').style.display='none'"
				class="cancelbtn">X</button>
		</div>
		<label for="email"><b>Email</b></label>
		<input type="text" formControlName="email" id="login-email" placeholder="Enter your email" name="email"
			required>
		<div class="alert alert-danger"
			*ngIf="LoginForm.controls['email'].invalid && (LoginForm.controls['email'].dirty || LoginForm.controls['email'].touched || submitted)">
			Your Email is missing</div>

		<label for="psw"><b>Password</b></label>
		<input formControlName="password" id="login-password" type="password" placeholder="Enter Password"
			name="password" required>
		<div class="alert alert-danger"
			*ngIf="LoginForm.controls['password'].invalid && (LoginForm.controls['password'].dirty || LoginForm.controls['password'].touched || submitted)">
			Please provide a password for your account</div>

		<div *ngIf="loginerror" class="alert alert-danger">
			<!-- <strong>Error!</strong> -->
			incorrect password
		</div>
		<div *ngIf="unregisterederror" class="alert alert-danger">
			<!-- <strong>Error!</strong> -->
			That Email is not registered with us.
		</div>
		<div *ngIf="loggedin" class="alert alert-success">
			<strong>Success!</strong>
			Logging you in...
		</div>

		<button [disabled]="loading" (click)="login()" type="submit"><span *ngIf="!loading">Login</span><span
				*ngIf="loading">Loading...</span></button>
		<label>
			<input type="checkbox" checked="checked" name="remember"> Remember me
		</label><a (click)="toggleForgot()">
			<span class="psw text-blue">Forgot password?</span>
		</a>
	</div>

	<div class="container" style="background-color:#f1f1f1">
		<!-- <button type="button" onclick="document.getElementById('id01').style.display='none'" class="cancelbtn">Cancel</button> -->
		<button type="submit" (click)="toggleRegister()" class="register-btn">Register</button>
	</div>
</form>