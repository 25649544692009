import { Injectable } from '@angular/core';
import { config } from '../_helpers/global';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class DealersService {

  constructor(private http: HttpClient) { }

  appointBlogger(id) {
    return this.http.get<any>(`${config.apiUrl}/appoint_blogger/` + id);
  }

  demoteBlogger(id) {
    return this.http.get<any>(`${config.apiUrl}/demote_blogger/` + id);
  }

  getDealer(id) { //by user id
    return this.http.get<any>(`${config.apiUrl}/get_dealer_details/` + id);
  }

  getDealerById(id) { //by dealer id
    return this.http.get<any>(`${config.apiUrl}/get_dealer_details_by_id/` + id);
  }

  getDealers() {
    return this.http.get<any>(`${config.apiUrl}/get_dealers`);
  }

  getUsers() {
    return this.http.get<any>(`${config.apiUrl}/get_users`);
  }

  deleteCar(id) {
    return this.http.get<any>(`${config.apiUrl}/delete_car/` + id);
  }
}