import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { config } from '../_helpers/global';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { BrandsModelsSeriesService } from '../_services/brands-models-series.service';
import { HttpParams } from "@angular/common/http";
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})

export class HomepageComponent implements OnInit {
  cars: any;
  url: any;
  UsedCarsForm: any;
  NewCarsForm: any;
  models: any;
  // series:any;
  brands: any;
  displaybrands: any;
  BrandsForm: any;
  BodyTypeForm: any;
  blogs: any;
  modelsloading = false;
  brandselected = false;
  loading = false;
  blogsloading = false;
  staticBrands: any;

  constructor(private router: Router, private formBuilder: FormBuilder, private _Activatedroute: ActivatedRoute, private bms: BrandsModelsSeriesService,
    private title: Title,
    private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle('German Rides Hub | GRH');
    this.meta.updateTag({ name: 'description', content: 'Buy German Cars In Kenya | Find New and Used Cars' });
    var self = this;
    this.url = config.Url;
    // this.appendJs();
    this.staticBrands = [
      {
        "id": 1,
        "brand": "Audi",
        "logo": "file_uploads\\images\\f8e035c0fc828ff6403a8b12aee667aa0d251584a169e43012f13415d6f3a5e8_Audi-Logo.png",
        "created_at": "2020-04-27 09:57:27",
        "updated_at": "2020-04-27 09:57:27"
      },
      {
        "id": 4,
        "brand": "BMW",
        "logo": "file_uploads\\images\\9fa68638bbdc372e51ecae866071b1d4adbf7632afa08b59d118d662207c376d_bmw.png",
        "created_at": "2020-04-27 10:15:18",
        "updated_at": "2020-04-27 10:15:18"
      },
      {
        "id": 8,
        "brand": "Land Rover",
        "logo": "file_uploads\\images\\ed14fe4efd1c98be169da1db7454d66710d3e1a579cc81ce64b095c5abf1e393_land_rover_PNG58-min.png",
        "created_at": "2021-03-26 08:38:28",
        "updated_at": "2021-03-26 08:38:28"
      },
      {
        "id": 5,
        "brand": "Mercedes Benz",
        "logo": "file_uploads\\images\\5f8eec58066ebf443f24fcd9981d54514fd759903f63c628cb133a73b65ae23b_mercedes.png",
        "created_at": "2020-04-27 10:21:30",
        "updated_at": "2020-04-27 10:21:30"
      },
      {
        "id": 3,
        "brand": "Peugeot",
        "logo": "file_uploads\\images\\66931f5a1fd53fbe31537256af4f35d5ede8e377d95910019714cfb7383d0ce6_peugeot.png",
        "created_at": "2020-04-27 10:09:47",
        "updated_at": "2020-04-27 10:09:47"
      },
      {
        "id": 6,
        "brand": "Porsche",
        "logo": "file_uploads\\images\\692d0c3c838a5e27f8e9dcacaf89c424708b4c82e1e70110f920d11960d298d7_5a355a236f74a8.8758566515134459234565-min.png",
        "created_at": "2020-04-27 10:23:51",
        "updated_at": "2020-04-27 10:23:51"
      },
      {
        "id": 7,
        "brand": "Volkswagen",
        "logo": "file_uploads\\images\\f09b2b64db7ce9c00fa2a14dc9e95987402bdfddb1cdff1fb2de5a9d025c57b0_5a214cdb079472.1392710915121318030311.png",
        "created_at": "2020-04-27 10:28:42",
        "updated_at": "2020-04-27 10:28:42"
      },
      {
        "id": 2,
        "brand": "Volvo",
        "logo": "file_uploads\\images\\f940862284657b0cd715319524269429c6324bd4d68191816829666d6e8dc36f_volvo-min.png",
        "created_at": "2020-04-27 10:04:11",
        "updated_at": "2020-04-27 10:04:11"
      }
    ]
    this.getCars(self);
    this.getBrands();
    this.getBlogs(self);
    this.url = config.Url;

    this.NewCarsForm = this.formBuilder.group({
      brand: ['', Validators.required],
      model: [''],
      condition: ['']
    });
    this.BrandsForm = this.formBuilder.group({
      brand: []
    });
    this.BodyTypeForm = this.formBuilder.group({
      body_type: []
    });
    this.UsedCarsForm = this.formBuilder.group({
      brand: [''],
      model: [''],
      series: [''],
      body_type: [''],
      mileage: [''],
      year_from: [''],
      year_to: [''],
      min_price: [''],
      max_price: [''],
      condition: ['']
    });

    // $('#used').submit(function(){
    //   var formData = new FormData(this);
    //   // self.loading = true;
    //   // var search = [];
    //   // search.push({'type': 'used'})
    //   // formData.forEach((value,key) => {
    //   //   //  console.log(key+" "+value)
    //   //   search.push({key , value});
    //   // });
    //   formData.append('condition' , 'local');
    //   self.router.navigateByUrl('/search', { state: { data: formData } });
    // });

    // $('#new').submit(function(){
    //   var formData = new FormData(this);
    //   // self.loading = true;
    //   // var search = [];
    //   // search.push({'type': 'new'})
    //   // formData.forEach((value,key) => {
    //   //   // console.log(key+" "+value)
    //   //   search.push({key , value});
    //   // });
    //   formData.append('condition' , 'new');
    //   self.router.navigateByUrl('/search', { state: { data: formData } });
    // });
  }

  getCars(self) {
    this.loading = true;
    // let str = 'time/6/'+self.last;    
    $.ajax(`${config.apiUrl}/get_cars/4`, {
      success: function (data) {
        self.cars = data;
        // console.log(self.cars);
        self.loading = false;
      },
      error: function (err) {
        console.log(err);
        self.loading = false;
      }
    });
    // self.blogs = this.blogService.getBlogs(str);
  }

  searchUsed() {
    this.UsedCarsForm.patchValue({ condition: 'used' });
    this.router.navigate(['/search'], { queryParams: this.UsedCarsForm.value });
    // this.router.navigateByUrl('/search', { state: { data: this.UsedCarsForm.value } });
  }

  searchNew() {
    this.NewCarsForm.patchValue({ condition: 'new' });
    this.router.navigate(['/search'], { queryParams: this.NewCarsForm.value });
  }

  // toBrand(brandid) {
  //   this.BrandsForm.patchValue({brand: brandid});
  //   // this.router.navigateByUrl('/search', {state: {data: this.BrandsForm.value}});
  //   const opts = { params: 'new HttpParams({fromString: "_page=1&_limit=10"})', bro : 'asd' };
  //   this.router.navigate(['/search'], { queryParams: { page: 'po' } });
  // }

  toBodyType(bodytype) {
    this.BodyTypeForm.patchValue({ body_type: bodytype });
    this.router.navigateByUrl('/search', { state: { data: this.BodyTypeForm.value } });
    // console.log(this.BodyTypeForm.value);
  }

  getBrands() {
    this.bms.getBrands().subscribe(data => {
      this.brands = data;
      this.displaybrands = data;
      // console.log(data);
      this.bms.brands = data;
    }, error => {
      console.log(error);
    });
  }

  getBlogs(self) {
    this.blogsloading = true;
    let str = 'time/6/none';
    $.ajax(`${config.apiUrl}/get_blogs/` + str, {
      success: function (data) {
        self.blogs = data;
        self.blogsloading = false;
        // console.log(data);
      },
      error: function (err) {
      }
    });
    // self.blogs = this.blogService.getBlogs(str);
  }

  getSpecificModels(event: any) {
    this.modelsloading = true;
    this.brandselected = true;
    var id = event.target.value;
    // console.log(id);
    this.NewCarsForm.patchValue({ "model": '' });
    this.UsedCarsForm.patchValue({ "model": '' });
    let brandid;
    for (let i = 0; i < this.staticBrands.length; i++) {
      //change value in filters form
      if (Object.values(this.staticBrands[i]).includes(id)) {
        brandid = this.staticBrands[i].id;
        break; //stop loop
      }
    }
    this.bms.getSpecificModels(brandid).subscribe(data => {
      this.models = data;
      this.modelsloading = false;
    });
  }

  removeElement(elementId) {
    // Removes an element from the document
    var element = document.getElementById(elementId);
    element.parentNode.removeChild(element);
    // console.log('removed');
  }

  appendJs() {
    if (document.getElementById("drift__code")) {
      this.removeElement("drift__code");
    }
    let script = document.createElement('script');
    script.src = "assets/driftcode.js";
    script.id = "drift__code";
    if (document.getElementById('head').appendChild(script)) {
      // console.log('loaded');
    }
  }

}
