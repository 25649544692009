<form id="EditDealersForm" enctype='multipart/form-data' [formGroup]="EditDealersForm">
    <div class="input-container ">
        <!-- <label for="title">Email: </label> -->
        <i class="fa fa-user icon"></i>
        <i class="icon fa fa"></i>
        <input type="text" class="form-control" name="dealer_name" placeholder="Dealer name" id="DealerName" required
            formControlName="dealer_name">
    </div>

    <input [disabled]="loading" class="site-btn" type="submit" value="Submit" name="submit" id="DealerSubmit">
    <!--loader-->
    <div *ngIf="loading" class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
    <div *ngIf="editmode" >
        <div *ngFor="let dealer of Dealers; let i = index" class="wrapper">
            <div class=" profile-card js-profile-card">
                <div class="profile-card__img">
                    <img src="assets/images/profile.jpg"
                        alt="profile card">
                </div>

                <div class="profile-card__cnt js-profile-cnt">
                    <div class="profile-card__name">{{dealer['name']}}</div>
                    <div class="profile-card__txt">Dealer from <strong>{{dealer['location']}}</strong></div>

                    <div class="profile-card-inf">

                        <div class="profile-card-inf__item">
                            <div class="profile-card-inf__title">{{dealer['posts']}}</div>
                            <div class="profile-card-inf__txt">Posts</div>
                        </div>
                    </div>

                    <div class="profile-card-ctr">
                        <a id="appoint_{{i}}_{{dealer['id']}}" *ngIf="dealer['status']==0" (click)="appoint(i)" class="profile-card__button button--blue js-message-btn">Appoint</a>
                        <a id="suspend_{{i}}_{{dealer['id']}}" *ngIf="dealer['status']==1" (click)="suspend(i)" class="profile-card__button button--orange">Suspend</a>
                    </div>
                </div>

                <div class="profile-card-message js-message">
                    <form class="profile-card-form">
                        <div class="profile-card-form__container">
                            <textarea placeholder="Say something..."></textarea>
                        </div>

                        <div class="profile-card-form__bottom">
                            <button class="profile-card__button button--blue js-message-close">
                                Send
                            </button>

                            <button class="profile-card__button button--gray js-message-close">
                                Cancel
                            </button>
                        </div>
                    </form>

                    <div class="profile-card__overlay js-message-close"></div>
                </div>

            </div>

        </div>
    </div>

    <lib-ngx-notification></lib-ngx-notification>
</form>