<!--headers-->
<app-headers></app-headers>
<!--Headers End-->

<div class="main-container pt-3">
    <div class="w3-row-padding">
        <div class="w3-col l8 m7 s12">
            <div class="w3-container">
                <div class="lds-roller loader" *ngIf="featuredloading">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div *ngIf="featuredone" class="border mb-5 section">
                    <a routerLink="/blog/{{featuredone['title']}}/{{featuredone['id']}}">
                        <div class="blog-img-hover-zoom top-image">
                            <img src="{{url}}{{featuredoneimage}}" alt="{{featuredone['title']}}"
                                class="top-image w3-round" alt="Image Loading">
                        </div>
                        <h3 class="title pl-2" *ngIf="featuredone['title'].length < 150">{{featuredone['title']}}</h3>
                        <h3 *ngIf="featuredone['title'].length > 150" class="pl-2 title">
                            {{featuredone['title'].substr(0,125) + '...'}}</h3>
                        <p *ngIf="featuredone['excerpt']" class="pl-2 description"
                            [innerHTML]="featuredone['excerpt'].substr(0,150)+'...'"></p>
                    </a>
                </div>
                <div class="w3-row">
                    <div class="w3-half" *ngIf="featuredtwo">
                        <div class="pr-2 mb-3 pr-2 card-view">
                            <div class="w3-hover-shadow border">
                                <a routerLink="/blog/{{featuredtwo['title']}}/{{featuredtwo['id']}}">

                                    <div class="blog-img-hover-zoom blog-suggestion-img">
                                        <img src="{{url}}{{featuredtwoimage}}" alt="{{featuredtwo['title']}}"
                                            class="img-hover-zoom w3-round">
                                    </div>
                                </a>
                                <a routerLink="/blog/single/{{featuredone['id']}}">
                                    <h4 class="pl-2">{{featuredtwo['title']}}</h4>
                                </a>
                                <p *ngIf="featuredtwo['excerpt']" class="pb-2 pl-2"
                                    [innerHTML]="featuredtwo['excerpt'].substr(0,150)+'...'"></p>
                            </div>
                        </div>
                    </div>
                    <div class="w3-half" *ngIf="featuredthree">
                        <div class="pl-2 pr-2 card-view">
                            <div class="border w3-hover-shadow">
                                <a routerLink="/blog/{{featuredthree['title']}}/{{featuredthree['id']}}">
                                    <div class="blog-suggestion-img blog-img-hover-zoom">
                                        <img src="{{url}}{{featuredthreeimage}}" alt="{{featuredthree['title']}"
                                            class="img-hover-zoom w3-round">
                                    </div>
                                </a>
                                <a routerLink="/blog/single/{{featuredthree['id']}}">
                                    <h4 class="pl-2">{{featuredthree['title']}}</h4>
                                </a>
                                <p *ngIf="featuredthree['excerpt']" class="pb-2 pl-2"
                                    [innerHTML]="featuredthree['excerpt'].substr(0,250)+'...'"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="w3-col l4 m5 s12">
            <div class="right-section w3-panel">
                <!-- <form>
                    <input type="text" class="w3-input w3-border w3-border-grey" placeholder="Search">
                </form> -->

                <h5 class="w3-center w3-margin-bottom follow" style="color: #707070;">Follow Us</h5>

                <div class="stats w3-row-padding">
                    <div class="w3-col l4 m4 s4">
                        <!-- <p class="w3-border w3-center w3-border-gray"><span class="w3-center">105,277</span></p> -->
                        <a href="https://www.facebook.com/GermanRidesHub.co.ke/?modal=admin_todo_tour">
                            <p class="w3-center"> <i class="fa fa-facebook-square fa-3x"></i></p>
                        </a>
                    </div>
                    <div class="w3-col l4 m4 s4">
                        <!-- <p class="w3-border w3-center w3-border-gray">5,189</p> -->
                        <p class="w3-center"> <i class="fa fa-twitter-square fa-3x"></i></p>
                    </div>
                    <div class="w3-col l4 m4 s4">
                        <!-- <p class="w3-border w3-center w3-border-gray">4,466</p> -->
                        <a href="https://www.instagram.com/german_rides_hub">
                            <p class="w3-center"> <i class="fa fa-instagram fa-3x"></i></p>
                        </a>
                    </div>
                </div>
                <!-- <div class="newsletter w3-center">
                    <h4>Subscribe to newsletter</h4>
                    <div class="mail-section w3-padding">
                        <form class=" ">
                            <input type="text" class="mail w3-input" name="email" placeholder="Your Email">

                            <input type="submit" class="w3-btn subscribe-btn w3-margin-top" value="Subscribe">
                        </form>
                    </div>
                </div> -->
            </div>
        </div>
    </div>

    <div class="bottom-section w3-container w3-margin-top">
        <h3 class="w3-center"> Latest Blogs
        </h3>
        <div class="row p-3">
            <!-- <div> -->
            <div *ngFor="let blog of blogs; let i = index" class="col-md-4 col-sm-6 col-lg-4 col-xs-12  mb-4">
                <div class=" w3-hover-shadow border">
                    <a routerLink="/blog/{{blog['title']}}/{{blog['id']}}">
                        <div class="w3-container w3-padding">
                            <div class="blog-suggestion-img  blog-img-hover-zoom border-bottom">
                                <img src="{{url}}{{blog['image']}}" class="blog-image" alt="{{blog['title']}}">
                            </div>
                            <div class="blog-suggestion-description">
                                <h4 class="tips-title pb-1 pt-2 w3-center" *ngIf="blog['title'].length < 80">
                                    {{blog['title']}}</h4>
                                <h4 class="tips-title pb-3 pt-2 w3-center" *ngIf="blog['title'].length > 80">
                                    {{blog['title'].substr(0,80)+'...'}}</h4>
                            </div>
                        </div>
                    </a>
                    <!-- <p *ngIf="blog['excerpt']" class="w3-margin-top w3-center" [innerHTML]="blog['excerpt'].substr(0,250)+'...'"></p> -->
                </div>
            </div>
            <!-- </div> -->
            <!-- <div class="w3-col l4 m6 s12">
                <div class="w3-padding">
                <img src="assets/images/lamborghini2.png" class="blog-image" alt="image loading">
                <h4 class="tips-title w3-center">6 Tips to save money when buying a car</h4>

                <p class="w3-margin-top">The best SUV category attracted high numbers during the Cheki Car Awards polling period. This...</p>
                </div>
        </div> -->
        </div>
        <div class="w3-row-padding w3-center pb-3">
            <div class="lds-roller " *ngIf="loading">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <button *ngIf="!loading" class="w3-button" (click)="loadMore()">
                View More
            </button>
        </div>
    </div>

</div>

<!--footers-->
<app-footers></app-footers>
<!--footers END-->