import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { config } from '../_helpers/global';
import { ActivatedRoute } from '@angular/router';
import { last } from 'rxjs/operators';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {
  blogs: any;
  page: any; //current page no
  pages = []; //total number of pages
  totalblogs = 0;
  sub;
  url;
  last;
  loading = false;
  featuredloading = false;

  featuredone: any;
  featuredoneimage: any;
  featuredtwo: any;
  featuredtwoimage: any;
  featuredthree: any;
  featuredthreeimage: any;

  constructor(private _Activatedroute: ActivatedRoute, private title: Title,
    private meta: Meta) { }

  ngOnInit() {
    this.url = config.Url;
    var self = this;
    this.getFeaturedBlogs(self);
    this.title.setTitle('German Rides Hub | GRH - Buy or sell German cars in Kenya');
    this.meta.updateTag({ name: 'description', content: 'Learn more about Audi, BMW, Porsche, VW, Land Rover and Mercedes Benz in Kenya.' });
    // this.getPages(self);
  }

  getFeaturedBlogs(self) {
    this.featuredloading = true;
    this.loading = true;
    var str = 'time/9/none';
    $.ajax(`${config.apiUrl}/get_blogs/` + str, {
      success: function (data) {
        console.log(data);
        self.featuredloading = false;
        self.loading = false;
        self.featuredone = data[0];
        self.featuredoneimage = self.getImageUrl(data[0]['image']);
        if (self.featuredtwo = data[1]) {
          self.featuredtwoimage = self.getImageUrl(data[1]['image']);
        }
        if (self.featuredthree = data[2]) {
          self.featuredthreeimage = self.getImageUrl(data[2]['image']);
          self.last = self.featuredthree['id'];
        }
        // self.getBlogs(self); //Get the blogs for printing
        var lastid = data.length - 1;
        self.last = data[lastid]['id'];
        self.blogs = data.slice(3, (data.length + 1));
      },
      error: function (err) {
        self.loading = false;
        console.log("err");
        console.log(err);
      }
    });
    // self.blogs = this.blogService.getBlogs(str);
  }

  getBlogs(self) {
    this.loading = true;
    let str = 'time/6/' + self.last;
    $.ajax(`${config.apiUrl}/get_blogs/` + str, {
      success: function (data) {
        self.loading = false;
        if (!self.blogs) {
          self.blogs = data;
          var lastid = data.length - 1;
          self.last = data[lastid]['id'];
        } else {
          if (data.length > 0) { //if response is not empty
            data.forEach((obj) => {
              self.blogs.push(obj);
            });
            var lastid = data.length - 1;
            self.last = data[lastid]['id'];
          } else {
            self.last = 0;
          }
        }
      },
      error: function (err) {
        self.loading = false;
        console.log(err);
      }
    });
    // self.blogs = this.blogService.getBlogs(str);
  }

  getPages(self) {
    $.ajax(`${config.apiUrl}/get_total_blogs`, {
      success: function (data) {
        self.setTotalBlogs(data);
        var n = data / 6; //calculate number of pagination pages
        var result = n - Math.floor(n);
        var total = 0;
        if (result) {
          var plus = n - result;
          plus++;
          total = plus;
        } else {
          total = n;
        }
        for (var i = 0; i < total; i++) {
          self.pages.push(i + 1); //push into array for iteration in dom      
        }
      }
    });
  }

  loadMore() {
    var self = this;
    this.getBlogs(self);
  }


  getImageUrl(image) {
    var image1 = image.replace('\\', '/');
    image1 = image1.replace('\\', '/');
    return image1;
  }

  isActive(page) {
    if (page == this.page) {
      return true;
    }
  }

  setTotalBlogs(total) {
    this.totalblogs = total;
  }

  getTotalBlogs() {
    return this.totalblogs;
  }

}
