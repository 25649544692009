<!--headers-->
<app-headers></app-headers>
<!--Headers End-->

<section class="p-5">
    <div class="p-5">
        <h1 class="text-center">Terms And Conditions</h1>
        <div class="pt-3">
            <p>
                Welcome to germanrideshub.co.ke. Please read these terms and conditions carefully before using the <b>https://germanrideshub.co.ke</b> website operated by German Rides Hub(referred to as  ‘us’, ‘we’ or ‘our’)
            </p>
            <p>
                Your access to and use of our services is conditioned on your acceptance of and compliance with these Terms. This agreement is between German Rides Hub and each site visitor.
            </p>
            <p>
                By accessing our website, you agree to these terms. If you disagree with any of these Terms and conditions, you may not access our services.
            </p>
            <h2>Accounts</h2>
            <p>
                When you create an account with us, you must provide us with information which is accurate and complete. Failure to do so breaches our Terms and this causes immediate termination of your account.
            </p>
            <p>
                The password you agree to use to access our Service MUST not be disclosed to any third party by you or us. Notify us immediately if you notice any unauthorized use of your account.
            </p>
            <h2>
                Links to other websites
            </h2>
            <p>
                Our Service may contain links to third party websites that are not owned or operated by German Rides Hub. We have no control over the content and privacy policies of any third party websites. You further agree and acknowledge that that we shall not be responsible directly or indirectly, for any damage or loss caused by or in connection with such content.
            </p>
            <p>
                You SHOULD also read privacy policies and Terms and Conditions of third party websites we provide links to.
            </p>
            <h2>
                Jurisdiction
            </h2>
            <p>
                The Laws of Kenya shall govern these Terms and Conditions. If the content on this site is contrary to the laws of the place you are in when you access it, this site should not be accessed by you.
            </p>
            <h2>
                Termination
            </h2>
            <p>
                The Laws of Kenya shall govern these Terms and Conditions. If the content on this site is contrary to the laws of the place you are in when you access it, this site should not be accessed by you.
            </p>
            <p>
                All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.
            </p>
            <h2>
                Disclaimer
            </h2>
            <p>
                Your use of this site is at your sole risk. The site is provided on an as is and as available basis. Our Service is provided without warranties of any kind, whether express or implied including but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.
            </p>
            <p>
                This site will offer complete and timely content and access to our Service will be error-free and the site will be secure.
            </p>
            <h2>
                Changes
            </h2>
            <p>
                We reserve the right, at our sole discretion, to modify or replace these Terms at any time. We will try to provide a 30 days notice prior to any new Terms taking effect. What causes these changes will be at our sole discretion.
            </p>
            <p>
                If you continue accessing our site after by changes, it proves you agree to be bound by the revised Terms. Please do not use our Service if you disagree with our Terms.
            </p>
            <h2>
                Contact Us
            </h2>
            <p>
                If you have any inquiries about these Terms, please contact us. We will offer assistance in any way possible.
            </p>
        </div>
    </div>
</section>

<!--footers-->
<app-footers></app-footers>
<!--footers END-->