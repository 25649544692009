import { Component, OnInit } from '@angular/core';
import { FormGroup,  FormBuilder, FormControl, Validators } from '@angular/forms';
import { config } from '../../_helpers/global';

import * as $ from 'jquery';

@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.css']
})
export class RegisterFormComponent implements OnInit {
  RegisterForm : FormGroup;
  submitted = false;
  loading = false;
  submiterror = false;
  created = false;
  dealercreated = false;
  dealercreatedsuccess = false;
  existing:any;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.RegisterForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"), Validators.required]],
      type : ['', [Validators.required]],
      password: ['', Validators.required],
      c_password: ['', [
        Validators.required,
        this.matchOtherValidator('password')
      ]]
    });
    this.existing = false;
  }
 
  matchOtherValidator (otherControlName: string) {

    let thisControl: FormControl;
    let otherControl: FormControl;
  
    return function matchOtherValidate (control: FormControl) {
  
      if (!control.parent) {
        return null;
      }
  
      // Initializing the validator.
      if (!thisControl) {
        thisControl = control;
        otherControl = control.parent.get(otherControlName) as FormControl;
        if (!otherControl) {
          throw new Error('matchOtherValidator(): other control is not found in parent group');
        }
        otherControl.valueChanges.subscribe(() => {
          thisControl.updateValueAndValidity();
        });
      }
  
      if (!otherControl) {
        return null;
      }
  
      if (otherControl.value !== thisControl.value) {
        return {
          matchOther: true
        };
      }
  
      return null;
  
    }
  }

  registerAs(event: any) {    
    var type = event.target.value;
    if (type == 'dealer') {
        this.dealercreated = true;
    } else {
        this.dealercreated = false;
    }
  }

  registerNew () {
    this.submitted = true;
    this.loading = true;
    if (this.RegisterForm.invalid) {
      return;
    }
    var self = this;
    $.when(
      $.ajax(`${config.apiUrl}/register`,
    {
      type:'POST', 
      data: this.RegisterForm.value, 
      success: function (success) {   // success callback function        
     },
      error: function (errorMessage) { // error callback 
        self.loading = false;
        console.log(errorMessage);
        self.submiterror=true;
        self.existing=false;
      }
    })
    ).done(function(success){
      // console.log(success);
      self.loading = false;
      if (success == 0) {
          self.existing=true;
          self.submiterror = false;
      }
      else if (success == 911) {
          self.submiterror=true;
          self.existing=false;
      } else {
          self.submiterror=false;
          self.existing=false;
          self.RegisterForm.reset();
          if (self.dealercreated) {
            self.dealercreatedsuccess = true;
            setTimeout(function(){
              $('#id02').fadeOut();
            }, 4400);    
          } else {
            self.created = true;
            setTimeout(function(){
              $('#id02').fadeOut();
            }, 2400);            
          }
          self.toggleLogin();
      }
    });
  }

  toggleRegister () {
    $('#id01').fadeOut();
    $('#id02').fadeIn();
  }
  toggleLogin () {
    $('#id02').css({'display': 'none'});
    $('#id01').css({'display': 'block'});
  }

}