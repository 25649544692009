import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { LoggedInService } from 'src/app/_services/logged-in.service';
import { FormGroup,  FormBuilder, FormControl, Validators } from '@angular/forms';
import { config } from '../_helpers/global';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-pasword-page',
  templateUrl: './forgot-pasword-page.component.html',
  styleUrls: ['./forgot-pasword-page.component.css']
})
export class ForgotPaswordPageComponent implements OnInit {
  ForgotPassword : FormGroup;
  loading = false;
  submitted = false;
  nonexisterror = false;
  reseterror = false;
  emailsent = false;
  isreset = false;
  emailto : any;
  reset_status = false;
  id : any;

  constructor(private loggedInService : LoggedInService, private fb: FormBuilder, private router: Router ) { }

  ngOnInit(): void {
    this.checkResetStatus();
    this.ForgotPassword = this.fb.group({
      resetpassword: ['', Validators.required],
      resetpasswordconfirm: ['', [
        Validators.required,
        this.matchOtherValidator('resetpassword')]],
      id: ['']
    });
  }
  
  checkResetStatus () {
    var url = window.location.pathname;
    var split = url.split('/');
    var userid = split[2];
    this.id = userid;
    var self= this;
    $.ajax(`${config.apiUrl}/user_details/`+userid,
    {
      type:'GET',
      success: function (success) {   // success callback function
        if (success['reset_status'] == 1 && success['reset_code'] == split[3]) {
          self.reset_status = true; 
        } else {
          self.router.navigate(['/']);
        }
     },
      error: function (errorMessage) { // error callback 
        // console.log(errorMessage);
      }
    });
  }

  reset() {
    this.submitted = true;
    if (this.ForgotPassword.invalid) {
      return;
    }
    this.loading=true;
    var self = this;
    this.ForgotPassword.patchValue({id: this.id});    
    $.when(
      $.ajax(`${config.apiUrl}/change_password`,
    {
      type:'POST',
      data: this.ForgotPassword.value, 
      success: function (success) {   // success callback function 
        console.log(success);
     },
      error: function (errorMessage) { // error callback 
        console.log(errorMessage);
        self.reseterror = true;
        self.loading = false;
      }
    })
    ).done(function(success){
      self.loading = false;
      self.submitted = false;
      if (success == 404) { //user does not exist
          self.nonexisterror = true; 
          self.reseterror = false;
          // self.loginerror = false;
      } else if (success == 503) { //something went wrong
          self.reseterror = true;
          self.nonexisterror = false;
          // self.unregisterederror = false;
      } else if (success == 1){ //success
        self.nonexisterror = false;
        self.reseterror = false;
        self.isreset = true;
        // self.router.navigate(['/']);
        document.getElementById('id01').style.display='block';
      } 
    });
  }

  matchOtherValidator (otherControlName: string) {

    let thisControl: FormControl;
    let otherControl: FormControl;
  
    return function matchOtherValidate (control: FormControl) {
  
      if (!control.parent) {
        return null;
      }
  
      // Initializing the validator.
      if (!thisControl) {
        thisControl = control;
        otherControl = control.parent.get(otherControlName) as FormControl;
        if (!otherControl) {
          throw new Error('matchOtherValidator(): other control is not found in parent group');
        }
        otherControl.valueChanges.subscribe(() => {
          thisControl.updateValueAndValidity();
        });
      }
  
      if (!otherControl) {
        return null;
      }
  
      if (otherControl.value !== thisControl.value) {
        return {
          matchOther: true
        };
      }
  
      return null;
  
    }
  }
}
