import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})

export class CanonicalService {

  constructor(@Inject(DOCUMENT) private dom) { }

  createCanonicalLink(url?: string) {
    let canURL = url == undefined ? this.dom.URL : url;
    let link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', canURL);
    // console.log(canURL);

    // const head = this.dom.getElementsByTagName('head')[0];
    // var element: HTMLLinkElement = this.dom.querySelector(`link[rel='canonical']`) || null
    // if (element == null) {
    //   element = this.dom.createElement('link') as HTMLLinkElement;
    //   head.appendChild(element);
    // }
    // element.setAttribute('rel', 'canonical')
    // element.setAttribute('href', url) 
  }
}