import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
import { config } from '../_helpers/global';
import { CarsService } from '../_services/cars.service';
import { LoggedInService } from '../_services/logged-in.service';
import { DealersService } from '../_services/dealers.service';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Meta, Title } from '@angular/platform-browser';
import { CanonicalService } from '../_services/canonical.service';

@Component({
  selector: 'app-single-car-view',
  templateUrl: './single-car-view.component.html',
  styleUrls: ['./single-car-view.component.css']
})

export class SingleCarViewComponent implements OnInit {
  url: any;
  car: any;
  CurrentId: any;
  LoggedIn = false;
  Dealer: any;
  BrandId: any;
  ModelId: any;
  RecommendedCars: any;
  BrandsForm: any;
  Loading = true;
  DealerPhoneNumber: any;
  DeviceInfo: any;
  staticBrands: any;
  BrandName: any;

  constructor(private _Activatedroute: ActivatedRoute, private carsService: CarsService, private loggedInService: LoggedInService, private dealersService: DealersService, private router: Router, private formBuilder: FormBuilder, private deviceService: DeviceDetectorService, private title: Title, private meta: Meta, private canonical: CanonicalService) {

    this.router.events.subscribe((evt) => {

    });
  }

  ngOnInit() {
    this.url = config.Url;
    this.canonical.createCanonicalLink();
    var self = this;
    this.getCurrentId(self);
    this.isLoggedIn();
    this.BrandsForm = this.formBuilder.group({
      brand: ['']
      // model: ['']
    });
    this.staticBrands = [
      {
        "id": 1,
        "brand": "Audi",
        "logo": "file_uploads\\images\\f8e035c0fc828ff6403a8b12aee667aa0d251584a169e43012f13415d6f3a5e8_Audi-Logo.png",
        "created_at": "2020-04-27 09:57:27",
        "updated_at": "2020-04-27 09:57:27"
      },
      {
        "id": 4,
        "brand": "BMW",
        "logo": "file_uploads\\images\\9fa68638bbdc372e51ecae866071b1d4adbf7632afa08b59d118d662207c376d_bmw.png",
        "created_at": "2020-04-27 10:15:18",
        "updated_at": "2020-04-27 10:15:18"
      },
      {
        "id": 8,
        "brand": "Land Rover",
        "logo": "file_uploads\\images\\ed14fe4efd1c98be169da1db7454d66710d3e1a579cc81ce64b095c5abf1e393_land_rover_PNG58-min.png",
        "created_at": "2021-03-26 08:38:28",
        "updated_at": "2021-03-26 08:38:28"
      },
      {
        "id": 5,
        "brand": "Mercedes Benz",
        "logo": "file_uploads\\images\\5f8eec58066ebf443f24fcd9981d54514fd759903f63c628cb133a73b65ae23b_mercedes.png",
        "created_at": "2020-04-27 10:21:30",
        "updated_at": "2020-04-27 10:21:30"
      },
      {
        "id": 3,
        "brand": "Peugeot",
        "logo": "file_uploads\\images\\66931f5a1fd53fbe31537256af4f35d5ede8e377d95910019714cfb7383d0ce6_peugeot.png",
        "created_at": "2020-04-27 10:09:47",
        "updated_at": "2020-04-27 10:09:47"
      },
      {
        "id": 6,
        "brand": "Porsche",
        "logo": "file_uploads\\images\\692d0c3c838a5e27f8e9dcacaf89c424708b4c82e1e70110f920d11960d298d7_5a355a236f74a8.8758566515134459234565-min.png",
        "created_at": "2020-04-27 10:23:51",
        "updated_at": "2020-04-27 10:23:51"
      },
      {
        "id": 7,
        "brand": "Volkswagen",
        "logo": "file_uploads\\images\\f09b2b64db7ce9c00fa2a14dc9e95987402bdfddb1cdff1fb2de5a9d025c57b0_5a214cdb079472.1392710915121318030311.png",
        "created_at": "2020-04-27 10:28:42",
        "updated_at": "2020-04-27 10:28:42"
      },
      {
        "id": 2,
        "brand": "Volvo",
        "logo": "file_uploads\\images\\f940862284657b0cd715319524269429c6324bd4d68191816829666d6e8dc36f_volvo-min.png",
        "created_at": "2020-04-27 10:04:11",
        "updated_at": "2020-04-27 10:04:11"
      }
    ]
  }

  openFancybox() {
    document.getElementById('firstimage').click();
    // $('#firstimage').click();
  }

  toBrand() {
    for (let i = 0; i < this.staticBrands.length; i++) {
      //change value in filters form
      if (this.staticBrands[i].id == this.BrandId) {
        let brandname = this.staticBrands[i].brand;
        this.BrandsForm.patchValue({ brand: brandname });
        this.BrandName = brandname;
        break; //stop loop
      } else {
        this.BrandsForm.patchValue({ brand: "" });
        this.BrandName = "";
      }
    }
    // this.BrandsForm.patchValue({model: modelid});
    // console.log(this.BrandsForm.value);
    // this.router.navigateByUrl('/search', { state: { data: this.BrandsForm.value } });
  }

  getCar(id) {
    var self = this;
    this.carsService.getCar(id).subscribe(data => {
      // console.log(data);
      // console.log(data[0].view_status);
      if (data.length > 0) {
        if (data[0].view_status) {
          if (data[0].view_status == 0) {
            window.location.href = "/search?brand=" + data[0]['brand'] + "&model=" + data[0]['model_id'];
          } else {
            this.car = data;
            var userid = data[0]['dealer_id'];
            self.BrandId = data[0]['brand_id'];
            self.ModelId = data[0]['model_id'];
            this.toBrand();
            self.setMetatags(data);
            self.Loading = false;
            this.getDealer(userid);
            self.getSimilarCars(self);
          }
        } else {
          window.location.href = "/search";
        }

      } else {
        window.location.href = "/search";
      }
    }, error => {
      console.log(error);
    }
    );
  }

  setMetatags(car) {
    let str = "" + car[0]['year'] + " " + car[0]['brand'] + " " + car[0]['model'] + " on sale in Nairobi Kenya | Features, price and specs | GRH";
    this.title.setTitle(str);
    this.meta.updateTag({ name: 'description', content: str });
  }

  getSimilarCars(self) {
    var str = self.CurrentId;
    $.ajax(`${config.apiUrl}/get_similar_cars/` + str, {
      success: function (data) {
        self.RecommendedCars = data;
        // console.log(self.RecommendedCars);
      },
      error: function (err) {
        console.log(err);
      }
    });
  }

  getDealer(userid) {
    this.dealersService.getDealer(userid).subscribe(data => {
      this.Dealer = data;
      this.DealerPhoneNumber = "254" + this.Dealer[0]['phone_number'].substring(1, 10);
    }, error => {
      console.log(error);
    }
    );
  }

  whatsappRedirect() {
    let phone_number = "254" + this.Dealer[0]['phone_number'].substring(1, 10);
    let currenturl = encodeURI(window.location.href);
    let brand = encodeURI(this.car[0]['brand']);
    let str = '';

    if (this.deviceService.isMobile() || this.deviceService.isTablet()) { //if client is on mobile
      str = "https://api.whatsapp.com/send?phone=%2B" + phone_number + "&text=Hi%2C%20I%20am%20interested%20in%20your%20vehicle%20" + currenturl;
    } else { //desktop
      str = 'https://web.whatsapp.com/send?phone=%2B' + phone_number + '&text=Hi%2C%20I%20am%20interested%20in%20your%20vehicle%20' + currenturl;
    }
    // console.log(phone_number);
    // console.log(currenturl);
    // console.log(str);
    window.open(str);
  }

  phoneRedirect() {
    let phone_number = "254" + this.Dealer[0]['phone_number'].substring(1, 10);
    let currenturl = encodeURI(window.location.href);
    let brand = encodeURI(this.car[0]['brand']);
    let str = 'tel://' + this.DealerPhoneNumber;
    // console.log(phone_number);
    // console.log(currenturl);
    // console.log(str);
    window.open(str);
  }

  emailRedirect() {
    let str = 'mailto:' + this.Dealer[0]['email'];
    // console.log(phone_number);
    // console.log(currenturl);
    // console.log(str);
    window.open(str);
  }

  getCurrentId(self) {
    // console.log('getting id');
    self.sub = self._Activatedroute.paramMap.subscribe(params => {
      self.CurrentId = params.get('id');
    });
    self.getCar(self.CurrentId); //Get the case study details
  }

  isLoggedIn() {
    if (this.loggedInService.checkStatus() == 0) { //If not logged in
      this.LoggedIn = false;
    } else {
      this.LoggedIn = true;
    }
  }

}
