import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NgxNotificationService } from 'ngx-notification';
import * as $ from 'jquery';
import { config } from '../../_helpers/global';
import { BrandsModelsSeriesService } from '../../_services/brands-models-series.service';
import { LoggedInService } from '../../_services/logged-in.service';
import { CarsService } from 'src/app/_services/cars.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-edit-car',
  templateUrl: './edit-car.component.html',
  styleUrls: ['./edit-car.component.css']
})

export class EditCarComponent implements OnInit {
  CarId: any;
  loading = false;
  seriesloading = false;
  EditCarForm: FormGroup;
  fileData: File = null;
  front_image: File = null;
  back_image: File = null;
  left_image: File = null;
  right_image: File = null;
  dashboard_image: File = null;
  interior_image: File = null;
  other_image1: File = null;
  other_image2: File = null;
  other_image3: File = null;
  other_image4: File = null;
  other_image5: File = null;
  other_image6: File = null;
  // brands:any;
  brands: any;
  brand_id: any;
  models: any;
  model_id: any;
  series: any;
  series_id: any;
  user_id: any;
  modelsloading = false;

  constructor(private loggedInService: LoggedInService, private ngxNotificationService: NgxNotificationService, private formBuilder: FormBuilder, private bms: BrandsModelsSeriesService, private carsService: CarsService, private http: HttpClient) {
  }

  ngOnInit(): void {
    var self = this;
    this.EditCarForm = this.formBuilder.group({
      brand_id: ['', Validators.required],
      model_id: ['', Validators.required],
      series_id: [''],
      year: ['', Validators.required],
      // mileage: ['', [Validators.pattern("^[0-9]*$"), Validators.required]],
      mileage: [''],
      body_type: ['', Validators.required],
      condition: ['', Validators.required],
      transmission: ['', Validators.required],
      price: ['', [Validators.pattern("^[0-9]*$"), Validators.required]],
      negotiable: [''],
      duty: ['', Validators.required],
      front_image: [''],
      back_image: [''],
      left_image: [''],
      right_image: [''],
      dashboard_image: [''],
      interior_image: [''],
      other_image1: [''],
      other_image2: [''],
      other_image3: [''],
      other_image4: [''],
      other_image5: [''],
      other_image6: [''],
      fuel: ['', Validators.required],
      interior: ['', Validators.required],
      color: ['', Validators.required],
      engine_size: ['', [Validators.pattern("^[0-9]*$"), Validators.required]],
      description: [''],
      ac_front: [''],
      ac_rear: [''],
      cruise_control: [''],
      navigation: [''],
      power_locks: [''],
      power_steering: [''],
      keyless_entry: [''],
      intergrated_phone: [''],
      bucket_seats: [''],
      leather_interior: [''],
      memory_seats: [''],
      power_seats: [''],
      airbag_driver: [''],
      airbag_passenger: [''],
      airbag_side: [''],
      alarm: [''],
      antilock_brakes: [''],
      fog_lights: [''],
      power_windows: [''],
      rear_window_defroster: [''],
      rear_window_wiper: [''],
      tinted_glass: [''],
      am_fm_stereo: [''],
      cd_player: [''],
      cassette_player: [''],
      alloy_wheels: [''],
      sunroof: [''],
      third_row_seats: [''],
      current_images: ['']
    });

    this.getBrands();

    this.carsService.toggleEditCar.subscribe(data => {
      if (data) { //car object
        this.CarId = data.id;
        let temp_images = {};
        if (data.back_image != null) {
          temp_images['back_image'] = data.back_image_raw
        }
        if (data.front_image != null) {
          temp_images['front_image'] = data.front_image_raw
        }
        if (data.left_image != null) {
          temp_images['left_image'] = data.left_image_raw
        }
        if (data.right_image != null) {
          temp_images['right_image'] = data.right_image_raw
        }
        if (data.dashboard_image != null) {
          temp_images['dashboard_image'] = data.dashboard_image_raw
        }
        if (data.interior_image != null) {
          temp_images['interior_image'] = data.interior_image_raw
        }
        if (data.other_image1 != null) {
          temp_images['other_image1'] = data.other_image1_raw
        }
        if (data.other_image2 != null) {
          temp_images['other_image2'] = data.other_image2_raw
        }
        if (data.other_image3 != null) {
          temp_images['other_image3'] = data.other_image3_raw
        }
        if (data.other_image4 != null) {
          temp_images['other_image4'] = data.other_image4_raw
        }
        if (data.other_image5 != null) {
          temp_images['other_image5'] = data.other_image5_raw
        }
        if (data.other_image6 != null) {
          temp_images['other_image6'] = data.other_image6_raw
        }
        this.EditCarForm.patchValue({ current_images: JSON.stringify(temp_images) });

        if (data.ac_front != null) {
          this.EditCarForm.patchValue({ ac_front: data.ac_front });
        }
        if (data.brand_id != null) {
          this.EditCarForm.patchValue({ brand_id: data.brand_id });
          this.brand_id = data.brand_id;
          this.getSpecificModelsManual(this.brand_id);
        }
        if (data.ac_rear != null) {
          this.EditCarForm.patchValue({ ac_rear: data.ac_rear });
        }
        if (data.airbag_driver != null) {
          this.EditCarForm.patchValue({ airbag_driver: data.airbag_driver });
        }
        if (data.airbag_passenger != null) {
          this.EditCarForm.patchValue({ airbag_passenger: data.airbag_passenger });
        }
        if (data.airbag_side != null) {
          this.EditCarForm.patchValue({ airbag_side: data.airbag_side });
        }
        if (data.alarm != null) {
          this.EditCarForm.patchValue({ alarm: data.alarm });
        }
        if (data.alloy_wheels != null) {
          this.EditCarForm.patchValue({ alloy_wheels: data.alloy_wheels });
        }
        if (data.am_fm_stereo != null) {
          this.EditCarForm.patchValue({ am_fm_stereo: data.am_fm_stereo });
        }
        if (data.antilock_brakes != null) {
          this.EditCarForm.patchValue({ antilock_brakes: data.antilock_brakes });
        }
        if (data.body_type != null) {
          this.EditCarForm.patchValue({ body_type: data.body_type });
        }
        if (data.bucket_seats != null) {
          this.EditCarForm.patchValue({ bucket_seats: data.bucket_seats });
        }
        if (data.cassette_player != null) {
          this.EditCarForm.patchValue({ cassette_player: data.cassette_player });
        }
        if (data.cd_player != null) {
          this.EditCarForm.patchValue({ cd_player: data.cd_player });
        }
        if (data.color != null) {
          this.EditCarForm.patchValue({ color: data.color });
        }
        if (data.cruise_control != null) {
          this.EditCarForm.patchValue({ cruise_control: data.cruise_control });
        }
        if (data.description != null) {
          this.EditCarForm.patchValue({ description: data.description });
        }
        if (data.duty != null) {
          this.EditCarForm.patchValue({ duty: data.duty });
        }
        if (data.engine_size != null) {
          this.EditCarForm.patchValue({ engine_size: data.engine_size });
        }
        if (data.fog_lights != null) {
          this.EditCarForm.patchValue({ fog_lights: data.fog_lights });
        }
        if (data.fuel != null) {
          this.EditCarForm.patchValue({ fuel: data.fuel });
        }
        if (data.intergrated_phone != null) {
          this.EditCarForm.patchValue({ intergrated_phone: data.intergrated_phone });
        }
        if (data.interior != null) {
          this.EditCarForm.patchValue({ interior: data.interior });
        }
        if (data.keyless_entry != null) {
          this.EditCarForm.patchValue({ keyless_entry: data.keyless_entry });
        }
        if (data.leather_interior != null) {
          this.EditCarForm.patchValue({ leather_interior: data.leather_interior });
        }
        if (data.location != null) {
          this.EditCarForm.patchValue({ location: data.location });
        }
        if (data.memory_seats != null) {
          this.EditCarForm.patchValue({ memory_seats: data.memory_seats });
        }
        if (data.mileage != null) {
          this.EditCarForm.patchValue({ mileage: data.mileage });
        }

        if (data.model_id != null) {
          // this.EditCarForm.patchValue({ model_id: data.model_id });
          this.model_id = data.model_id;
        }
        if (data.navigation != null) {
          this.EditCarForm.patchValue({ navigation: data.navigation });
        }
        if (data.negotiable != null) {
          this.EditCarForm.patchValue({ negotiable: data.negotiable });
        }
        if (data.power_locks != null) {
          this.EditCarForm.patchValue({ power_locks: data.power_locks });
        }
        if (data.power_seats != null) {
          this.EditCarForm.patchValue({ power_seats: data.power_seats });
        }
        if (data.power_steering != null) {
          this.EditCarForm.patchValue({ power_steering: data.power_steering });
        }
        if (data.power_windows != null) {
          this.EditCarForm.patchValue({ power_windows: data.power_windows });
        }
        if (data.price != null) {
          this.EditCarForm.patchValue({ price: data.price });
        }
        if (data.rear_window_defroster != null) {
          this.EditCarForm.patchValue({ rear_window_defroster: data.rear_window_defroster });
        }
        if (data.rear_window_wiper != null) {
          this.EditCarForm.patchValue({ rear_window_wiper: data.rear_window_wiper });
        }
        if (data.series_id != null) {
          // this.EditCarForm.patchValue({ series_id: data.series_id });
          this.series_id = data.series_id;
        }
        if (data.sunroof != null) {
          this.EditCarForm.patchValue({ sunroof: data.sunroof });
        }
        if (data.third_row_seats != null) {
          this.EditCarForm.patchValue({ third_row_seats: data.third_row_seats });
        }
        if (data.tinted_glass != null) {
          this.EditCarForm.patchValue({ tinted_glass: data.tinted_glass });
        }
        if (data.transmission != null) {
          this.EditCarForm.patchValue({ transmission: data.transmission });
        }
        if (data.use_condition != null) {
          this.EditCarForm.patchValue({ condition: data.use_condition });
        }
        if (data.year != null) {
          this.EditCarForm.patchValue({ year: data.year });
        }
      }
    });

    $('#EditCarForm').submit(function (e) {
      // console.log('aa');
      e.preventDefault();
      if (self.EditCarForm.invalid) {
        console.log('invalid');
        self.ngxNotificationService.sendMessage('Fill all fields with *', 'danger', 'bottom-left');
        return;
      }
      self.loading = true;

      var formData = new FormData();
      let CarForm = self.EditCarForm.value;

      Object.keys(CarForm).forEach((key) => {
        formData.append(key, CarForm[key]);
      })

      // console.log(CarForm); 

      self.http.post(`${config.apiUrl}/edit_car/` + self.CarId, formData)
        .subscribe({
          next: data => {
            // console.log(data);
            self.addCar(data); //callback
          },
          error: err => {
            // console.log(err);
            self.addCar(err);
          }
        })
    });
    // console.log(this.user_id);
  }

  addCar(data) {
    // console.log(data);
    this.loading = false;

    var self = this;
    if (data == 1) {
      self.ngxNotificationService.sendMessage('The car was successfully posted!', 'success', 'bottom-left');
      self.EditCarForm.reset();
      this.carsService.closeEditCar.emit('yes');
      // console.log(data);
      self.loading = false;
    } else if (data == 0) {
      self.ngxNotificationService.sendMessage('Nothing to Update!', 'warning', 'bottom-left');
      // this.carsService.closeEditCar.emit('yes');
      // console.log(data);
      self.EditCarForm.reset();
      self.loading = false;
    } else {
      self.ngxNotificationService.sendMessage('Something went wrong! Please try again', 'warning', 'bottom-left');
      self.loading = false;
    }
  }

  getBrands() {
    this.bms.getBrands().subscribe(data => {
      this.brands = data;
    });
  }

  getSpecificSeries(event: any) {
    this.seriesloading = true;
    var id = event.target.value;
    this.bms.getSpecificSeries(id).subscribe(data => {
      this.series = data;
      this.seriesloading = false;
    });
  }

  getSpecificModels(event: any) {
    this.loading = true;
    var id = event.target.value;
    this.bms.getSpecificModels(id).subscribe(data => {
      this.models = data;
      this.loading = false;
    });
  }

  getSpecificModelsManual(brand) {
    this.modelsloading = true;
    var id = brand;
    this.EditCarForm.patchValue({ model_id: '' });
    this.bms.getSpecificModels(id).subscribe(data => {
      this.models = data;
      this.modelsloading = false;
      this.EditCarForm.patchValue({ model_id: this.model_id });
      // console.log(data);
      this.getSpecificSeriesManual(this.model_id);
    });
  }

  getSpecificSeriesManual(id) {
    this.seriesloading = true;
    this.bms.getSpecificSeries(id).subscribe(data => {
      this.series = data;
      this.seriesloading = false;
      this.EditCarForm.patchValue({ series_id: this.series_id });
    });
  }

  fileProgress(fileInput: any, code) {
    if (code == 1) {
      this.EditCarForm.patchValue({ front_image: <File>fileInput.target.files[0] });
      this.front_image = <File>fileInput.target.files[0];
    } else if (code == 2) {
      this.EditCarForm.patchValue({ back_image: <File>fileInput.target.files[0] });
      this.back_image = <File>fileInput.target.files[0];
    } else if (code == 3) {
      this.EditCarForm.patchValue({ left_image: <File>fileInput.target.files[0] });
      this.left_image = <File>fileInput.target.files[0];
    } else if (code == 4) {
      this.EditCarForm.patchValue({ right_image: <File>fileInput.target.files[0] });
      this.right_image = <File>fileInput.target.files[0];
    } else if (code == 5) {
      this.EditCarForm.patchValue({ dashboard_image: <File>fileInput.target.files[0] });
      this.dashboard_image = <File>fileInput.target.files[0];
    } else if (code == 6) {
      this.EditCarForm.patchValue({ interior_image: <File>fileInput.target.files[0] });
      this.interior_image = <File>fileInput.target.files[0];
    } else if (code == 7) {
      this.EditCarForm.patchValue({ other_image1: <File>fileInput.target.files[0] });
      this.other_image1 = <File>fileInput.target.files[0];
    } else if (code == 8) {
      this.EditCarForm.patchValue({ other_image2: <File>fileInput.target.files[0] });
      this.other_image2 = <File>fileInput.target.files[0];
    } else if (code == 9) {
      this.EditCarForm.patchValue({ other_image3: <File>fileInput.target.files[0] });
      this.other_image3 = <File>fileInput.target.files[0];
    } else if (code == 10) {
      this.EditCarForm.patchValue({ other_image4: <File>fileInput.target.files[0] });
      this.other_image4 = <File>fileInput.target.files[0];
    } else if (code == 11) {
      this.EditCarForm.patchValue({ other_image5: <File>fileInput.target.files[0] });
      this.other_image5 = <File>fileInput.target.files[0];
    } else if (code == 12) {
      this.EditCarForm.patchValue({ other_image6: <File>fileInput.target.files[0] });
      this.other_image6 = <File>fileInput.target.files[0];
    }
    // this.AddBlogForm.patchValue({
    //   fileSource : this.fileData
    // });
  }

}
