  <!--Edit Blog Modal-->
  <div class="edit-blog-modal hidden">
    <div class="edit-blog-container">
        <form id="EditBlogForm" enctype='multipart/form-data' [formGroup]="EditBlogForm">
            <button (click)="hideModal('blog')" class="float-right">X</button>
            <h2>Edit Blog</h2>
            <div class="form-group">
                <label for="title">Title: </label>
                <input type="text" class="form-control" name="title" id="edit-blog-title" required formControlName="title">
            </div>
            <div class="form-group">
                <label for="body">Body: </label>                            
                <div id="edit-blog-body" class="quill_body">
                    <div id="blog-editor-container">
                    </div>                           
                </div>
            </div>
            <div class="form-group">
                <label for="excerpt">Excerpt: </label>
                <input type="text" class="form-control" placeholder="A short introduction of the blog (50 words max)" required name="excerpt" id="edit-blog-excerpt" formControlName="excerpt">
            </div>
            <div class="form-group">
                <label>Image: (Leave Blank if you don't want to change it)</label>
                <input type="file" accept="image/*" placeholder="Cover image" class="form-control" name="image" id="edit-blog-image" formControlName="image" (change)="fileProgress($event)"> 
            </div>
            <div class="form-group">
                <label for="author">Author: </label>
                <input type="text" class="form-control" required name="author" id="edit-blog-author" formControlName="author">
            </div>
            <!-- <div class="form-group">
                <input type="checkbox" value="featured" name="featured" id="edit-blog-featured">
                <label for="featured">Send to homepage slider</label>                           
            </div> -->
            <input [disabled]="loading"  class="site-btn" type="submit" value="Post Blog"
                name="submit" id="edit-blog-submit">
                <div *ngIf="loading" class="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            <lib-ngx-notification></lib-ngx-notification>
        </form>
    </div>
   
</div>
<!--Edit blog modal end-->

        <!--edit blog list section-->
        <div class="add-container-inner">
            <div class="panel-heading">
                <a data-toggle="collapse" data-parent="#accordion" href="#collapse8">
                    <h3>
                        Edit Blogs
                    </h3>
                </a>
            </div>
            <div id="collapse8" class="panel-collapse collapse">
                <div class="table-responsive">
                    <table class="table" id="blogs-table">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Title</th>
                            <th>Body</th>
                            <th>Excerpt</th>
                            <th>Edit</th>
                            <th>Delete</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let blog of Blogs; let i=index">
                            <td>{{i+1}}</td>
                            <td>{{blog['title']}}</td>
                            <td [innerHtml]="blog['body'].substr(0,100)+'...'"></td>
                            <td ><p *ngIf="blog['excerpt']">{{blog['excerpt'].substr(0,100)+'...'}}</p></td>
                            <td><button (click)="editBlog(i)">Edit</button></td>
                            <td>
                                <button (click)="showPrompt('delete_blog_'+blog['id'])">Delete</button>
                                    <div id="delete_button_{{blog['id']}}" class="hidden" id="delete_blog_{{blog['id']}}">
                                        <h5>Are you sure You want to delete this blog </h5>
                                        <button (click)="deleteBlog(blog['id'])" class="button-red" >Yes</button>
                                        <button (click)="hidePrompt('delete_blog_'+blog['id'])" class="button-green">No</button>
                                    </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>                    
                    <lib-ngx-notification></lib-ngx-notification>
                </div>
            </div>
          </div>
        <!--edit blog list end-->
        