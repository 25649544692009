<div class="add-container-inner">

    <div class="panel-heading w3-padding">
        <a data-toggle="collapse" data-parent="#accordion" href="#collapse1">
            <h3><span class="w3-margin-right"><i class="fa fa-plus"></i></span>
                Add New Blog    
            </h3>
        </a>
    </div>
    <div id="collapse1" class="panel-collapse collapse in w3-panel">
        <form id="AddBlogForm" enctype='multipart/form-data' [formGroup]="AddBlogForm">
            <div class="form-group">
                <label for="title">Title: </label>
                <input type="text" class="form-control" name="title" id="title" required formControlName="title">
            </div>
            <div class="form-group">
                <label for="body">Body: </label>               
                <div id="body" class="quill_body">
                    <div id="editor-container">
                    </div>                           
                </div>
            </div>
            <div class="form-group">
                <label>Image: </label>
                <input type="file" accept="image/*" placeholder="Cover image" class="form-control" required name="image" id="image" formControlName="image" (change)="fileProgress($event)"> 
            </div>
            <div class="form-group">
                <label for="author">Author: </label>
                <input type="text" placeholder="William Shakespear?" class="form-control" required name="author" id="author" formControlName="author">
            </div>
            <div class="form-group">
                <label for="excerpt">Excerpt</label>
                <input type="text" placeholder="A Short description of the blog. (50 words max)" class="form-control" required name="excerpt" id="excerpt" formControlName="excerpt">
            </div>
            <div class="form-group">
                <label for="tags">Tags: <p>(Comma Separated)</p></label>
                <input type="text" placeholder="audi, convertible, electric" class="form-control" required name="tags" id="tags" formControlName="tags">
            </div>
            <input [disabled]="loading"  class="site-btn" type="submit" value="Post Blog"
                name="submit" id="blog_submit">
                <div *ngIf="loading" class="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            <lib-ngx-notification></lib-ngx-notification>
        </form>
    </div>          
</div>