import { Injectable, EventEmitter } from '@angular/core';
import * as $ from 'jquery';
import { config } from '../_helpers/global';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CarsService {
  SingleCar: any;

  toggleEditCar = new EventEmitter();
  closeEditCar = new EventEmitter();

  constructor(private http: HttpClient) { }

  getCar(id) {
    return this.http.get<any>(`${config.apiUrl}/get_car/` + id);
  }

  getCars(dealer_id) {
    return this.http.get<any>(`${config.apiUrl}/get_cars_by_dealer/` + dealer_id);
  }
}
