import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SingleCarViewComponent } from './single-car-view/single-car-view.component';

import { HomepageComponent } from './homepage/homepage.component';
import { from } from 'rxjs';
import { BlogComponent } from './blog/blog.component';
import { SingleBlogComponent } from './single-blog/single-blog.component';
import { SearchComponent } from './search/search.component';
import { AdminPortalComponent } from './admin-portal/admin-portal.component';
import { ProfileComponent } from './profile/profile.component';
import { DealersComponent } from './dealers/dealers.component';
import { DealerComponent } from './dealer/dealer.component';
import { MakesComponent } from './makes/makes.component';
import { LocallyUsedComponent } from './locally-used/locally-used.component';
import { ForgotPaswordPageComponent } from './forgot-pasword-page/forgot-pasword-page.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';
import { AboutComponent } from './about/about.component';
import { Page404Component } from './page404/page404.component';


const routes: Routes = [
  { path: 'car/:name/:id', component: SingleCarViewComponent },
  { path: 'single-car/:id', component: SingleCarViewComponent },
  { path: 'blog/single/:id', component: SingleBlogComponent },
  { path: '', component: HomepageComponent },
  { path: 'blogs', component: BlogComponent },
  { path: 'blog/:title/:id', component: SingleBlogComponent },
  { path: 'search', component: SearchComponent },
  { path: 'admin-portal', component: AdminPortalComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'dealers', component: DealersComponent },
  { path: 'dealer/:id', component: DealerComponent },
  { path: 'reset_password/:id/:code', component: ForgotPaswordPageComponent },
  { path: 'makes', component: MakesComponent },
  { path: 'locally-used', component: LocallyUsedComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'terms-of-service', component: TermsOfServiceComponent },
  { path: 'about', component: AboutComponent },
  {
    path: '**',
    component: SearchComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
