import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { config } from '../_helpers/global';
import { ActivatedRoute } from '@angular/router';
import { DealersService } from '../_services/dealers.service';


@Component({
  selector: 'app-dealers',
  templateUrl: './dealers.component.html',
  styleUrls: ['./dealers.component.css']
})
export class DealersComponent implements OnInit {
  url;
  Dealers;
  loading = false;

  constructor( private _Activatedroute:ActivatedRoute, private dealersService: DealersService ) { }

  ngOnInit() {
    this.url = config.Url;
    var self = this;
    this.getDealers();
  }

  getDealers () { 
    this.loading = true;
    this.dealersService.getDealers().subscribe( data=> {
      // console.log(data);
      this.loading = false;
      this.Dealers = data;
      var userid = data[0]['dealer_id'];
    }, error=> {
      this.loading = false;
      console.log(error);
    }
    );
  }

}
