import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-locally-used',
  templateUrl: './locally-used.component.html',
  styleUrls: ['./locally-used.component.css']
})
export class LocallyUsedComponent implements OnInit {

  constructor(private title: Title,
    private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle('German Rides Hub | GRH - Buy or sell German cars in Kenya');
    this.meta.updateTag({ name: 'description', content: 'A market place where you can buy or sell German cars (Audi, BMW, Porsche, VW, Land Rover and Mercedes Benz) in Kenya.' });
  }

}
