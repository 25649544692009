<form id="AddCarForm" enctype='multipart/form-data' [formGroup]="AddCarForm">

    <div class="row">
        <div class="form-group col-md-6">
            <label for="brand">Make: *</label>
            <select (change)="getSpecificModels($event)" formControlName="brand_id" id="brand" name="brand_id"
                class="form-control">
                <option value="" disabled selected='selected'>Please Select</option>
                <option *ngFor="let brand of brands" value="{{brand['id']}}">{{brand['brand']}}</option>
            </select>
        </div>
        <div class="form-group col-md-6">
            <label for="model">Model: *</label>
            <select (change)="getSpecificSeries($event)" formControlName="model_id" id="model" name="model_id"
                class="form-control">
                <option disabled value="" selected>
                    <p *ngIf="!loading">Please Select </p>
                    <div *ngIf="loading">Loading...
                    </div>
                </option>
                <option *ngFor="let model of models" value="{{model['id']}}">{{model['model']}}</option>
            </select>
        </div>
    </div>
    <div class="row">
        <div class="form-group col-md-6">
            <label for="series">Series: </label>
            <select formControlName="series_id" id="series" name="series_id" class="form-control">
                <option disabled value="" selected>
                    <p *ngIf="!seriesloading">Please Select </p>
                    <div *ngIf="seriesloading">Loading...
                    </div>
                </option>
                <option *ngFor="let series of series" value="{{series['id']}}">{{series['series']}}</option>
            </select>
        </div>
        <div class="form-group col-md-6">
            <label for="year">Year: *</label>
            <select formControlName="year" id="year" name="year" class="form-control">
                <option disabled value="" selected="selected">Please Select</option>
                <option value="2021">2021</option>
                <option value="2020">2020</option>
                <option value="2019">2019</option>
                <option value="2018">2018</option>
                <option value="2017">2017</option>
                <option value="2016">2016</option>
                <option value="2015">2015</option>
                <option value="2014">2014</option>
                <option value="2013">2013</option>
                <option value="2012">2012</option>
                <option value="2011">2011</option>
                <option value="2010">2010</option>
                <option value="2009">2009</option>
                <option value="2008">2008</option>
                <option value="2007">2007</option>
                <option value="2006">2006</option>
                <option value="2005">2005</option>
                <option value="2004">2004</option>
                <option value="2003">2003</option>
                <option value="2002">2002</option>
                <option value="2001">2001</option>
                <option value="2000">2000</option>
                <option value="1999">1999</option>
                <option value="1998">1998</option>
                <option value="1997">1997</option>
                <option value="1996">1996</option>
                <option value="1995">1995</option>
                <option value="1994">1994</option>
                <option value="1993">1993</option>
                <option value="1992">1992</option>
                <option value="1991">1991</option>
                <option value="1990">1990</option>
                <option value="1989">1989</option>
                <option value="1988">1988</option>
                <option value="1987">1987</option>
                <option value="1986">1986</option>
                <option value="1985">1985</option>
                <option value="1984">1984</option>
                <option value="1983">1983</option>
                <option value="1982">1982</option>
                <option value="1981">1981</option>
                <option value="1980">1980</option>
                <option value="1979">1979</option>
                <option value="1978">1978</option>
                <option value="1977">1977</option>
                <option value="1976">1976</option>
                <option value="1975">1975</option>
                <option value="1974">1974</option>
                <option value="1973">1973</option>
                <option value="1972">1972</option>
                <option value="1971">1971</option>
                <option value="1970">1970</option>
                <option value="1969">1969</option>
                <option value="1968">1968</option>
                <option value="1967">1967</option>
                <option value="1966">1966</option>
                <option value="1965">1965</option>
                <option value="1964">1964</option>
                <option value="1963">1963</option>
                <option value="1962">1962</option>
                <option value="1961">1961</option>
                <option value="1960">1960</option>
                <option value="1959">1959</option>
                <option value="1958">1958</option>
                <option value="1957">1957</option>
                <option value="1956">1956</option>
                <option value="1955">1955</option>
                <option value="1954">1954</option>
                <option value="1953">1953</option>
                <option value="1952">1952</option>
                <option value="1951">1951</option>
                <option value="1950">1950</option>
            </select>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 form-group">
            <label for="mileage">Mileage (km): </label>
            <input type="number" class="form-control" name="mileage" id="mileage" formControlName="mileage"
                placeholder="eg: 30000 (No commas)">
        </div>

        <div class="form-group col-md-6">
            <label for="body_type">Body Type: *</label>
            <select formControlName="body_type" id="body_type" name="body_type" class="form-control">
                <option value="" disabled selected="selected">Please Select</option>
                <option value="Saloons">Saloons</option>
                <option value="Hatchbacks">Hatchbacks</option>
                <option value="SUV">4 Wheel Drives &amp; SUVs</option>
                <option value="Station Wagon">Station Wagons</option>
                <option value="Pickups">Pickups</option>
                <option value="Motorbikes">Motorbikes</option>
                <option value="Convertibles">Convertibles</option>
                <option value="Buses and Vans">Buses, Taxis and Vans</option>
                <option value="Trucks">Trucks</option>
                <option value="Machinery and Tractors">Machinery and Tractors</option>
                <option value="Trailers">Trailers</option>
                <option value="Minis">Minis</option>
                <option value="Coupes">Coupes</option>
                <option value="Quad Bikes">Quad Bikes</option>
                <option value="Other">Other</option>
            </select>
        </div>
    </div>

    <div class="row">
        <div class="form-group col-md-6">
            <label for="condition">Condition: *</label>
            <select formControlName="condition" id="condition" name="condition" class="form-control">
                <option disabled value="" selected>Please Select</option>
                <option value="new">Brand New</option>
                <option value="foreign">Foreign Used</option>
                <option value="local">Locally Used</option>
            </select>
        </div>

        <div class="form-group col-md-6">
            <label for="transmission">Transmission Type: *</label>
            <select formControlName="transmission" id="transmission" name="transmission" class="form-control">
                <option disabled selected value="">Please Select</option>
                <option value="manual">Manual</option>
                <option value="automatic">Automatic</option>
                <option value="other">Other</option>
            </select>
        </div>
    </div>
    <div class="row">
        <div class="form-group col-md-6">
            <label for="price">Price (Kshs): *</label>
            <input type="number" class="form-control" name="price" id="price" required formControlName="price"
                placeholder="Eg: 4000000 (No Commas)">
            <input type="checkbox" id="negotiable" name="negotiable" formControlName="negotiable" value="negotiable">
            <label for="negotiable">Negotiable</label>
        </div>
        <div class="col-md-6 form-group">
            <label for="duty">Duty *</label>
            <select formControlName="duty" id="duty" name="duty" class="form-control">
                <option value="" disabled selected>Please Select</option>
                <option value="Duty Exempted">Duty Exempted</option>
                <option value="Not specified">Not specified</option>
                <option value="Duty Not Paid">Duty Not Paid</option>
                <option value="Duty Paid">Duty Paid</option>
            </select>
        </div>
    </div>

    <div id="features" class="row">
        <h3 class="w3-text-center text-center w3-block">Features</h3>
        <div class="form-group w3-block">
            <label for="fuel">Fuel Type *</label>

            <div id="fuel" class="radio">
                <label class="radio-inline">
                    <input type="radio" formControlName="fuel" value="diesel" name="fuel">
                    Diesel
                </label>
                <label class="radio-inline">
                    <input type="radio" formControlName="fuel" value="petrol" name="fuel">
                    Petrol
                </label>
                <label class="radio-inline">
                    <input type="radio" formControlName="fuel" value="hybrid" name="fuel">
                    Hybrid
                </label>
                <label class="radio-inline">
                    <input type="radio" formControlName="fuel" value="diesel hybrid" name="fuel">
                    Diesel-Hybrid
                </label>
                <label class="radio-inline">
                    <input type="radio" formControlName="fuel" value="electric" name="fuel">
                    Electric
                </label>
                <label class="radio-inline">
                    <input type="radio" formControlName="fuel" value="other" name="fuel">Other
                </label>
                <!-- <label><input type="radio" name="optradio" checked>Option 1</label> -->
            </div>
        </div>
        <div class="form-group w3-block">
            <label for="interior">Interior *</label>
            <div id="interior " class="radio">
                <label class="radio-inline"><input type="radio" formControlName="interior" value="cloth"
                        name="interior">Cloth</label>
                <label class="radio-inline"><input type="radio" formControlName="interior" value="leather"
                        name="interior">Leather</label>
                <label class="radio-inline"><input type="radio" formControlName="interior" value="other"
                        name="interior">Other</label>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 form-group">
                <label for="color">Color *</label>
                <select formControlName="color" id="color" name="color" class="form-control">
                    <option value="" disabled selected>Please Select</option>
                    <option value="white">White</option>
                    <option value="silver">Silver</option>
                    <option value="green">Green</option>
                    <option value="dark green">Dark Green</option>
                    <option value="blue">Blue</option>
                    <option value="dark blue">Dark Blue</option>
                    <option value="brown">Brown</option>
                    <option value="black">Black</option>
                    <option value="yellow">Yellow</option>
                    <option value="red">Red</option>
                    <option value="maroon">Maroon</option>
                    <option value="purple">Purple</option>
                    <option value="pink">Pink</option>
                    <option value="orange">Orange</option>
                    <option value="grey">Grey</option>
                    <option value="dark grey">Dark Grey</option>
                    <option value="gold">Gold</option>
                    <option value="beige">Beige</option>
                    <option value="pearl">Pearl</option>
                    <option value="other">Other</option>
                </select>
            </div>
            <div class="col-md-6 form-group">
                <label for="engine_size">Engine size (cc) *</label>
                <input type="number" class="form-control" name="engine_size" id="engine_size" required
                    formControlName="engine_size">
            </div>
        </div>
        <div class="form-group w3-block">
            <label for="description">Description</label>
            <textarea class="form-control" rows="5" formControlName="description" id="description" name="description">

            </textarea>
        </div>
        <div class="">
            <h3 class="w3-block">Additional Features (OPTIONAL)<p>Tick the ones that apply to you </p>
            </h3>

            <label>Comfort</label>
            <div class="checkbox">
                <input type="checkbox" name="ac_front" formControlName="ac_front" value="ac front">A/C: Front
            </div>
            <div class="checkbox">
                <input type="checkbox" name="ac_rear" formControlName="ac_rear" value="ac rear">A/C: Rear
            </div>
            <div class="checkbox">
                <input type="checkbox" name="cruise_control" formControlName="cruise_control"
                    value="cruise control">Cruise control
            </div>
            <div class="checkbox">
                <input type="checkbox" name="navigation" formControlName="navigation" value="navigation">Navigation
            </div>
            <div class="checkbox">
                <input type="checkbox" name="power_locks" formControlName="power_locks" value="power locks">Power Locks
            </div>
            <div class="checkbox">
                <input type="checkbox" name="power_steering" formControlName="power_steering"
                    value="power steering">Power Steering
            </div>
            <div class="checkbox">
                <input type="checkbox" name="keyless_entry" formControlName="keyless_entry"
                    value="keyless entry">Keyless Entry
            </div>
            <div class="checkbox">
                <input type="checkbox" name="intergrated_phone" formControlName="intergrated_phone"
                    value="intergrated phone">Intergrated Phone
            </div>
            <label>Seats</label>
            <div class="checkbox">
                <input type="checkbox" name="bucket_seats" formControlName="bucket_seats" value="bucket seats">Bucket
                Seats
            </div>
            <div class="checkbox">
                <input type="checkbox" name="leather_interior" formControlName="leather_interior"
                    value="leather interior">Leather Interior
            </div>
            <div class="checkbox">
                <input type="checkbox" name="memory_seats" formControlName="memory_seats" value="memory seats">Memory
                Seats
            </div>
            <div class="checkbox">
                <input type="checkbox" name="power_seats" formControlName="power_seats" value="power seats">Power Seats
            </div>
            <label>Safety</label>
            <div class="checkbox">
                <input type="checkbox" name="airbag_driver" formControlName="airbag_driver"
                    value="airbag driver">Airbag: Driver
            </div>
            <div class="checkbox">
                <input type="checkbox" name="airbag_passenger" formControlName="airbag_passenger"
                    value="airbag passenger">Airbag: Passenger
            </div>
            <div class="checkbox">
                <input type="checkbox" name="airbag_side" formControlName="airbag_side" value="airbag side">Airbag: Side
            </div>
            <div class="checkbox">
                <input type="checkbox" name="alarm" formControlName="alarm" value="alarm">Alarm
            </div>
            <div class="checkbox">
                <input type="checkbox" name="antilock_brakes" formControlName="antilock_brakes"
                    value="antilock brakes">Antilock Brakes
            </div>
            <div class="checkbox">
                <input type="checkbox" name="fog_lights" formControlName="fog_lights" value="fog lights">Fog Lights
            </div>
            <label>Windows</label>
            <div class="checkbox">
                <input type="checkbox" name="power_windows" formControlName="power_windows" value="power windows">Power
                Windows
            </div>
            <div class="checkbox">
                <input type="checkbox" name="rear_window_defroster" formControlName="rear_window_defroster"
                    value="rear window defroster">Rear Window Defroster
            </div>
            <div class="checkbox">
                <input type="checkbox" name="rear_window_wiper" formControlName="rear_window_wiper"
                    value="rear window wiper">Rear Window Wiper
            </div>
            <div class="checkbox">
                <input type="checkbox" name="tinted_glass" formControlName="tinted_glass" value="tinted glass">Tinted
                Glass
            </div>
            <label>Entertainment</label>
            <div class="checkbox">
                <input type="checkbox" name="am_fm_stereo" formControlName="am_fm_stereo" value="am fm Stereo">Am/FM
                Stereo
            </div>
            <div class="checkbox">
                <input type="checkbox" name="cd_player" formControlName="cd_player" value="cd player">cd_player
            </div>
            <div class="checkbox">
                <input type="checkbox" name="cassette_player" formControlName="cassette_player"
                    value="cassette player">Cassette Player
            </div>
            <label>Other</label>
            <div class="checkbox">
                <input type="checkbox" name="alloy_wheels" formControlName="alloy_wheels" value="alloy wheels">Alloy
                Wheels
            </div>
            <div class="checkbox">
                <input type="checkbox" name="sunroof" formControlName="sunroof" value="sunrooof">Sunroof
            </div>
            <div class="checkbox">
                <input type="checkbox" name="third_row_seats" formControlName="third_row_seats"
                    value="third row seats">Third Row Seats
            </div>
        </div>
    </div>


    <div id="images" class="row">
        <h3 class="w3-text-center text-center w3-block">Images</h3>
        <div class="form-group col-md-4">
            <label>Front: *</label>
            <input type="file" accept="image/*" placeholder="Front" class="form-control" required name="front_image"
                id="front_image" formControlName="front_image" (change)="fileProgress($event,1)">
        </div>
        <div class="form-group col-md-4">
            <label>Back: *</label>
            <input type="file" accept="image/*" placeholder="back" class="form-control" required name="back_image"
                id="back_image" formControlName="back_image" (change)="fileProgress($event,2)">
        </div>
        <div class="form-group col-md-4">
            <label>Left: *</label>
            <input type="file" accept="image/*" placeholder="left" class="form-control" required name="left_image"
                id="left_image" formControlName="left_image" (change)="fileProgress($event,3)">
        </div>
        <div class="form-group col-md-4">
            <label>Right: *</label>
            <input type="file" accept="image/*" placeholder="back" class="form-control" required name="right_image"
                id="right_image" formControlName="right_image" (change)="fileProgress($event,4)">
        </div>
        <div class="form-group col-md-4">
            <label>Dashboard: *</label>
            <input type="file" accept="image/*" placeholder="Dashboard" class="form-control" required
                name="dashboard_image" id="dashboard_image" formControlName="dashboard_image"
                (change)="fileProgress($event,5)">
        </div>
        <div class="form-group col-md-4">
            <label>Interior: *</label>
            <input type="file" accept="image/*" placeholder="interior" class="form-control" required
                name="interior_image" id="interior_image" formControlName="interior_image"
                (change)="fileProgress($event,6)">
        </div>
        <div class="form-group col-md-4">
            <label>Other: </label>
            <input type="file" accept="image/*" placeholder="other" class="form-control" name="other_image1"
                id="other_image1" formControlName="other_image1" (change)="fileProgress($event,7)">
        </div>
        <div class="form-group col-md-4">
            <label>Other: </label>
            <input type="file" accept="image/*" placeholder="other" class="form-control" name="other_image2"
                id="other_image2" formControlName="other_image2" (change)="fileProgress($event,8)">
        </div>
        <div class="form-group col-md-4">
            <label>Other: </label>
            <input type="file" accept="image/*" placeholder="other" class="form-control" name="other_image3"
                id="other_image3" formControlName="other_image3" (change)="fileProgress($event,9)">
        </div>
        <div class="form-group col-md-4">
            <label>Other: </label>
            <input type="file" accept="image/*" placeholder="other" class="form-control" name="other_image4"
                id="other_image4" formControlName="other_image4" (change)="fileProgress($event,10)">
        </div>
        <div class="form-group col-md-4">
            <label>Other: </label>
            <input type="file" accept="image/*" placeholder="other" class="form-control" name="other_image5"
                id="other_image5" formControlName="other_image5" (change)="fileProgress($event,11)">
        </div>
        <div class="form-group col-md-4">
            <label>Other: </label>
            <input type="file" accept="image/*" placeholder="other" class="form-control" name="other_image6"
                id="other_image6" formControlName="other_image6" (change)="fileProgress($event,12)">
        </div>
    </div>
    <input [disabled]="loading" class="site-btn" type="submit" value="Post Car" name="submit" id="car_submit">
    <div *ngIf="loading" class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
    <lib-ngx-notification></lib-ngx-notification>
</form>