import { Injectable } from '@angular/core';
import { config } from '../_helpers/global';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class BlogsServiceService {

  constructor(private http: HttpClient) { }

  getComments(id) {
    return this.http.get<any>(`${config.apiUrl}/get_comments/` + id);
  }

  addComment(data) {
    return this.http.post(`${config.apiUrl}/add_comment`, data);
  }

}