<!-- <a id="floating-whatsapp" data-bs-toggle="tooltip" data-bs-placement="top" title="Chat with us on whatsapp"
    (click)="whatsappRedirect()"><img src="assets/images/whatsapp.png" alt=""></a> -->

<div class="w3-row-padding footers">
  <div class="w3-col l4 m4 s12">
    <h4>Quick Links</h4>
    <p><a routerLink="">Home</a></p>
    <p><a href="http://blog.germanrideshub.co.ke/">Blog</a></p>
    <p><a routerLink="/makes">Makes</a></p>
    <p><a routerLink="/locally-used">Locally Used</a></p>
    <!-- <p><a routerLink="/dealers">Dealers</a></p> -->
  </div>
  <div class="w3-col l4 m4 s12">
    <h4>Contact</h4>
    <p>
      <span>Email: </span>
      <a
        class="contact-text w3-large"
        target="_blank"
        href="mailto:info@germanrideshub.co.ke"
        >info@germanrideshub.co.ke</a
      >
    </p>
    <p>
      <span>Phone: </span
      ><a class="contact-text" href="tel://+254704181628">+254 704 181628</a>
    </p>
    <p>
      <a
        target="_blank"
        href="https://www.facebook.com/GermanRidesHub.co.ke/?modal=admin_todo_tour"
        ><i class="fa fa-facebook-square fa-3x"></i
      ></a>
      <a target="_blank" href="https://www.instagram.com/german_rides_hub"
        ><i class="fa fa-instagram fa-3x pl-4"></i
      ></a>
      <!-- <a href=""><i class="fa fa-twitter-square fa-3x pl-4"></i></a> -->
    </p>
  </div>
  <div class="w3-col l4 m4 s12">
    <h4>Company</h4>
    <p class="cursor-pointer" routerLink="/about">About</p>
    <p class="cursor-pointer" routerLink="/terms-of-service">
      Terms of service
    </p>
    <p routerLink="/privacy-policy" class="cursor-pointer">Privacy policy</p>
    <!-- <p><a href="http://www.dennisweru.rf.gd">Contact Developer</a></p> -->
  </div>
  <div class="w3-row center">
    <p>Copyright {{ Year }}. <b>GermanRidesHub</b> All rights reserved</p>
  </div>
</div>
