import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { config } from '../_helpers/global';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { BlogsServiceService } from '../_services/blogs-service.service';
import { NgxNotificationService } from 'ngx-notification';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoggedInService } from '../_services/logged-in.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-single-blog',
  templateUrl: './single-blog.component.html',
  styleUrls: ['./single-blog.component.css']
})

export class SingleBlogComponent implements OnInit {
  url: any;
  sub;
  Blog: any;
  RecommendedCars: any;
  RecommendedBlogs: any;
  relatedcarsloading = true;
  CurrentId: any;
  blogimg: any;
  EditorPicks: any;
  Comments: any;
  AddCommentForm: FormGroup;
  LoggedIn: any;
  postcommentloading = false;

  constructor(private _Activatedroute: ActivatedRoute, private router: Router, private loggedInservice: LoggedInService, private BS: BlogsServiceService, private ngxNotificationService: NgxNotificationService, private formBuilder: FormBuilder, private title: Title,
    private meta: Meta) { }

  ngOnInit() {
    this.url = config.Url;
    var self = this;
    this.AddCommentForm = this.formBuilder.group({
      comments: ['', Validators.required],
      commentor: ['', Validators.required],
      blog_id: ['', Validators.required],
    });
    this.getCurrentId(self);
    this.loggedInservice.checkStatus;
    this.LoggedIn = this.loggedInservice.loggedin;
    this.AddCommentForm.patchValue({ commentor: this.loggedInservice.username });
    this.appendJs();

    $('body').on('click', '.rate-btn', function () {
      $('#nav-login-btn').click();
    });
  }

  ngAfterViewInit() {

  }

  setMetatags(title, excerpt) {
    this.title.setTitle(title + "| GRH");
    this.meta.updateTag({ name: 'description', content: excerpt });
  }

  addComment() {
    if (this.LoggedIn == false) {
      $('#nav-login-btn').click();
      return;
    }

    if (this.AddCommentForm.invalid && this.postcommentloading != true) {
      return;
    }

    this.postcommentloading = true;
    this.BS.addComment(this.AddCommentForm.value).subscribe(data => {
      this.postcommentloading = false;
      if (data == 1) {
        this.ngxNotificationService.sendMessage('Comment Successfully posted', 'success', 'bottom-left');
        this.AddCommentForm.patchValue({ comments: '' });
        this.getComments(this.CurrentId);
      } else {
        this.ngxNotificationService.sendMessage('Something went wrong, please try again', 'warning', 'bottom-left');
      }
    });
  }

  likeComment(id) {

  }

  dislikeComment(id) {

  }

  getComments(id) {
    this.BS.getComments(id).subscribe(data => {
      this.Comments = data;
    });
  }

  removeElement(elementId) {
    // Removes an element from the document
    var element = document.getElementById(elementId);
    element.parentNode.removeChild(element);
    // console.log('removed');
  }

  appendJs() {
    if (document.getElementById("share__buttons")) {
      this.removeElement("share__buttons");
    }
    let script = document.createElement('script');
    script.src = "https://platform-api.sharethis.com/js/sharethis.js#property=5eccf0c47cfa4a0012b4762c&product=inline-share-buttons";
    script.id = "share__buttons";
    if (document.getElementById('head').appendChild(script)) {
      // console.log('loaded');
    }
  }

  getBlog(self) {
    var str = self.CurrentId;
    $.ajax(`${config.apiUrl}/get_blog/` + str, {
      success: function (data) {
        self.Blog = data;
        self.blogimg = data[0]['image'];
        self.setMetatags(data[0]['title'], data[0]['excerpt']);
        // console.log(self.Blog);
      },
      error: function (err) {
      }
    });
  }

  getRecommendedBlogs(self) {
    var str = self.CurrentId;
    $.ajax(`${config.apiUrl}/get_recommended_blogs/` + str, {
      success: function (data) {
        self.RecommendedBlogs = data;
        // console.log(self.RecommendedBlogs);
      },
      error: function (err) {
        // console.log(err);
      }
    });
  }

  getEditorPicks(self) {
    var str = "time/5/none";
    $.ajax(`${config.apiUrl}/get_blogs/` + str, {
      success: function (data) {
        self.EditorPicks = data;
        // console.log(self.RecommendedBlogs);
      },
      error: function (err) {
        // console.log(err);
      }
    });
  }

  getRecommendedCars(self) {
    var str = self.CurrentId;
    $.ajax(`${config.apiUrl}/get_recommended_cars/` + str, {
      success: function (data) {
        self.RecommendedCars = data;
        self.relatedcarsloading = false;
        // console.log(self.RecommendedCars);
      },
      error: function (err) {
        // console.log(err);
      }
    });
  }

  getCurrentId(self) {
    self.sub = self._Activatedroute.paramMap.subscribe(params => {
      self.CurrentId = params.get('id');
    });
    self.getBlog(self); //Get the blog details
    self.getComments(self.CurrentId);
    self.getRecommendedBlogs(self);
    self.getRecommendedCars(self);
    self.getEditorPicks(self);
    this.AddCommentForm.patchValue({ blog_id: self.CurrentId });
  }
}
