import { Component, OnInit } from '@angular/core';
import { config } from '../_helpers/global';
import { ActivatedRoute } from '@angular/router';
import { DealersService } from '../_services/dealers.service';
import { Router } from '@angular/router';
import { LoggedInService } from '../_services/logged-in.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CarsService } from '../_services/cars.service';
import * as $ from 'jquery';
import { NgxNotificationService } from 'ngx-notification';

@Component({
  selector: 'app-dealer',
  templateUrl: './dealer.component.html',
  styleUrls: ['./dealer.component.css']
})
export class DealerComponent implements OnInit {
  url: any;
  loading = false;
  Dealer: any;
  Cars: any;
  UrlId: any;
  DealerId: any;
  sub;
  LoggedIn = false;
  fileData: File = null;
  EditDealerForm: FormGroup;
  submitted = false;
  DeletingId: any;
  UserId: any; //logged in user
  DealerUserID: any;  // current page user
  admin: any;

  constructor(private loggedInservice: LoggedInService,
    private formBuilder: FormBuilder, private _Activatedroute: ActivatedRoute, private dealersService: DealersService, private router: Router, private loggedInService: LoggedInService, private carsService: CarsService, private ngxNotificationService: NgxNotificationService) { }

  ngOnInit() {
    var self = this;
    this.loggedInservice.checkStatus;
    this.UserId = this.loggedInservice.userId;
    // console.log(this.UserId);
    this.admin = this.loggedInservice.admin;
    if (!this.loggedInService.loggedin) {
      this.router.navigate(['/']);
    }
    this.url = config.Url;
    this.EditDealerForm = this.formBuilder.group({
      name: [''],
      location: [''],
      email: ['', Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")],
      phone_number: ['', [
        Validators.pattern("^[0-9]*$"),
        Validators.minLength(10), Validators.maxLength(10)
      ]],
      description: [''],
      cover_photo: [''],
      profile_photo: ['']
    });
    this.getCurrentId();
    $('#EditDealerForm').submit(function (e) {
      e.preventDefault();
      var formData = new FormData(this);
    });

    $('#EditDealerForm').submit(function (e) {
      e.preventDefault();
      self.submitted = true;
      if (self.EditDealerForm.invalid) {
        console.log('invalid');
        return;
      }
      var formData = new FormData(this);
      var id = self.UrlId;
      self.loading = true;
      // console.log(formData);
      $.ajax({
        type: 'POST',
        url: `${config.apiUrl}/update_dealer/` + id,
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        success: (data) => {
          self.submitted = false;
          if (data == 1) {
            self.ngxNotificationService.sendMessage('Dealer details successfully updated!', 'success', 'bottom-left');
            // console.log(data);
            self.loading = false;
            $('#id0').fadeOut();
            self.getDealer(self.DealerUserID);
          } else {
            console.log(data);
            self.ngxNotificationService.sendMessage('Something went wrong!', 'warning', 'bottom-left');
            self.loading = false;
          }
        },
        error: function (err) {
          self.submitted = false;
          console.log(err);
          self.ngxNotificationService.sendMessage('Something went wrong!', 'warning', 'bottom-left');
          self.loading = false;
          // self.addBlog(data);
        }
      });
    });

    this.carsService.closeEditCar.subscribe(data => {
      $('#editcarmodal').fadeOut();
      this.getCars(this.DealerUserID);
    })
  }

  confirmDelete(id) {
    this.DeletingId = id;
    $('#delete_car').fadeIn();
  }

  editCar(id) {
    // onclick="document.getElementById('editcarmodal').style.display='none'"
    $('#editcarmodal').fadeIn();
    this.carsService.toggleEditCar.emit(this.Cars[id]);
  }

  deleteCar() {
    this.dealersService.deleteCar(this.DeletingId).subscribe(data => {
      if (data == 1) {
        this.ngxNotificationService.sendMessage('Car sucessfuly deleted!', 'success', 'bottom-left');
        this.getCars(this.DealerUserID);
      } else if (data == 0) {
        this.ngxNotificationService.sendMessage('Something went wrong!', 'warning', 'bottom-left');
      }
    }, error => {
      this.ngxNotificationService.sendMessage('Something went wrong!', 'warning', 'bottom-left');
    });
    $('#delete_car').fadeOut();
  }

  cancelDelete() {
    $('#delete_car').fadeOut();
  }

  getCurrentId() {
    var dealerstatus;
    this.sub = this._Activatedroute.paramMap.subscribe(params => { //get id from the  url
      this.UrlId = params.get('id');

      this.dealersService.getDealerById(this.UrlId).subscribe(data => { //get dealer id of logged in user
        // let userid = data[0]['user_id'];
        this.DealerUserID = data[0]['user_id'];
        this.DealerId = data[0]['id'];
        dealerstatus = data[0]['status'];
        this.isLoggedIn();
        if (this.DealerId == this.loggedInService.dealerid || this.loggedInService.dealerid || this.admin == true) {  //If this Admin or this is the dealer who is logged in
          this.getDealer(this.DealerUserID); //Get dealer details
          this.getCars(this.DealerUserID); //Get dealer cars
        } else {
          this.router.navigate(['/']);
        }
      }, error => {
        console.log(error);
      });
    });
  }

  getDealer(userid) {
    this.dealersService.getDealer(userid).subscribe(data => {
      this.Dealer = data;
      // console.log(data);
      this.EditDealerForm.patchValue({ 'name': this.Dealer[0]['name'], 'phone_number': this.Dealer[0]['phone_number'], 'email': this.Dealer[0]['email'], 'location': this.Dealer[0]['location'], 'description': this.Dealer[0]['description'] });
    }, error => {
      console.log(error);
    });
  }

  getCars(DealerId) {
    this.loading = true;
    this.carsService.getCars(DealerId).subscribe(data => {
      this.Cars = data;
      this.loading = false;
      console.log(data);
    }, error => {
      this.loading = false;
      console.log(error);
    }
    );
  }

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
  }


  isLoggedIn() {
    if (this.loggedInService.checkStatus() != 0) {
      var UserId = this.DealerUserID;
      if (UserId == this.loggedInService.userId) {
        this.LoggedIn = true;
        // console.log(this.LoggedIn);
      }
    }
  }

}
