import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NgxNotificationService } from 'ngx-notification';
import * as $ from 'jquery';
import * as Quill from 'quill';
import { config } from '../../_helpers/global';

@Component({
  selector: 'app-add-blogs',
  templateUrl: './add-blogs.component.html',
  styleUrls: ['./add-blogs.component.css']
})

export class AddBlogsComponent implements OnInit {
  fileData: File = null;
  loading = false;
  editmode = false;
  AddBlogForm: FormGroup;
  BlogEditor: any;
  EditBlogEditor: any;
  Blogs: any;

  constructor(private ngxNotificationService: NgxNotificationService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    var self = this; this.BlogEditor = new Quill("#editor-container", {
      modules: {
        toolbar: [
          // [{ header: [1, 2, false] }],
          // ["bold", "italic", "underline"],
          // [{ 'list' : 'ordered' }, { 'list' : 'bullet' }],
          // 
          ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
          ['blockquote', 'code-block'],
          ['link'],

          [{ 'header': 1 }, { 'header': 2 }],               // custom button values
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
          [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
          [{ 'direction': 'rtl' }],                         // text direction

          [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'color': ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"] }, { 'background': ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"] }],          // dropdown with defaults from theme
          [{ 'font': [] }],
          [{ 'align': [] }],

          ["image", "code-block"],

          ['clean']
        ]
      },
      placeholder: "Your epic story goes here...",
      theme: "snow" // or 'bubble'
    });

    this.AddBlogForm = this.formBuilder.group({
      title: ['', Validators.required],
      body: ['', Validators.required],
      author: ['', Validators.required],
      excerpt: ['', Validators.required],
      image: ['', Validators.required],
      tags: ['', Validators.required]
    });

    this.BlogEditor.on('text-change', function (delta, old, source) {

      if (source === 'user') {
        var inner = self.BlogEditor.root.innerHTML;
        self.AddBlogForm.patchValue({ body: inner });
      }
    });


    $('#AddBlogForm').submit(function (e) {
      e.preventDefault();
      // if (self.AddBlogForm.invalid) {
      //   return;
      // }
      var formData = new FormData(this);
      // formData.forEach((value,key) => {
      //   console.log(key+" "+value)
      // });
      self.loading = true;
      // console.log(formData);
      formData.append('body', self.BlogEditor.root.innerHTML);
      self.loading = true;
      console.log(self.BlogEditor.root.innerHTML);

      $.ajax({
        type: 'POST',
        url: `${config.apiUrl}/add_blog`,
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        success: (data) => {
          self.addBlog(data); //callback
        },
        error: function (data) {
          self.addBlog(data);
        }
      });
    });
  }

  addBlog(data) {
    var self = this;
    if (data == 1) {
      self.ngxNotificationService.sendMessage('Blog successfully posted!', 'success', 'bottom-left');
      // console.log(data);
      self.AddBlogForm.reset();
      self.loading = false;
    } else {
      console.log(data);
      self.ngxNotificationService.sendMessage('Something went wrong!', 'warning', 'bottom-left');
      self.loading = false;
    }
  }

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    // this.AddBlogForm.patchValue({
    //   fileSource : this.fileData
    // });
  }
}
