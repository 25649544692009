<!--headers-->
<app-headers></app-headers>
<!--Headers End-->
<div class="search-background">
    <div class="search-car">
        <div class="search-form">
            <h1 class="w3-center w3-margin-bottom w3-text-white find-title">Find Your Next Car</h1>


            <div class="form-grouping w3-hover-shadow">

                <div class="w3-bar w3-round">
                    <button class="w3-bar-item w3-btn tablink active-color"
                        onclick="openCity(event,'used')">Used</button>
                    <button class="w3-bar-item w3-btn tablink" onclick="openCity(event,'new')">New</button>
                    <!-- <button class="w3-bar-item w3-btn tablink" onclick="openCity(event,'import')">Import</button> -->
                </div>

                <form id="used" [formGroup]="UsedCarsForm" class="city">
                    <div class="input-group">
                        <div class="w3-row-padding">
                            <div class="w3-col l12 m12 s12">
                                <label for="brand">Brand</label>
                                <select (change)="getSpecificModels($event)" id="search_brand" name="brand"
                                    formControlName="brand" class=" select-field w3-round">
                                    <option value="" disabled selected>Make</option>
                                    <option class="text-black" *ngFor="let brand of brands" value="{{brand['brand']}}">
                                        {{brand['brand']}}</option>
                                </select>
                            </div>

                            <div class="w3-col l12 m12 s12">
                                <label for="model">Model</label>
                                <select id="used-model" formControlName="model" name="model"
                                    class="w3-select select-field w3-round">
                                    <option value="" selected disabled>
                                        <p *ngIf="!modelsloading &&!brandselected">Model </p>
                                        <p *ngIf="brandselected">Models </p>
                                        <div *ngIf="modelsloading">Loading...
                                        </div>
                                    </option>
                                    <option *ngFor="let model of models" value="{{model['id']}}">{{model['model']}}
                                    </option>
                                </select>
                            </div>


                            <!-- <div class="w3-col l12 m12 s12">
                        <label for="Mileage">Mileage (Km)</label>
                        <input type="number" formControlName="mileage" name="mileage" class="w3-input select-field w3-round" id="mileage" placeholder="Mileage (Km)">
                    </div> -->



                            <div class="w3-col l12 m12 s12 w3-row">
                                <div class="w3-col l6 m6 s6">
                                    <label for="year_from">Year From</label>
                                    <select name="year_from" formControlName="year_from" id="year_from"
                                        class="w3-select select-field w3-round">
                                        <option value="" disabled selected>Year: From</option>
                                        <option value="2021">2021</option>
                                        <option value="2020">2020</option>
                                        <option value="2019">2019</option>
                                        <option value="2018">2018</option>
                                        <option value="2017">2017</option>
                                        <option value="2016">2016</option>
                                        <option value="2015">2015</option>
                                        <option value="2014">2014</option>
                                        <option value="2013">2013</option>
                                        <option value="2012">2012</option>
                                        <option value="2011">2011</option>
                                        <option value="2010">2010</option>
                                        <option value="2009">2009</option>
                                        <option value="2008">2008</option>
                                        <option value="2007">2007</option>
                                        <option value="2006">2006</option>
                                        <option value="2005">2005</option>
                                        <option value="2004">2004</option>
                                        <option value="2003">2003</option>
                                        <option value="2002">2002</option>
                                        <option value="2001">2001</option>
                                        <option value="2000">2000</option>
                                        <option value="1999">1999</option>
                                        <option value="1998">1998</option>
                                        <option value="1997">1997</option>
                                        <option value="1996">1996</option>
                                        <option value="1995">1995</option>
                                        <option value="1994">1994</option>
                                        <option value="1993">1993</option>
                                        <option value="1992">1992</option>
                                        <option value="1991">1991</option>
                                        <option value="1990">1990</option>
                                        <option value="1989">1989</option>
                                        <option value="1988">1988</option>
                                        <option value="1987">1987</option>
                                        <option value="1986">1986</option>
                                        <option value="1985">1985</option>
                                        <option value="1984">1984</option>
                                        <option value="1983">1983</option>
                                        <option value="1982">1982</option>
                                        <option value="1981">1981</option>
                                        <option value="1980">1980</option>
                                        <option value="1979">1979</option>
                                        <option value="1978">1978</option>
                                        <option value="1977">1977</option>
                                        <option value="1976">1976</option>
                                        <option value="1975">1975</option>
                                        <option value="1974">1974</option>
                                        <option value="1973">1973</option>
                                        <option value="1972">1972</option>
                                        <option value="1971">1971</option>
                                        <option value="1970">1970</option>
                                        <option value="1969">1969</option>
                                        <option value="1968">1968</option>
                                        <option value="1967">1967</option>
                                        <option value="1966">1966</option>
                                        <option value="1965">1965</option>
                                        <option value="1964">1964</option>
                                        <option value="1963">1963</option>
                                        <option value="1962">1962</option>
                                        <option value="1961">1961</option>
                                        <option value="1960">1960</option>
                                        <option value="1959">1959</option>
                                        <option value="1958">1958</option>
                                        <option value="1957">1957</option>
                                        <option value="1956">1956</option>
                                        <option value="1955">1955</option>
                                        <option value="1954">1954</option>
                                        <option value="1953">1953</option>
                                        <option value="1952">1952</option>
                                        <option value="1951">1951</option>
                                        <option value="1950">1950</option>
                                    </select>
                                </div>

                                <div class="w3-col l6 m6 s6">
                                    <label for="year_to">year to</label>
                                    <select id="year_to" name="year_to" formControlName="year_to"
                                        class="w3-select select-field w3-round">
                                        <option value="" disabled selected>Year: To</option>
                                        <option value="2021">2021</option>
                                        <option value="2020">2020</option>
                                        <option value="2019">2019</option>
                                        <option value="2018">2018</option>
                                        <option value="2017">2017</option>
                                        <option value="2016">2016</option>
                                        <option value="2015">2015</option>
                                        <option value="2014">2014</option>
                                        <option value="2013">2013</option>
                                        <option value="2012">2012</option>
                                        <option value="2011">2011</option>
                                        <option value="2010">2010</option>
                                        <option value="2009">2009</option>
                                        <option value="2008">2008</option>
                                        <option value="2007">2007</option>
                                        <option value="2006">2006</option>
                                        <option value="2005">2005</option>
                                        <option value="2004">2004</option>
                                        <option value="2003">2003</option>
                                        <option value="2002">2002</option>
                                        <option value="2001">2001</option>
                                        <option value="2000">2000</option>
                                        <option value="1999">1999</option>
                                        <option value="1998">1998</option>
                                        <option value="1997">1997</option>
                                        <option value="1996">1996</option>
                                        <option value="1995">1995</option>
                                        <option value="1994">1994</option>
                                        <option value="1993">1993</option>
                                        <option value="1992">1992</option>
                                        <option value="1991">1991</option>
                                        <option value="1990">1990</option>
                                        <option value="1989">1989</option>
                                        <option value="1988">1988</option>
                                        <option value="1987">1987</option>
                                        <option value="1986">1986</option>
                                        <option value="1985">1985</option>
                                        <option value="1984">1984</option>
                                        <option value="1983">1983</option>
                                        <option value="1982">1982</option>
                                        <option value="1981">1981</option>
                                        <option value="1980">1980</option>
                                        <option value="1979">1979</option>
                                        <option value="1978">1978</option>
                                        <option value="1977">1977</option>
                                        <option value="1976">1976</option>
                                        <option value="1975">1975</option>
                                        <option value="1974">1974</option>
                                        <option value="1973">1973</option>
                                        <option value="1972">1972</option>
                                        <option value="1971">1971</option>
                                        <option value="1970">1970</option>
                                        <option value="1969">1969</option>
                                        <option value="1968">1968</option>
                                        <option value="1967">1967</option>
                                        <option value="1966">1966</option>
                                        <option value="1965">1965</option>
                                        <option value="1964">1964</option>
                                        <option value="1963">1963</option>
                                        <option value="1962">1962</option>
                                        <option value="1961">1961</option>
                                        <option value="1960">1960</option>
                                        <option value="1959">1959</option>
                                        <option value="1958">1958</option>
                                        <option value="1957">1957</option>
                                        <option value="1956">1956</option>
                                        <option value="1955">1955</option>
                                        <option value="1954">1954</option>
                                        <option value="1953">1953</option>
                                        <option value="1952">1952</option>
                                        <option value="1951">1951</option>
                                        <option value="1950">1950</option>
                                    </select>
                                </div>
                            </div>

                            <div class=" w3-col l12 m12 s12 w3-row">
                                <div class="w3-col l6 m6 s6">
                                    <label for="min_price">Min. Price</label>
                                    <select name="min_price" formControlName="min_price" id="min_price"
                                        class="w3-select select-field w3-round">
                                        <option value="" disabled selected>Min. Price</option>
                                        <option value="100000">100,000</option>
                                        <option value="500000">500,000</option>
                                        <option value="700000">700,000</option>
                                        <option value="1000000">1,000,000</option>
                                        <option value="2000000">2,000,000</option>
                                        <option value="3000000">3,000,000</option>
                                        <option value="4000000">4,000,000</option>
                                        <option value="5000000">5,000,000</option>
                                        <option value="6000000">6,000,000</option>
                                        <option value="7000000">7,000,000</option>
                                        <option value="8000000">8,000,000</option>
                                        <option value="9000000">9,000,000</option>
                                        <option value="10000000">10,000,000</option>
                                        <option value="12000000">12,000,000</option>
                                        <option value="14000000">14,000,000</option>
                                        <option value="16000000">16,000,000</option>
                                        <option value="18000000">18,000,000</option>
                                        <option value="20000000">20,000,000</option>
                                        <option value="22000000">22,000,000</option>
                                        <option value="24000000">24,000,000</option>
                                        <option value="26000000">26,000,000</option>
                                        <option value="28000000">28,000,000</option>
                                        <option value="30000000">30,000,000</option>
                                    </select>
                                </div>

                                <div class="w3-col l6 m6 s6">
                                    <label for="max_price">Max. Price</label>
                                    <select formControlName="max_price" name="max_price" id="max_price"
                                        class="w3-select select-field w3-round">
                                        <option value="" disabled selected>Max. Price</option>
                                        <option value="100000">100,000</option>
                                        <option value="500000">500,000</option>
                                        <option value="700000">700,000</option>
                                        <option value="1000000">1,000,000</option>
                                        <option value="2000000">2,000,000</option>
                                        <option value="3000000">3,000,000</option>
                                        <option value="4000000">4,000,000</option>
                                        <option value="5000000">5,000,000</option>
                                        <option value="6000000">6,000,000</option>
                                        <option value="7000000">7,000,000</option>
                                        <option value="8000000">8,000,000</option>
                                        <option value="9000000">9,000,000</option>
                                        <option value="10000000">10,000,000</option>
                                        <option value="12000000">12,000,000</option>
                                        <option value="14000000">14,000,000</option>
                                        <option value="16000000">16,000,000</option>
                                        <option value="18000000">18,000,000</option>
                                        <option value="20000000">20,000,000</option>
                                        <option value="22000000">22,000,000</option>
                                        <option value="24000000">24,000,000</option>
                                        <option value="26000000">26,000,000</option>
                                        <option value="28000000">28,000,000</option>
                                        <option value="30000000">30,000,000</option>
                                    </select>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div class="search-cars-div w3-margin-top">
                        <input type="submit" id="search-used-cars" value="Search Now" class="w3-input w3-round"
                            (click)="searchUsed()">
                    </div>
                </form>

                <form id="new" class="city" [formGroup]="NewCarsForm" style="display: none;">
                    <div class="w3-row-padding">
                        <div class="w3-col l12 m12 s12">
                            <label for="brand">Make</label>
                            <select (change)="getSpecificModels($event)" name="brand" formControlName="brand"
                                id="new_brand" class=" select-field w3-round">
                                <option value="" disabled selected>Make</option>
                                <option class="text-black" *ngFor="let brand of brands" value="{{brand['brand']}}">
                                    {{brand['brand']}}</option>
                            </select>
                        </div>

                        <div class="w3-col l12 m12 s12">
                            <label for="model">Model</label>
                            <select id="new-model" formControlName="model" name="model"
                                class="w3-select select-field w3-round">
                                <option value="" selected disabled>
                                    <p *ngIf="!modelsloading &&!brandselected">Please Select Make</p>
                                    <p *ngIf="brandselected">Models </p>
                                    <div *ngIf="modelsloading">Loading...
                                    </div>
                                </option>
                                <option *ngFor="let model of models" value="{{model['id']}}">{{model['model']}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="search-cars-div w3-margin-top">
                        <input type="submit" id="search-new-cars" value="Search Now" class="w3-input w3-round"
                            (click)="searchNew()">
                    </div>
                </form>


                <!-- <form id="import" class="city" style="display:none;"> 
                <div class="input-group">
                    <div class="w3-row-padding">
                    <div class="w3-col l12 m12 s12">
                        <label for="make">Make</label>
                        <select id="make" class=" select-field w3-round">
                            <option value="" disabled selected>Make</option>
                            <option value="Mercedes">Mercedes</option>
                            <option value="BMW">BMW</option>
                            <option value="Audi">Audi</option>
                            <option value="Opel">Opel</option>
                        </select>
                    </div>

                    <div class="w3-col l12 m12 s12">
                        <label for="model">Model</label>
                        <select id="model" class="w3-select select-field w3-round"> 
                            <option value="" disabled selected>Model</option>
                            <option value="Polo">Polo</option>
                            <option value="Quantum">Atego</option>
                            <option value="B-Class">B-Class</option>
                            <option value="axor">Axor</option>
                        </select>
                    </div> 


                    <div class="w3-col l12 m12 s12">
                        <label for="Mileage">Mileage (Km)</label>
                        <input type="number"   class="w3-input select-field w3-round" id="mileage" placeholder="Mileage (Km)">
                    </div>

              

                        <div class="w3-col l12 m12 s12 w3-row">
                            <div class="w3-col l6 m6 s6">
                                <label for="year_from">Year From</label>
                                <select id="year_from" class="w3-select select-field w3-round">
                                    <option value="" disabled selected>Year: From</option>
                                    <option value="2020">2020</option>
                                    <option value="2019">2019</option>
                                    <option value="2018">2018</option>
                                    <option value="2017">2017</option>
                                    <option value="2016">2016</option>
                                    <option value="2015">2015</option>
                                    <option value="2014">2014</option>
                                </select>
                            </div>
        
                            <div class="w3-col l6 m6 s6">
                                <label for="year_to">year to</label>
                                <select id="year_to" class="w3-select select-field w3-round">
                                    <option value="" disabled selected>Year: To</option>
                                    <option value="2020">2020</option>
                                    <option value="2019">2019</option>
                                    <option value="2018">2018</option>
                                    <option value="2017">2017</option>
                                    <option value="2016">2016</option>
                                    <option value="2015">2015</option>
                                    <option value="2014">2014</option>
                                </select>
                            </div>
                            </div>

                            <div class=" w3-col l12 m12 s12 w3-row">   
                                <div class="w3-col l6 m6 s6">
                                    <label for="min_price">Min. Price</label>
                                    <select id="min_price" class="w3-select select-field w3-round">
                                        <option value="" disabled selected>Min. Price</option>
                                        <option value="100000">100,000</option>
                                        <option value="500000">500,000</option>
                                        <option value="700000">700,000</option>
                                        <option value="1000000">1,000,000</option>
                                        <option value="2000000">2,000,000</option>
                                        <option value="3000000">3,000,000</option>
                                        <option value="4000000">4,000,000</option>
                                        <option value="5000000">5,000,000</option>
                                        <option value="6000000">6,000,000</option>
                                        <option value="7000000">7,000,000</option>
                                        <option value="8000000">8,000,000</option>
                                        <option value="9000000">9,000,000</option>
                                        <option value="10000000">10,000,000</option>
                                    </select>
                                </div>
            
                                    <div class="w3-col l6 m6 s6">
                                        <label for="max_price">Max. Price</label>
                                        <select id="max_price" class="w3-select select-field w3-round">
                                            <option value="" disabled selected>Max. Price</option>
                                            <option value="100000">100,000</option>
                                            <option value="500000">500,000</option>
                                            <option value="700000">700,000</option>
                                            <option value="1000000">1,000,000</option>
                                            <option value="2000000">2,000,000</option>
                                            <option value="3000000">3,000,000</option>
                                            <option value="4000000">4,000,000</option>
                                            <option value="5000000">5,000,000</option>
                                            <option value="6000000">6,000,000</option>
                                            <option value="7000000">7,000,000</option>
                                            <option value="8000000">8,000,000</option>
                                            <option value="9000000">9,000,000</option>
                                            <option value="10000000">10,000,000</option>
                                        </select>
                                    </div>
                                </div>

                </div>
            
                </div>
                <div class="search-cars-div w3-margin-top">
                    <input type="submit" id="search-cars" value="Search Now" class="w3-input w3-round">
                </div>    
            </form> -->
            </div>
        </div>
    </div>
</div>

<div class="new-arrivals">
    <div class="w3-row-padding new-arrivals-inner mt-5">
        <div class="w3-center mb-5">
            <h1>New Arrivals</h1>
        </div>
        <div *ngFor="let car of cars; let i = index" class="w3-col l3 m6 s12 car__card single-new-arrival">
            <a routerLink="/car/{{car['year'] }} {{car['brand']}} {{car['model']}}/{{car['id']}}">
                <div class="w3-card w3-margin-bottom">
                    <div class="img-hover-zoom arrival-image border-bottom">
                        <img src="{{url}}{{car['front_image']}}"
                            alt="{{car['year'] }} {{car['brand']}} {{car['model']}}/{{car['id']}}" class="">
                    </div>
                    <div class="car-name-wrapper">
                        <h5 class="car-title"> {{car['year']}} {{car['brand']}} {{car['model']}}</h5>
                    </div>

                    <div class="car-details-bottom w3-row-padding border-top pt-2">
                        <div class="w3-col l5 m5 s5">
                            <p class="model w3-text-gray"> {{car['use_condition']}}</p>
                            <div>
                                <p class="w3-text-gray pl-0.5"><i class="fa fa-map-marker"></i>
                                    {{car['location']}}</p>
                            </div>
                        </div>
                        <div class="w3-col border-left l7 m7 s7">
                            <p *ngIf="car['mileage'] > 0" class="w3-text-gray"> {{car['mileage'] |
                                number:'1.0':'en-US'}} Km</p>
                            <p *ngIf="car['mileage'] == 0" class="w3-text-gray"> 0 Km</p>
                            <p *ngIf="!car['mileage']" class="w3-text-gray"></p>
                            <p class="price  w3-text-gray ">Kshs {{car['price'] | number:'1.0':'en-US'}} </p>
                        </div>
                    </div>
                </div>
            </a>
        </div>
        <div class="lds-roller loader" *ngIf="loading">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>

    <div class="w3-row-padding w3-center pb-3 mt-4">
        <a [routerLink]="['/search']">
            <button class="w3-button">
                View More
            </button>
        </a>
    </div>
</div>

<div class="research-makes">
    <div class="w3-center pt-2">
        <h1>Research Car Makes</h1>
    </div>
    <div class="w3-row pt-3">
        <div *ngFor="let brand of displaybrands" class="w3-col l2 m4 s12 single-brand">
            <a [routerLink]="['/search']" [queryParams]="{ brand: brand['brand'] }">
                <div class="w3-panel">
                    <div class="text-center make-logo-wrapper">
                        <img class="make-logo w3-image" alt="{{brand['brand']}}" src="{{url}}{{brand['logo']}}">
                    </div>
                    <div class="text-center model-name">
                        <h3>{{brand['brand']}}</h3>
                    </div>
                </div>
            </a>
        </div>
    </div>
</div>
<div class="research-models">
    <div class="w3-center">
        <h1>View Cars By Body Type</h1>
    </div>
    <div class="w3-row-padding models-wrapper">
        <div class="cursor-pointer w3-col l3 m6 s6 single-model">
            <a [routerLink]="['/search']" [queryParams]="{ body_type: 'Saloons'}">
                <div class="model-img">
                    <img src="assets/images/car-icons/saloon.png"
                        alt="saloons cars for sale in Kenya German Rides Hub | GRH" style="width: 70%;">
                </div>
                <div class="model-name">
                    <h3>Saloons</h3>
                </div>
            </a>
        </div>
        <div class="cursor-pointer w3-col l3 m6 s6  single-model">
            <a [routerLink]="['/search']" [queryParams]="{ body_type: 'Hatchbacks'}">
                <div class="model-img">
                    <img src="assets/images/car-icons/hatchback.png"
                        alt="Hatchbacks cars for sale in Kenya German Rides Hub | GRH" style="width: 70%;">
                </div>
                <div class="model-name">
                    <h3>Hatchbacks</h3>
                </div>
            </a>
        </div>
        <div class="cursor-pointer w3-col l3 m6 s6 single-model">
            <a [routerLink]="['/search']" [queryParams]="{ body_type: 'SUV'}">
                <div class="model-img">
                    <img src="assets/images/car-icons/suv-car.png"
                        alt="SUVs cars for sale in Kenya German Rides Hub | GRH" style="width: 70%;">
                </div>
                <div class="model-name">
                    <h3>SUVs</h3>
                </div>
            </a>
        </div>
        <div class="cursor-pointer w3-col l3 m6 s6  single-model">
            <a [routerLink]="['/search']" [queryParams]="{ body_type: 'Station Wagon'}">
                <div class="model-img">
                    <img src="assets/images/car-icons/wagon.png"
                        alt="Station Wagon cars for sale in Kenya German Rides Hub | GRH" style="width: 70%;">
                </div>
                <div class="model-name">
                    <h3>Station Wagon</h3>
                </div>
            </a>
        </div>
        <div class="cursor-pointer w3-col l3 m6 s6  single-model">
            <a [routerLink]="['/search']" [queryParams]="{ body_type: 'Pickups'}">
                <div class="model-img">
                    <img src="assets/images/car-icons/pickup-truck.png"
                        alt="Pickups for sale in Kenya German Rides Hub | GRH" style="width: 70%;">
                </div>
                <div class="model-name">
                    <h3>Pickups</h3>
                </div>
            </a>
        </div>
        <div class="cursor-pointer w3-col l3 m6 s6  single-model">
            <a [routerLink]="['/search']" [queryParams]="{ body_type: 'Motorbikes'}">
                <div class="model-img">
                    <img src="assets/images/car-icons/motorbike.png"
                        alt="Motorbikes for sale in Kenya German Rides Hub | GRH" style="width: 70%;">
                </div>
                <div class="model-name">
                    <h3>Motorbikes</h3>
                </div>
            </a>
        </div>
        <div class="cursor-pointer w3-col l3 m6 s6  single-model">
            <a [routerLink]="['/search']" [queryParams]="{ body_type: 'Convertibles'}">
                <div class="model-img">
                    <img src="assets/images/car-icons/convertible.png"
                        alt="Convertibles for sale in Kenya German Rides Hub | GRH" style="width: 70%;">
                </div>
                <div class="model-name">
                    <h3>Convertibles</h3>
                </div>
            </a>
        </div>
        <div class="cursor-pointer w3-col l3 m6 s6  single-model">
            <a [routerLink]="['/search']" [queryParams]="{ body_type: 'Buses and Vans'}">
                <div class="model-img">
                    <img src="assets/images/car-icons/bus.png"
                        alt="Buses and Vans for sale in Kenya German Rides Hub | GRH" style="width: 70%;">
                </div>
                <div class="model-name">
                    <h3>Buses and Vans</h3>
                </div>
            </a>
        </div>
        <div class="cursor-pointer w3-col l3 m6 s6  single-model">
            <a [routerLink]="['/search']" [queryParams]="{ body_type: 'Trucks'}">
                <div class="model-img">
                    <img src="assets/images/car-icons/truck.png" alt="Trucks for sale in Kenya German Rides Hub | GRH"
                        style="width: 70%;">
                </div>
                <div class="model-name">
                    <h3>Trucks</h3>
                </div>
            </a>
        </div>
        <div class="cursor-pointer w3-col l3 m6 s6  single-model">
            <a [routerLink]="['/search']" [queryParams]="{ body_type: 'Machinery and Tractors'}">
                <div class="model-img">
                    <img src="assets/images/car-icons/tractor.png"
                        alt="Machinery and Tractors for sale in Kenya German Rides Hub | GRH" style="width: 70%;"
                        style="width: 70%;">
                </div>
                <div class="model-name">
                    <h3>Machinery and Tractors</h3>
                </div>
            </a>
        </div>
        <div class="cursor-pointer w3-col l3 m6 s6 single-model">
            <a [routerLink]="['/search']" [queryParams]="{ body_type: 'Trailers'}">
                <div class="model-img">
                    <img src="assets/images/car-icons/trailer.png"
                        alt="Trailers for sale in Kenya German Rides Hub | GRH" style="width: 70%;">
                </div>
                <div class="model-name">
                    <h3>Trailers</h3>
                </div>
            </a>
        </div>
        <div class="cursor-pointer w3-col l3 m6 s6  single-model">
            <a [routerLink]="['/search']" [queryParams]="{ body_type: 'Minis'}">
                <div class="model-img">
                    <img src="assets/images/car-icons/mini.png" alt="Minis for sale in Kenya German Rides Hub | GRH"
                        style="width: 70%;">
                </div>
                <div class="model-name">
                    <h3>Minis</h3>
                </div>
            </a>
        </div>
        <div class="cursor-pointer w3-col l3 m6 s6  single-model">
            <a [routerLink]="['/search']" [queryParams]="{ body_type: 'Quad Bikes'}">
                <div class="model-img">
                    <img src="assets/images/car-icons/quad.png"
                        alt="Quad Bikes for sale in Kenya German Rides Hub | GRH" style="width: 70%;"
                        style="width: 65%;">
                </div>
                <div class="model-name pt-2">
                    <h3>Quad Bikes</h3>
                </div>
            </a>
        </div>
        <div class="cursor-pointer w3-col l3 m6 s6  single-model">
            <a [routerLink]="['/search']" [queryParams]="{ body_type: 'Coupes'}">
                <div class="model-img">
                    <img src="assets/images/car-icons/coupe-car.png"
                        alt="Coupes for sale in Kenya German Rides Hub | GRH" style="width: 70%;" style="width: 75%;">
                </div>
                <div class="model-name mt-n4">
                    <h3>Coupes</h3>
                </div>
            </a>
        </div>
    </div>
</div>

<div class="car-reviews">
    <div class="w3-center">
        <h1>Blogs</h1>
    </div>
    <div class="w3-row-padding blog-suggestions">
        <div *ngFor="let blog of blogs; let i = index">
            <div class="w3-col mb-4 l4 m6 s12 w3-hover-shadow">
                <div class="">
                    <a routerLink="/blog/{{blog['title']}}/{{blog['id']}}">
                        <div class="w3-container w3-padding">
                            <div class="blog-suggestion-img blog-img-hover-zoom">
                                <img src="{{url}}{{blog['image']}}" alt="{{blog['title']}}" class="blog-image"
                                    alt="image loading">
                            </div>
                            <div class="blog-suggestion-description">
                                <h4 class="tips-title w3-center">{{blog['title'].substr(0,60)+'...'}}</h4>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>

        <div class="lds-roller loader" *ngIf="blogsloading">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
    <div class="w3-row-padding w3-center pb-3">
        <button class="w3-button" routerLink="/blogs">
            View More
        </button>
    </div>
</div>
<br>
<!--footers-->
<app-footers></app-footers>
<!--footers END-->