import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
// import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgxNotificationModule } from 'ngx-notification';
import { SlickCarouselModule } from 'ngx-slick-carousel';

import { AppComponent } from './app.component';
import { HeadersComponent } from './headers/headers.component';
import { FootersComponent } from './footers/footers.component';
import { SingleCarViewComponent } from './single-car-view/single-car-view.component';
import { BlogComponent } from './blog/blog.component';
import { SingleBlogComponent } from './single-blog/single-blog.component';
import { SearchComponent } from './search/search.component';
import { HomepageComponent } from './homepage/homepage.component';
import { AdminPortalComponent } from './admin-portal/admin-portal.component';
import { RegisterFormComponent } from './_forms/register-form/register-form.component';
import { LoginFormComponent } from './_forms/login-form/login-form.component';
import { LoggedInService } from './_services/logged-in.service';
import { ProfileComponent } from './profile/profile.component';
import { EditDealersComponent } from './_forms/edit-dealers/edit-dealers.component';
import { NotificationBellComponent } from './_component/notification-bell/notification-bell.component';
import { AddCarComponent } from './_forms/add-car/add-car.component';
import { CarsService } from './_services/cars.service';
import { CarsFilterComponent } from './_forms/cars-filter/cars-filter.component';
import { AddBlogsComponent } from './_forms/add-blogs/add-blogs.component';
import { EditBlogsComponent } from './_forms/edit-blogs/edit-blogs.component';
import { DealerComponent } from './dealer/dealer.component';
import { DealersComponent } from './dealers/dealers.component';
import { MakesComponent } from './makes/makes.component';
import { LocallyUsedComponent } from './locally-used/locally-used.component';
import { ForgotPasswordComponent } from './_forms/forgot-password/forgot-password.component';
import { ForgotPaswordPageComponent } from './forgot-pasword-page/forgot-pasword-page.component';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';
import { AboutComponent } from './about/about.component';
import { Page404Component } from './page404/page404.component';
import { EditCarComponent } from './_forms/edit-car/edit-car.component';

@NgModule({
  declarations: [
    AppComponent,
    HeadersComponent,
    FootersComponent,
    SingleCarViewComponent,
    HomepageComponent,
    BlogComponent,
    SingleBlogComponent,
    SearchComponent,
    HomepageComponent,
    AdminPortalComponent,
    RegisterFormComponent,
    LoginFormComponent,
    ProfileComponent,
    EditDealersComponent,
    NotificationBellComponent,
    AddCarComponent,
    CarsFilterComponent,
    AddBlogsComponent,
    EditBlogsComponent,
    DealerComponent,
    DealersComponent,
    MakesComponent,
    LocallyUsedComponent,
    ForgotPasswordComponent,
    ForgotPaswordPageComponent,
    PrivacyPolicyComponent,
    TermsOfServiceComponent,
    AboutComponent,
    Page404Component,
    EditCarComponent,
  ],
  imports: [
    HttpClientModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    NgxNotificationModule,
    SlickCarouselModule,
    DeviceDetectorModule.forRoot()
  ],
  providers: [LoggedInService, CarsService],
  bootstrap: [AppComponent]
})
export class AppModule { }
