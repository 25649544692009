import { Injectable } from '@angular/core';
import * as $ from 'jquery';
import { config } from '../_helpers/global';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class BrandsModelsSeriesService {
  brands:any;
  models:any;
  series:any;

  constructor(private http: HttpClient) {
    
  }

  getBrands() {  
    // console.log('called');
    return this.http.get<any>(`${config.apiUrl}/get_brands`);
  }

  getModels() {    
    return this.http.get<any>(`${config.apiUrl}/get_models`);
  }
  
  getSeries() {    
    return this.http.get<any>(`${config.apiUrl}/get_series`);
  }

  getSpecificSeries(id) {    
    return this.http.get<any>(`${config.apiUrl}/get_specific_series/`+id);
  }

  getSpecificModels(id) {    
    return this.http.get<any>(`${config.apiUrl}/get_specific_models/`+id);
  }

}
