<!--headers-->
<app-headers></app-headers>
<!--Headers End-->

<div class="container">
    <h1 *ngIf="!reset_status">
        Sorry that link is expired
    </h1>
    <div *ngIf="reset_status" class="container-inner">
        <form method="POST" [formGroup]="ForgotPassword">
            <div class="form-group">
                <label for="Password">
                    <b>
                        Password
                    </b>
                </label>
                <input id="resetpassword" type="password" name="resetpassword" required formControlName="resetpassword" class="form-control">
                <div class="alert alert-danger" *ngIf="submitted && ForgotPassword.controls['resetpassword'].invalid && (ForgotPassword.controls['resetpassword'].dirty || ForgotPassword.controls['resetpassword'].touched || submitted)">Please provide a password for your account</div>
            </div>
            <div class="form-group">
                <label for="resetpasswordconfirm">
                    <b>
                        Confirm Password
                    </b>
                </label>
                <input id="resetpasswordconfirm" class="form-control" type="password" name="resetpasswordconfirm" required formControlName="resetpasswordconfirm">
                <div class="alert alert-danger" *ngIf="submitted && ForgotPassword.controls['resetpasswordconfirm'].invalid && (ForgotPassword.controls['resetpasswordconfirm'].dirty || ForgotPassword.controls['resetpasswordconfirm'].touched || submitted)">Your passwords do not match</div>
            </div>
            <div>
                <button type="submit" (click)="reset()" id="forgot-submit">
                    <span *ngIf="!loading">Reset</span>
                    <span *ngIf="loading">Loading...</span>
                </button>

                <div *ngIf="reseterror" class="alert alert-danger">
                    Sorry Something went wrong. 
                </div>
                
                <div *ngIf="isreset" class="alert alert-success">
                    <strong>Success!</strong>
                  Your password was reset successfully
                </div>
            </div>
        </form>
    </div>
</div>

<!--footers-->
<app-footers></app-footers>
<!--footers END-->