<!--headers-->
<app-headers></app-headers>
<!--Headers End-->

<div class="single-car-gallery w3-row top-slider">
    <div class="w3-col l4 m4 s12">
        <a data-fancybox="images" id="firstimage" href="{{url}}{{car[0]['right_image']}}">
            <img src="{{url}}{{car[0]['right_image']}}" alt="{{car[0]['year']}} {{car[0]['brand']}} {{car[0]['model']}}"
                title="Click to view more images">
        </a>
        <!-- <img src="{{url}}{{car[0]['right_image']}}" onclick="openModal();currentDiv(1)" title="Click to view more images"> -->
    </div>
    <div class="w3-col l4 m4 s12 w3-hide-small">
        <a data-fancybox="images" href="{{url}}{{car[0]['front_image']}}">
            <img src="{{url}}{{car[0]['front_image']}}" alt="{{car[0]['year']}} {{car[0]['brand']}} {{car[0]['model']}}"
                title="Click to view more images">
        </a>
        <!-- <img class="" src="{{url}}{{car[0]['front_image']}}" onclick="openModal();currentDiv(2)"> -->
    </div>
    <div class="w3-col l4 m4 s12 w3-hide-small">
        <a data-fancybox="images" href="{{url}}{{car[0]['left_image']}}">
            <img src="{{url}}{{car[0]['left_image']}}" alt="{{car[0]['year']}} {{car[0]['brand']}} {{car[0]['model']}}"
                title="Click to view more images">
        </a>
        <!-- <img src="{{url}}{{car[0]['left_image']}}" onclick="openModal();currentDiv(3)"> -->
    </div>
    <div class="w3-col l4 m4 s12 d-none">
        <a data-fancybox="images" href="{{url}}{{car[0]['back_image']}}">
            <img src="{{url}}{{car[0]['back_image']}}" alt="{{car[0]['year']}} {{car[0]['brand']}} {{car[0]['model']}}"
                title="Click to view more images">
        </a>
        <!-- <img src="{{url}}{{car[0]['back_image']}}" onclick="openModal();currentDiv(4)"> -->
    </div>
    <div class="w3-col l4 m4 s12 d-none">
        <a data-fancybox="images" href="{{url}}{{car[0]['interior_image']}}">
            <img src="{{url}}{{car[0]['interior_image']}}"
                alt="{{car[0]['year']}} {{car[0]['brand']}} {{car[0]['model']}}" title="Click to view more images">
        </a>
        <!-- <img src="{{url}}{{car[0]['interior_image']}}" onclick="openModal();currentDiv(5)"> -->
    </div>
    <div class="w3-col l4 m4 s12 d-none">
        <a data-fancybox="images" href="{{url}}{{car[0]['dashboard_image']}}">
            <img src="{{url}}{{car[0]['dashboard_image']}}"
                alt="{{car[0]['year']}} {{car[0]['brand']}} {{car[0]['model']}}" title="Click to view more images">
        </a>
        <!-- <img src="{{url}}{{car[0]['dashboard_image']}}" onclick="openModal();currentDiv(6)"> -->
    </div>
    <div class="w3-col l4 m4 s12 d-none">
        <a *ngIf="car[0]['other_image1']" data-fancybox="images" href="{{url}}{{car[0]['other_image1']}}">
            <img src="{{url}}{{car[0]['other_image1']}}"
                alt="{{car[0]['year']}} {{car[0]['brand']}} {{car[0]['model']}}" title="Click to view more images">
        </a>
        <!-- <img src="{{url}}{{car[0]['dashboard_image']}}" onclick="openModal();currentDiv(6)"> -->
    </div>
    <div class="w3-col l4 m4 s12 d-none">
        <a *ngIf="car[0]['other_image2']" data-fancybox="images" href="{{url}}{{car[0]['other_image2']}}">
            <img src="{{url}}{{car[0]['other_image2']}}"
                alt="{{car[0]['year']}} {{car[0]['brand']}} {{car[0]['model']}}" title="Click to view more images">
        </a>
        <!-- <img src="{{url}}{{car[0]['dashboard_image']}}" onclick="openModal();currentDiv(6)"> -->
    </div>
    <div class="w3-col l4 m4 s12 d-none">
        <a *ngIf="car[0]['other_image3']" data-fancybox="images" href="{{url}}{{car[0]['other_image3']}}">
            <img src="{{url}}{{car[0]['other_image3']}}"
                alt="{{car[0]['year']}} {{car[0]['brand']}} {{car[0]['model']}}" title="Click to view more images">
        </a>
        <!-- <img src="{{url}}{{car[0]['dashboard_image']}}" onclick="openModal();currentDiv(6)"> -->
    </div>
    <div class="w3-col l4 m4 s12 d-none">
        <a *ngIf="car[0]['other_image4']" data-fancybox="images" href="{{url}}{{car[0]['other_image4']}}">
            <img src="{{url}}{{car[0]['other_image4']}}"
                alt="{{car[0]['year']}} {{car[0]['brand']}} {{car[0]['model']}}" title="Click to view more images">
        </a>
        <!-- <img src="{{url}}{{car[0]['dashboard_image']}}" onclick="openModal();currentDiv(6)"> -->
    </div>
    <div class="w3-col l4 m4 s12 d-none">
        <a *ngIf="car[0]['other_image5']" data-fancybox="images" href="{{url}}{{car[0]['other_image5']}}}}">
            <img src="{{url}}{{car[0]['other_image5']}}"
                alt="{{car[0]['year']}} {{car[0]['brand']}} {{car[0]['model']}}" title="Click to view more images">
        </a>
        <!-- <img src="{{url}}{{car[0]['dashboard_image']}}" onclick="openModal();currentDiv(6)"> -->
    </div>
    <div class="w3-col l4 m4 s12 d-none">
        <a *ngIf="car[0]['other_image6']" data-fancybox="images" href="{{url}}{{car[0]['other_image6']}}">
            <img src="{{url}}{{car[0]['other_image6']}}"
                alt="{{car[0]['year']}} {{car[0]['brand']}} {{car[0]['model']}}" title="Click to view more images">
        </a>
        <!-- <img src="{{url}}{{car[0]['dashboard_image']}}" onclick="openModal();currentDiv(6)"> -->
    </div>

    <!---
    <div class="w3-col l4 m4 s12 d-none">  
        <img src="{{url}}{{car[0]['right_image']}}">
    </div>
    <div class="w3-col l4 m4 s12 d-none">  
        <img src="{{url}}{{car[0]['right_image']}}">
    </div>
    <div class="w3-col l4 m4 s12 d-none">  
        <img src="{{url}}{{car[0]['right_image']}}">
    </div>
    <div class="w3-col l4 m4 s12 d-none">  
        <img src="{{url}}{{car[0]['right_image']}}">
    </div>
    <div class="w3-col l4 m4 s12 d-none">  
        <img src="{{url}}{{car[0]['right_image']}}">
    </div>
    <div class="w3-col l4 m4 s12 d-none">  
        <img src="{{url}}{{car[0]['right_image']}}">
    </div> -->
</div>

<div class="more-btn">
    <a class="w3-btn w3-blue w3-round" (click)="openFancybox()"> <i class="fa fa-angle-double-down "></i> More Images <i
            class="fa fa-angle-double-down"></i> </a>
</div>

<div class=" w3-row w3-border-top w3-border-grey">
    <div class="w3-col m12 s12 l12 w3-border-bottom">
        <div class="w3-row car-intro border-bottom">
            <div class="car-name w3-col l6 m6 s12">
                <h1>{{car[0]['year']}} {{car[0]['brand']}} {{car[0]['model']}}</h1>
                <p>
                    {{car[0]['series']}}
                    <span style="border-left:3px solid black;padding-left: 0.3em;" *ngIf="car[0]['mileage']">Mileage:
                        {{car[0]['mileage'] | number:'1.0':'en-US'}} Kms</span>
                    <span style="border-left:3px solid black;padding-left: 0.3em;" *ngIf="!car[0]['mileage']">Mileage:
                        Not Provided</span>
                </p>
            </div>
            <div class="price-buy w3-col l6 m6 s12">
                <div class="price w3-right">
                    <p>
                        <span>Ksh. {{car[0]['price'] | number:'1.0':'en-US'}}</span>
                    </p>
                    <p>
                        <span>Negotiable</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="car-details">
            <div class="w3-row">
                <div class="w3-col m6 pt-3 l6 s12">
                    <p class="capitalize pb-4"><b class="specs-half">Condition:</b><span
                            class="specs-half">{{car[0]['use_condition']}}</span> </p>
                    <p class="capitalize pb-4"><b class="specs-half">Body Type:</b> <span
                            class="specs-half">{{car[0]['body_type']}}</span> </p>
                    <p class="capitalize pb-4"><b class="specs-half">Interior:</b> <span
                            class="specs-half">{{car[0]['interior']}}</span></p>
                    <p class="capitalize pb-3"><b class="specs-half"> color: </b><span class="specs-half">
                            {{car[0]['color']}}</span></p>
                </div>
                <div class="w3-col second-details m6 l6 s12">
                    <p class="capitalize pb-4"><b class="specs-half">Duty:</b> <span
                            class="specs-half">{{car[0]['duty']}}</span></p>
                    <p class="capitalize pb-4"><b class="specs-half">Engine:</b> <span
                            class="specs-half">{{car[0]['engine_size']}} CC</span></p>
                    <p class="capitalize pb-4"><b class="specs-half">Transmission:</b> <span
                            class="specs-half">{{car[0]['transmission']}}</span></p>
                    <p class="capitalize pb-3"><b class="specs-half">Fuel Type:</b> <span
                            class="specs-half">{{car[0]['fuel']}}</span></p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="features w3-border-bottom">
    <h1>Features</h1>
    <div class="w3-row features-inner">
        <div class="w3-col m6 s12 l3"
            *ngIf="car[0]['ac_front'] || car[0]['ac_rear'] || car[0]['cruise_control'] || car[0]['navigation'] || car[0]['Keyless Entry'] || car[0]['intergrated_phone']">
            <h3>Comfort</h3>
            <p class="capitalize" *ngIf="car[0]['ac_front']">Air Conditioning front</p>
            <p class="capitalize" *ngIf="car[0]['ac_rear']">Air Conditioning rear</p>
            <p class="capitalize" *ngIf="car[0]['cruise_control']">Cruise Control</p>
            <p class="capitalize" *ngIf="car[0]['navigation']">Navigation</p>
            <p class="capitalize" *ngIf="car[0]['Keyless Entry']">Keyless Entry</p>
            <p class="capitalize" *ngIf="car[0]['intergrated_phone']">Intergrated Phone</p>
        </div>
        <div class="w3-col m6 s12 l3"
            *ngIf="car[0]['leather_interior'] || car[0]['bucket_seats'] || car[0]['power_seats'] || car[0]['memory_seats']">
            <h3>Seats</h3>
            <p class="capitalize" *ngIf="car[0]['leather_interior']">Leather Interior</p>
            <p class="capitalize" *ngIf="car[0]['bucket_seats']">Leather Interior</p>
            <p class="capitalize" *ngIf="car[0]['power_seats']">Leather Interior</p>
            <p class="capitalize" *ngIf="car[0]['memory_seats']">Memory Seats</p>
        </div>
        <div class="w3-col m6 s12 l3"
            *ngIf="car[0]['airbag_driver'] || car[0]['airbag_passenger'] || car[0]['airbag_side'] || car[0]['alarm'] || car[0]['antilock_brakes'] || car[0]['fog_lights']">
            <h3>Safety</h3>
            <p class="capitalize" *ngIf="car[0]['airbag_driver']">Airbag Driver</p>
            <p class="capitalize" *ngIf="car[0]['airbag_passenger']">Airbag Passenger</p>
            <p class="capitalize" *ngIf="car[0]['airbag_side']">Airbag Side</p>
            <p class="capitalize" *ngIf="car[0]['alarm']">alarm</p>
            <p class="capitalize" *ngIf="car[0]['antilock_brakes']">Antilock Brakes</p>
            <p class="capitalize" *ngIf="car[0]['fog_lights']">Fog Lights</p>
        </div>
        <div class="w3-col m6 s12 l3"
            *ngIf="car[0]['power_windows'] || car[0]['power_windows'] || car[0]['rear_window_defroster'] || car[0]['rear_window_wiper'] || car[0]['tinted_glass'] || car[0]['power_windows']">
            <h3>Windows</h3>
            <p class="capitalize" *ngIf="car[0]['power_windows']">Power Windows</p>
            <p class="capitalize" *ngIf="car[0]['rear_window_defroster']">Rear Window Defroster</p>
            <p class="capitalize" *ngIf="car[0]['rear_window_wiper']">Rear Window Wiper</p>
            <p class="capitalize" *ngIf="car[0]['tinted_glass']">Tinted Glass</p>
            <p class="capitalize" *ngIf="car[0]['power_windows']">Power Windows</p>
        </div>
        <div class="w3-col m6 s12 l3"
            *ngIf="car[0]['am_fm_stereo'] || car[0]['cd_player'] || car[0]['cassette_player']">
            <h3>Entertainment</h3>
            <p class="capitalize" *ngIf="car[0]['am_fm_stereo']">Am Fm Stereo</p>
            <p class="capitalize" *ngIf="car[0]['cd_player']">Cd Player</p>
            <p class="capitalize" *ngIf="car[0]['cassette_player']">Cassette Player</p>
        </div>
        <div class="w3-col m6 s12 l3" *ngIf="car[0]['alloy_wheels'] || car[0]['sunroof'] || car[0]['third_row_seats']">
            <h3>Other</h3>
            <p class="capitalize" *ngIf="car[0]['alloy_wheels']">Alloy Wheels</p>
            <p class="capitalize" *ngIf="car[0]['sunroof']">Sunroof</p>
            <p class="capitalize" *ngIf="car[0]['third_row_seats']">Third Row Seats</p>
        </div>
    </div>

    <div class="w3-row features-description" *ngIf="car[0]['description']">
        <h3>Description</h3>
        <p class="w3-block">
            {{car[0]['description']}}
        </p>
    </div>
</div>

<div class="w3-section pt-2 pl-3 pr-3 w3-border-bottom">
    <div class="w3-center">
        <h3>Contact the seller</h3>
    </div>
    <!-- <div *ngIf="!LoggedIn" class="w3-center w3-margin-bottom buy price">
        <p>To view the dealer's details you have to create an account. You only do this once.</p>
        <a onclick="document.getElementById('id02').style.display='block'" class="w3-btn  w3-blue  w3-round">Register</a>
    </div> -->
    <div class="w3-center">
        <h4 class="w3-text-blue">{{Dealer[0]['name']}}</h4>
        <div class="">
            <p>
                For an enquiry contact the dealer through this contacts.
            </p>
            <div class="w3-row-padding">
                <div class="w3-col l4 m4 s12 w3-margin-bottom">
                    <a class="contact-text contacct_button" href="tel://{Dealer[0]['phone_number']}">
                        <div class="contact-dealer">
                            <i class="fa w3-text-blue fa-phone" aria-hidden="true"></i> <br>
                            {{Dealer[0]['phone_number']}}
                        </div>
                    </a>
                    <!-- <button (click)="phoneRedirect()" class="contact-text">
                        <div class="contact-dealer">
                            <i class="fa w3-text-blue fa-phone" aria-hidden="true"></i> <br>
                            {{Dealer[0]['phone_number']}}
                        </div>
                    </button> -->
                </div>

                <div class="w3-col l4 m4 s12 w3-margin-bottom">
                    <a href="javascript:void(0);" (click)="whatsappRedirect()"
                        class="whatsapp__button w3-block contact-text">
                        <div class="contact-dealer pt-3">
                            <i class="fa fa-whatsapp w3-text-green  w3-block"></i> WhatsApp
                        </div>
                    </a>
                    <!-- <button (click)="whatsappRedirect()" class="whatsapp__button w3-block contact-text">
                        <div class="contact-dealer pt-2 w3-text-white">
                            <i class="fa fa-whatsapp w3-text-green w3-block"> </i>WhatsApp
                        </div>
                    </button> -->
                </div>
                <div class="w3-col l4 m4 s12 w3-margin-bottom">
                    <a class="contact-text emaill_button w3-large" href="mailto:{Dealer[0]['email']}">
                        <div class="contact-dealer">
                            <i class="fa w3-text-blue fa-envelope" aria-hidden="true"></i>
                            <br> {{Dealer[0]['email']}}
                        </div>
                    </a>
                    <!-- <button (click)="emailRedirect()" class="contact-text w3-large" target="_blank">
                        <div class="contact-dealer ">
                            <i class="fa w3-text-blue fa-envelope" aria-hidden="true"></i>
                            <br> {{Dealer[0]['email']}}
                        </div>
                    </button> -->
                </div>
            </div>
        </div>

    </div>
</div>


<div class="similar">
    <div class="w3-row-padding similar-inner">
        <h1 class="w3-center w3-margin-bottom">
            Similar Vehicles
        </h1>
        <div class="w3-row-padding new-arrivals-inner">
            <div *ngFor="let car of RecommendedCars; let i = index"
                class="w3-col l3 m6 s12 car__card single-new-arrival">
                <a href="/car/{{car['year'] }} {{car['brand']}} {{car['model']}}/{{car['id']}}">
                    <div class=" w3-card w3-margin-bottom">
                        <div class="img-hover-zoom arrival-image">
                            <img src="{{url}}{{car['front_image']}}"
                                alt="{{car['year'] }} {{car['brand']}} {{car['model']}}" class="">
                        </div>
                        <div class="car-name-wrapper">
                            <h5 class="car-title w3-margin-left"> {{car['year']}} {{car['brand']}} {{car['model']}}</h5>
                        </div>
                        <div class="car-details-bottom w3-row-padding border-top pt-2">
                            <div class="w3-col l5 m5 s5">
                                <p class="model w3-text-gray"> {{car['use_condition']}}</p>
                                <div>
                                    <p class="w3-text-gray pl-0.5"><i class="fa fa-map-marker"></i>
                                        {{car['location']}}</p>
                                </div>
                            </div>
                            <div class="w3-col border-left l7 m7 s7">
                                <p class="w3-text-gray" *ngIf="car['mileage']"> {{car['mileage'] |
                                    number:'1.0':'en-US'}} Km</p>
                                <p class="w3-text-gray" *ngIf="!car['mileage']"> </p>
                                <p class="price  w3-text-gray ">Kshs {{car['price'] | number:'1.0':'en-US'}} </p>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
        <div class="w3-row-padding w3-center pb-3">
            <a [routerLink]="['/search']" [queryParams]="{ brand: BrandName }">
                <button *ngIf="!Loading" class="w3-button">
                    View More
                </button>
            </a>
        </div>
    </div>
</div>

<div class="w3-container">


</div>
<app-footers></app-footers>