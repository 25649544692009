<!--headers-->
<app-headers></app-headers>
<!--Headers End-->

<div class="main-container">
<div class="w3-container delaers-section">
    <h2 class="main-title w3-margin-bottom">Meet The Dealers</h2>
    <div class="w3-row-padding">
        <div *ngFor="let dealer of Dealers" class="single-car w3-col l3 m6 s12" >
            <div routerLink="/dealer/{{dealer['id']}}" class="from-dealers w3-margin-bottom w3-hover-shadow"> 
                <div>
                    <img class="w3-image" *ngIf="!dealer['profile_photo']" src="assets/images/profile.jpg" alt="image loading">
                    <img *ngIf="dealer['profile_photo']"    class="w3-image" src="{{url}}{{dealer['profile_photo']}}" alt="image loading">
                </div>
                <h4 class="car-title w3-center w3-margin-left">{{dealer['name']}}</h4>
                <div class="w3-row-padding">
                    <div class="w3-col l6 m6 s6">
                        <p class="location"><span *ngIf="dealer['location']">{{dealer['location']}}</span><span *ngIf="!dealer['location']">Unknown</span></p>
                    </div>
                    <div class="w3-col l6 m6 s6">
                        <p style="border-left:3px solid black;padding-left: 0.3em;" class="distance"><span class="float-right">{{dealer['posts']}} posts</span></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="lds-roller loader" *ngIf="loading"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>     

        <div class="w3-row-padding w3-center pb-3">
            <!-- <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>      -->
             <!-- <button  class="w3-button w3-border w3-border-green w3-text-green">
                View More
            </button> -->
      
        </div>
    </div>
</div>
</div>
