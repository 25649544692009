// import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { LoggedInService } from '../_services/logged-in.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { DealersService } from '../_services/dealers.service';

@Component({
  selector: 'app-headers',
  templateUrl: './headers.component.html',
  styleUrls: ['./headers.component.css']
})
export class HeadersComponent implements OnInit {
  loggedin = false;  
  loading = false;
  submitted = false;
  admin = false;
  accesslevel : any;
  logOut : any;
  username : any;
  LocallyUsedCars : any;
  dealerid : any;
  dealerstatus : any;
  userid : any;

  constructor(private loggedInservice: LoggedInService, private _Activatedroute:ActivatedRoute, private router: Router, private formBuilder: FormBuilder, private dealersService : DealersService) {
   
   }

  
  ngOnInit() {
    this.loggedInservice.checkStatus;
    this.loggedin = this.loggedInservice.loggedin;
    this.logOut = this.loggedInservice.logOut;
    this.admin = this.loggedInservice.admin;
    this.username = this.loggedInservice.username;
    this.accesslevel = this.loggedInservice.accesslevel;
    this.userid = this.loggedInservice.userId;
    // this.resetPasswordRedirect();
    if (this.loggedInservice.dealer == true || this.loggedInservice.admin == true) {
        this.getDealer(this.userid);
    }

    this.LocallyUsedCars = this.formBuilder.group({
        condition: ['']
    });

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
    });
  }

  resetPasswordRedirect() {
    var CurrentUrl = this._Activatedroute.snapshot['_routerState'].url;
    var base = CurrentUrl.split("/");
    base = base[1];
    if (base == "reset_password") {
      this.router.navigate(['/']);
    }
  }

  toLocallyUsed () {
    this.LocallyUsedCars.patchValue({condition: 'local'});  
    // console.log(this.LocallyUsedCars.value);
    this.router.navigateByUrl('/locally-used', {state: {data:this.LocallyUsedCars.value}});
  }

  getDealer (userid) {
    // console.log('userid'+userid);
    this.dealersService.getDealer(userid).subscribe( data=>{
      // console.log(data);
      this.dealerid = data[0]['id'];
      this.dealerstatus = data[0]['status'];
      // console.log("dealerid"+this.dealerid);
      // alert(this.dealerid);
    }, error=> {
      console.log(error);
    }
    );
  }

  w3_open() {
    document.getElementById("mySidebar").style.display = "block";
    document.getElementById("myOverlay").style.display = "block";
  }
  
  w3_close() {
    document.getElementById("mySidebar").style.display = "none";
    document.getElementById("myOverlay").style.display = "none";
  }

}
