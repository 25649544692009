<div class="w3-top shadow-1">
  <div class="w3-bar">
    <a routerLink="">
      <img class="logo-nav" src="assets/images/GRH.png" />
    </a>

    <div class="w3-right navigation-right">
      <a
        routerLink=""
        href="/"
        class="w3-bar-single-item"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        >Home</a
      >
      <a
        href="http://blog.germanrideshub.co.ke/"
        class="w3-bar-single-item"
        routerLinkActive="active"
        >Blog</a
      >
      <a
        class="w3-bar-single-item"
        href="/makes"
        routerLink="/makes"
        routerLinkActive="active"
        >Makes</a
      >
      <a
        class="w3-bar-single-item"
        routerLinkActive="active"
        href="/locally-used"
        routerLink="/locally-used"
        (click)="toLocallyUsed()"
        >Locally used</a
      >
      <a
        routerLink="/dealers"
        href="/dealers"
        routerLinkActive="active"
        class="w3-bar-single-item"
        *ngIf="accesslevel == 1"
        >Dealers</a
      >
      <!-- <a class="w3-bar-single-item pl-5"  *ngIf="loggedin">
                <app-notification-bell></app-notification-bell>
            </a> -->
      <a
        *ngIf="!loggedin"
        class="w3-bar-single-item login-btn"
        onclick="document.getElementById('id01').style.display='block'"
        >Login</a
      >
      <a
        *ngIf="!loggedin"
        class="w3-bar-single-item login-btn"
        onclick="document.getElementById('id02').style.display='block'"
        >Sign Up</a
      >
      <div *ngIf="loggedin" class="w3-bar-single-item w3-dropdown-hover">
        <a href="#" class="nav-link dropdown-toggle" role="button"
          ><i class="fa fa-fw fa-user"></i>{{ username }} <b class="caret"></b
        ></a>
        <ul class="w3-dropdown-content w3-bar-block w3-card-4">
          <!-- <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown2" role="button" data-toggle="dropdown" >
                        Dropdown
                    </a> -->
          <li
            *ngIf="accesslevel == 1 || accesslevel == 2"
            class="w3-bar-item w3-button"
            routerLink="/dealer/{{ dealerid }}"
          >
            <a routerLink="/dealer/{{ dealerid }}" class=""
              ><i class="fa fa-user-o"></i> <span class="pl-2">Profile</span></a
            >
          </li>
          <li
            class="w3-bar-item w3-button"
            *ngIf="accesslevel == 1"
            routerLink="/admin-portal"
          >
            <a routerLink="/admin-portal"
              ><i class="fa fa-sliders"> </i
              ><span class="pl-2">Admin Portal</span></a
            >
          </li>
          <li
            class="w3-bar-item w3-button"
            *ngIf="accesslevel == 2"
            routerLink="/admin-portal"
          >
            <a routerLink="/admin-portal" class=""
              ><i class="fa fa-sliders"> </i
              ><span class="pl-2">Post Car</span></a
            >
          </li>
          <li
            routerLink="/admin-portal"
            class="w3-bar-item w3-button"
            *ngIf="accesslevel == 3"
          >
            <a routerLink="/admin-portal"
              ><i class="fa fa-sliders"> </i
              ><span class="pl-2">Post Blog</span></a
            >
          </li>
          <li class="divider dropdown-divider"></li>
          <li (click)="logOut()" class="w3-bar-item pl-4 w3-button">
            <i class="material-icons">&#xE8AC;</i>
            <span class="pl-2 text-white"></span>Logout
          </li>
        </ul>
      </div>
    </div>

    <a class="sidebar-btn w3-text-white" (click)="w3_open()">
      <i class="fa fa-bars fa-2x"></i>
    </a>

    <div
      class="w3-sidebar w3-bar-block w3-hide-large w3-card w3-animate-right"
      style="display: none"
      id="mySidebar"
    >
      <button
        class="w3-btn w3-right w3-large w3-hide-large w3-margin-right"
        (click)="w3_close()"
        style="width: 50px"
      >
        &times;
      </button>
      <div class="w3-row-padding">
        <div class="w3-col s6 m6">
          <a
            *ngIf="!loggedin"
            onclick="document.getElementById('id01').style.display='block'"
            id="nav-login-btn"
            class="w3-btn w3-blue w3-mobile"
            >Login</a
          >
        </div>
        <div class="w3-col s6 m6">
          <a
            *ngIf="!loggedin"
            onclick="document.getElementById('id02').style.display='block'"
            class="w3-btn w3-teal w3-mobile"
            >Sign up</a
          >
        </div>

        <h3 *ngIf="loggedin" class="w3-margin-left">
          Welcome,<span class="w3-text-blue w3-xlarge"> {{ username }}</span> !
        </h3>
      </div>
      <div class="w3-panel">
        <a routerLink="" href="/" class="w3-bar-item w3-large w3-btn">Home</a>
        <a
          href="http://blog.germanrideshub.co.ke/"
          class="w3-bar-item w3-large w3-btn"
          >Blog</a
        >
        <a routerLink="/makes" class="w3-bar-item w3-large w3-btn">Makes</a>
        <a
          routerLink="/locally-used"
          href="/locally-used"
          class="w3-bar-item w3-large w3-btn"
          >Locally Used</a
        >
        <a
          routerLink="/dealers"
          href="/dealers"
          class="w3-bar-item w3-large w3-btn"
          *ngIf="accesslevel == 1"
          >Dealers</a
        >

        <a
          *ngIf="accesslevel > 0"
          href="/dealer/{{ dealerid }}"
          routerLink="/dealer/{{ dealerid }}"
          class="w3-bar-item w3-large w3-btn"
          ><i class="fa fa-user-o"></i> Profile</a
        >

        <a
          *ngIf="loggedin && accesslevel > 0"
          href="admin-portal"
          routerLink="/admin-portal"
          class="w3-bar-item w3-large w3-btn"
        >
          <i class="fa fa-sliders"> </i>Admin Portal</a
        >

        <div class="w3-row-padding w3-margin-top">
          <a
            *ngIf="loggedin"
            (click)="logOut()"
            class="w3-btn w3-blue w3-mobile w3-right"
            >Logout</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="w3-overlay w3-animate-opacity"
  (click)="w3_close()"
  style="cursor: pointer"
  id="myOverlay"
></div>
<!--Login form-->
<div id="id01" class="modal">
  <div class="modal-inner">
    <app-login-form></app-login-form>
  </div>
</div>
<!--Login form END-->

<!--Register form-->
<div id="id02" class="modal">
  <div class="modal-inner RegisterForm">
    <app-register-form></app-register-form>
  </div>
</div>
<!--register form END-->

<!--Forgot Password-->
<div id="id03" class="modal">
  <div class="forgot-password modal-inner">
    <app-forgot-password></app-forgot-password>
  </div>
</div>
<!--Forgot Password End-->
