import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-footers',
  templateUrl: './footers.component.html',
  styleUrls: ['./footers.component.css']
})
export class FootersComponent implements OnInit {
  Year: any;

  constructor(private deviceService: DeviceDetectorService) { }

  ngOnInit(): void {
    var d = new Date();
    this.Year = d.getFullYear();
  }

  whatsappRedirect() {
    let phone_number = "254704181628";
    var str = '';
    // let currenturl = encodeURI(window.location.href);
    if (this.deviceService.isMobile() || this.deviceService.isTablet()) { //if client is on mobile
      str = "https://api.whatsapp.com/send?phone=%2B" + phone_number + "&text=Hi%2C%20I%20came%20from%20your%20website%20and%20I%20would%20like%20to%20chat";
    } else { //desktop
      str = 'https://web.whatsapp.com/send?phone=%2B' + phone_number + "&text=Hi%2C%20I%20came%20from%20your%20website%20and%20I%20would%20like%20to%20chat";
    }
    window.open(str);
  }
}
