import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NgxNotificationService } from 'ngx-notification';
import { LoggedInService } from '../_services/logged-in.service';
import { BrandsModelsSeriesService } from '../_services/brands-models-series.service';
import * as $ from 'jquery';
import { config } from '../_helpers/global';
import { Router } from '@angular/router';
import { DealersService } from '../_services/dealers.service';

@Component({
  selector: 'app-admin-portal',
  templateUrl: './admin-portal.component.html',
  styleUrls: ['./admin-portal.component.css']
})

export class AdminPortalComponent implements OnInit, AfterViewInit {
  fileData: File = null;
  loading = false;
  editmode = false;
  accesslevel: any;
  admin: any;
  brands: any;
  models: any;
  series: any;
  users: any;
  makebloggername: any;
  // getBrands: any;
  // getModels: any;
  username: any;
  AddBrandForm: FormGroup;
  AddModelForm: FormGroup;
  AddSeriesForm: FormGroup;

  constructor(private ngxNotificationService: NgxNotificationService, private formBuilder: FormBuilder, private loggedInService: LoggedInService, private router: Router, private bms: BrandsModelsSeriesService, private dealerService: DealersService) {
    // this.getBrands = this.bms.getBrands();
    // this.getModels = this.bms.getModels();
    // this.brands = this.bms.brands;
    // this.models = this.bms.models;
  }


  ngOnInit() {
    var self = this;
    this.checkAdmin();
    this.getBrands();
    this.getUsers();
    this.admin = this.loggedInService.admin;
    this.accesslevel = this.loggedInService.accesslevel;
    this.username = this.loggedInService.username;
    // this.getModels();
    // this.getSeries();

    this.AddBrandForm = this.formBuilder.group({
      brand: ['', Validators.required],
      logo: ['', Validators.required]
    });
    this.AddModelForm = this.formBuilder.group({
      model_name: ['', Validators.required],
      brand: ['', Validators.required]
    });
    this.AddSeriesForm = this.formBuilder.group({
      series_name: ['', Validators.required],
      brand_id: ['', Validators.required],
      series_model: ['', Validators.required]
    });

  }

  ngAfterViewInit() {
    let self = this;

    $('#AddModelForm').submit(function (e) {
      e.preventDefault();

      if (self.AddModelForm.invalid) {
        return;
      }
      var formData = new FormData(this);
      self.loading = true;
      // formData.forEach((value, key) => {
      //   console.log(key + " " + value)
      // });
      $.ajax({
        type: 'POST',
        url: `${config.apiUrl}/add_model`,
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        success: (data) => {
          // console.log(data);
          self.addModel(data); //callback
        },
        error: function (data) {
          console.log(data);
          self.addModel(data);
        }
      });
    });

    $('#AddSeriesForm').submit(function (e) {
      e.preventDefault();
      if (self.AddSeriesForm.invalid) {
        return;
      }
      var formData = new FormData(this);
      self.loading = true;
      // formData.forEach((value,key) => {
      //   console.log(key+" "+value)
      // });
      $.ajax({
        type: 'POST',
        url: `${config.apiUrl}/add_series`,
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        success: (data) => {
          // console.log(data);
          self.addSeries(data); //callback
        },
        error: function (data) {
          console.log(data);
          self.addSeries(data);
        }
      });
    });

    $('#AddBrandForm').submit(function (e) {
      e.preventDefault();
      if (self.AddBrandForm.invalid) {
        return;
      }
      var formData = new FormData(this);
      self.loading = true;
      // console.log(formData);
      $.ajax({
        type: 'POST',
        url: `${config.apiUrl}/add_brand`,
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        success: (data) => {
          // console.log(data);
          self.addBrand(data); //callback
        },
        error: function (data) {
          console.log(data);
          self.addBrand(data);
        }
      });
    });

  }

  addSeries(data) {
    var self = this;
    if (data == 1) {
      self.ngxNotificationService.sendMessage('Series successfully posted!', 'success', 'bottom-left');
      // console.log(data);
      // self.AddSeriesForm.reset();
      self.models = [];
      self.loading = false;
    } else {
      // console.log(data);
      self.ngxNotificationService.sendMessage('Something went wrong!', 'warning', 'bottom-left');
      self.loading = false;
    }
  }

  addModel(data) {
    var self = this;
    if (data == 1) {
      self.ngxNotificationService.sendMessage('Model successfully posted!', 'success', 'bottom-left');
      // console.log(data);
      // self.AddModelForm.reset();
      self.loading = false;
      self.getModels();
    } else {
      // console.log(data);
      self.ngxNotificationService.sendMessage('Something went wrong!', 'warning', 'bottom-left');
      self.loading = false;
    }
  }

  addBrand(data) {
    var self = this;
    if (data == 1) {
      self.ngxNotificationService.sendMessage('Make successfully posted!', 'success', 'bottom-left');
      // console.log(data);
      self.getBrands();
      // self.AddBrandForm.reset();
      self.loading = false;
    } else {
      // console.log(data);
      self.ngxNotificationService.sendMessage('Something went wrong!', 'warning', 'bottom-left');
      self.loading = false;
    }
  }


  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    // this.AddBlogForm.patchValue({
    //   fileSource : this.fileData
    // });
  }

  checkAdmin() {
    if (this.loggedInService.checkStatus() == 0 || this.loggedInService.accesslevel == 0) { //redirect to home
      this.router.navigate(['/']);
    } else {
      // this.loggedInService.checkStatus();
      // if (this.loggedInService.accesslevel == 0) {
      // } else {}

      // if (this.loggedInService.accesslevel == 0) {
      //   this.router.navigate(['/']);
      // } else {
      //   this.accesslevel =  this.loggedInService.accesslevel;
      // }
      this.accesslevel = this.loggedInService.accesslevel;
      // console.log(this.accesslevel);
    }
  }

  getBrands() {
    this.bms.getBrands().subscribe(data => {
      this.brands = data;
      this.bms.brands = data;
      // console.log(this.brands);
    });
    // if( this.brands = this.bms.getBrands) {
    //   console.log(this.brands);
    // }
  }

  getModels() {
    this.bms.getModels().subscribe(data => {
      this.models = data;
      this.bms.models = data;
      // console.log(this.models);
    });
  }

  getSeries() {
    this.bms.getSeries().subscribe(data => {
      this.series = data;
      this.bms.series = data;
      // console.log(this.models);
    });
  }

  getSpecificModels(event: any) {
    this.loading = true;
    var id = event.target.value;
    this.bms.getSpecificModels(id).subscribe(data => {
      this.models = data;
      this.loading = false;
    });
  }

  getUsers() {
    this.dealerService.getUsers().subscribe(data => {
      this.users = data;
      // console.log(this.users);
    })
  }

  confirmAppointBlogger(index) {
    this.makebloggername = this.users[index];
    $('#make_blogger').fadeIn();
  }

  confirmDemoteBlogger(index) {
    this.makebloggername = this.users[index];
    $('#demote_blogger').fadeIn();
  }

  demoteBlogger(index) {
    $('#demote_blogger').fadeOut();
    this.dealerService.demoteBlogger(index).subscribe(data => {
      if (data == 1) {
        this.ngxNotificationService.sendMessage('Blogger demoted succesfully!', 'success', 'bottom-left');
        this.getUsers();
      } else {
        this.ngxNotificationService.sendMessage('Something went wrong! Please try again', 'danger', 'bottom-left');
      }
    });
  }

  appointBlogger(index) {
    $('#make_blogger').fadeOut();
    this.dealerService.appointBlogger(index).subscribe(data => {
      if (data == 1) {
        this.ngxNotificationService.sendMessage('Blogger appointed succesfully!', 'success', 'bottom-left');
        this.getUsers();
      } else {
        this.ngxNotificationService.sendMessage('Something went wrong! Please try again', 'danger', 'bottom-left');
      }
    });
  }

  cancelAppointBlogger() {
    $('#make_blogger').fadeOut();
  }

  cancelDemoteBlogger() {
    $('#demote_blogger').fadeOut();
  }
}
