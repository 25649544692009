<!--headers-->
<app-headers></app-headers>
<!--headers END-->
<section class="pt-5 mt-3 pb-5">
    <div class="research-makes pl-5 pr-5">
        <div class="w3-center">
            <h1>Research Car Makes</h1>
        </div>

        <div class="lds-roller loader" *ngIf="makesloading">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>

        <div class="w3-row pt-3">
            <div *ngFor="let brand of Brands" class="w3-col l2 s12 m4 single-brand" (click)="toBrand(brand['brand'])">
                <a [routerLink]="['/search']" [queryParams]="{ brand: brand['brand'] }">
                    <div class="w3-panel">
                        <div class="text-center make-logo-wrapper">
                            <img class="make-logo w3-image" src="{{url}}{{brand['logo']}}">
                        </div>
                        <div class="text-center model-name">
                            <h3>{{brand['brand']}}</h3>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</section>

<!--footers-->
<app-footers></app-footers>
<!--footers END-->